import * as Pikaday from 'pikaday';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core';

import { ShiftDate } from '../../models';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnInit {
  @Input() date: Date | string;
  @Input() end_date = undefined;
  @Input() shiftDates: Array<ShiftDate>;
  @Input() maxDate: Date;
  @Output() changeDate: EventEmitter<Date> = new EventEmitter<Date>();
  @ViewChild('datePicker', { static: true }) datePicker: ElementRef;
  @ViewChild('container', { static: true }) container: ElementRef;
  private picker: Pikaday;

  ngOnInit() {
    this.picker = new Pikaday({
      field: this.datePicker.nativeElement,
      firstDay: 1,
      minDate: new Date(2018, 12, 31),
      maxDate: this.maxDate ? this.maxDate : new Date(new Date().getFullYear() + 1, 12, 31),
      yearRange: [2000, 2020],
      bound: false,
      defaultDate: new Date(),
      setDefaultDate: true,
      container: this.container.nativeElement,
      onDraw: () => {
        this.drawShiftsOnDatePicker(this.shiftDates);
      },
      onSelect: () => {
        if (this.end_date) {
          return;
        }
        this.changeDate.emit(this.picker.getDate());
      },
    });
    this.picker.setDate(this.date);
  }

  drawShiftsOnDatePicker = (shiftDates: Array<ShiftDate>) => {
    const comparableDate = (date) => date.setHours(0, 0, 0, 0);
    const monthYearNow = () => ({ month: new Date().getMonth(), year: new Date().getFullYear() });
    // the property of the picker calendars[0] holds an object that determines the current date
    // this is a bit sneaky because we're not using the Pikaday public API so we need to cast it to an any
    const currentMonthYear = this.picker && this.picker ? (this.picker as any).calendars[0] : monthYearNow();
    // gets a list of all days in the container by targeting the td elements
    const days: any = Array.from(this.container.nativeElement.getElementsByTagName('td'));
    days.forEach((day) => {
      if (!day.classList.contains('is-empty')) {
        const currentDayDate = new Date(
          currentMonthYear.year,
          currentMonthYear.month,
          Number(day.getAttribute('data-day'))
        );
        const shiftsExist =
          shiftDates &&
          shiftDates.find((shiftDate) => comparableDate(shiftDate.date) === comparableDate(currentDayDate));

        if (shiftsExist) {
          switch (shiftsExist.shifts.length) {
            case 1:
              day.classList.add('one-shift');
              break;
            case 2:
              day.classList.add('two-shifts');
              break;
            case 3:
              day.classList.add('three-shifts');
              break;
          }
        }
      }
    });
  };
}
