import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ButtonTypes } from '../buttons/basic-button.component';

@Component({
  selector: 'app-select-module',
  templateUrl: './select-module.component.html',
  styleUrls: ['./select-module.component.scss']
})
export class SelectModuleComponent implements OnInit, OnChanges {
  @Input() module_group;
  @Input() moduleIdArray = [];
  @Output() changeModuleRequested = new EventEmitter<any>();

  showAddButton = true;
  buttonTypes = ButtonTypes;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.addButtonTest()
  }

  addButtonTest() {
    this.showAddButton = true;
    this.module_group.forEach(module => {
      if (this.moduleIdArray.includes(module.module_id)) {
        this.showAddButton = false
      }
    });
  }

  showModule(module_id) {
    return this.moduleIdArray.includes(module_id)
  }

  emitChangeModule(module, module_group, index, direction) {
    this.changeModuleRequested.emit([module, module_group, index, direction])
    this.showAddButton = false;
  }
}
