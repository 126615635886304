import { Component, Input, OnDestroy, OnInit } from '@angular/core';

// Services
import { Store } from '@ngrx/store';
import { DecimalPipe } from '@angular/common';
import { TripCostsService } from './trip-costs.service';

// Reducers
import { selectorActiveBucketId, selectorActiveBucket } from '../../manage-bucket.reducer';

// Constants
import {
  emptySubmittedTrips,
  noSelectedTripsMessage,
  tripsTableColumns,
  unroutedOrdersTableColumns,
  tripsTablePrimaryActions,
  tripsTableBulkActions,
  tripsSummaryTableColumns,
} from './trip-costs.constants';

// Helper Functions
import { completeAssign } from '../../../../shared/helpers';

// Interfaces
import {
  TableColumn,
  TableRow,
  TableCell,
  TableAction,
  TableActionTaken,
} from '../../../../shared/table/table.interfaces';

import { IconTypes } from '../../../../shared/icon/icon.interfaces';

import { SubmittedTrips, TripsTableData } from './trip-costs.interfaces';

import { BucketState } from '../../../buckets/buckets.interfaces';
import { selectorUser } from '../../../../auth/auth.reducer';
import { UiColors, UiThemes } from '../../../../interfaces/ui.interfaces';
import { AuthUser } from '../../../../interfaces/auth.interfaces';
import { MapTypes } from '../../../../shared/map/map.interfaces';
import { ConfirmModalComponent } from '../../../../shared/modals/confirm-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { environment } from 'environments/environment';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-trip-costs',
  templateUrl: './trip-costs.component.html',
  styleUrls: ['./trip-costs.component.scss'],
  providers: [DecimalPipe, TripCostsService],
})
export class TripCostsComponent implements OnInit, OnDestroy {
  @Input() state: BucketState;
  @Input() readOnly = false;
  // Init
  bucketId: number;

  environement = environment;

  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  public MapTypes = MapTypes;
  buttonTypes = ButtonTypes;
  tableThemes = UiThemes;

  showUnrouted = false;

  // Trips Summary Table
  _tripsSummaryTableColumns: TableColumn[] = tripsSummaryTableColumns;

  // Trips Table Setup (creating locally scoped vars for view)
  _tripsTableColumns: TableColumn[] = tripsTableColumns;
  _unroutedOrdersTableColumns: TableColumn[] = unroutedOrdersTableColumns;

  // Primary Actions
  tblTripsGenericPrimaryActions = tripsTablePrimaryActions;

  // Bulk Actions
  tblTripsUnsortedBulkActions: TableAction[] = [
    tripsTableBulkActions.picup,
    tripsTableBulkActions.contract,
    tripsTableBulkActions.courier,
  ];

  tblTripsPicupBulkActions: TableAction[] = [
    tripsTableBulkActions.unassign,
    tripsTableBulkActions.contract,
    tripsTableBulkActions.courier,
  ];

  tblTripsContractBulkActions: TableAction[] = [
    tripsTableBulkActions.picup,
    tripsTableBulkActions.unassign,
    tripsTableBulkActions.courier,
  ];

  tblTripsCourierBulkActions: TableAction[] = [
    tripsTableBulkActions.picup,
    tripsTableBulkActions.contract,
    tripsTableBulkActions.courier,
  ];

  _noSelectedTripsMessage = noSelectedTripsMessage;

  trips: TripsTableData = {
    unassigned: [],
    unrouted: [],
    picup: [],
    contract: [],
    courier: [],
    count: 0,
    previouslySaved: false,
    hasFetched: false,
  };

  multi_date_bucket = true;

  // Saving Trips
  tripIds: SubmittedTrips = completeAssign(emptySubmittedTrips);

  // Reset Trips button
  hasReset = false;

  // Save Trips button
  hasSortedTrips = false;
  savingTripsInProcess = false;
  saveButtonLabel = 'Save';

  // Proceed Button
  tripsSavedSuccessfully = false;
  finaliseTripCostsInProcess = false;

  // Toggle Suburbs on the table
  hideSuburbs = true;

  // View Trip Details
  tripViewActive = false;
  selectedTripId;

  // Showing Trips on the tableDataMap:
  showTripsOnMap = false;
  allTripGeoJson: any;

  // Summary Section (Deprecated)
  summary: any[];

  user: AuthUser;

  activeBucket;

  // Subscriptions;
  tripsSubscription;
  finalisingInProgressSubscription;
  savingInProgressSubscription;
  savedSuccessfullySubscription;
  summaryDataSubscription;
  tripGeoDataSubscription;

  constructor(
    public store: Store<any>,
    private simpleModalService: SimpleModalService,
    private tripCostsService: TripCostsService
  ) {
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.bucketId = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActiveBucket).subscribe((next) => {
      this.activeBucket = next;

      if (!this.activeBucket.delivery_date_end || this.activeBucket.delivery_date_end === '0001-01-01T00:00:00') {
        this.multi_date_bucket = false;
      }
    });

    // Trips Subscription
    this.tripsSubscription = this.tripCostsService.trips$.subscribe((observer) => {
      if (!observer) {
        return;
      }

      this.trips = observer;

      if (this.trips.previouslySaved) {
        this.saveButtonLabel = 'Saved';
      }
    });

    // Summary Data Subscription
    this.summaryDataSubscription = this.tripCostsService.summaryData$.subscribe(
      (observer) => (this.summary = observer || this.summary)
    );

    // Map Geo-data for Trips
    this.tripGeoDataSubscription = this.tripCostsService.tripGeoData$.subscribe(
      (observer) => (this.allTripGeoJson = observer || this.allTripGeoJson)
    );

    // Saving Trips
    this.savingInProgressSubscription = this.tripCostsService.savingInProgress$.subscribe(
      (observer) => (this.savingTripsInProcess = observer)
    );

    this.savedSuccessfullySubscription = this.tripCostsService.savedSuccessfully$.subscribe((observer) => {
      this.tripsSavedSuccessfully = observer || false;
      if (this.tripsSavedSuccessfully) {
        this.saveButtonLabel = 'Saved';
      }
    });

    // Finalising Trips
    this.finalisingInProgressSubscription = this.tripCostsService.finalisingInProgress$.subscribe(
      (observer) => (this.finaliseTripCostsInProcess = observer || false)
    );
  }

  ngOnInit(): void {
    this.tripCostsService.init();
  }

  ngOnDestroy(): void {
    this.trips = null;
    this.tripIds = null;
    this.tripsSubscription.unsubscribe();
    this.finalisingInProgressSubscription.unsubscribe();
    this.summaryDataSubscription.unsubscribe();
    this.savingInProgressSubscription.unsubscribe();
    this.tripGeoDataSubscription.unsubscribe();
  }

  handleCloseTripView(): void {
    this.tripViewActive = false;
  }

  // Methods handling the Table Actions
  handleTableTripContractActions($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'assignPicup':
      case 'assignCourier':
      case 'unassign':
        this.trips.contract = this.removeTrips(this.trips.contract, $event.rows);
        break;
      default:
    }
    this.handleTableTripGenericActions($event);
  }

  handleTableTripPicupActions($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'unassign':
      case 'assignCourier':
      case 'assignContract':
        this.trips.picup = this.removeTrips(this.trips.picup, $event.rows);
        break;
      default:
    }
    this.handleTableTripGenericActions($event);
  }

  handleTableTripUnsortedActions($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'assignPicup':
      case 'assignCourier':
      case 'assignContract':
        this.trips.unassigned = this.removeTrips(this.trips.unassigned, $event.rows);
        break;
      default:
    }
    this.handleTableTripGenericActions($event);
  }

  handleTableTripCourierActions($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'assignPicup':
      case 'assignContract':
      case 'assignCourier':
        this.trips.courier = this.removeTrips(this.trips.courier, $event.rows);
        break;
      default:
    }
    this.handleTableTripGenericActions($event);
  }

  handleTableTripGenericActions($event: TableActionTaken): void {
    switch ($event.action.event) {
      case 'viewTrip':
        this.viewTrip($event);
        break;
      case 'assignPicup':
        this.trips.picup = [...this.trips.picup, ...$event.rows];
        break;
      case 'assignContract':
        this.trips.contract = [...this.trips.contract, ...$event.rows];
        break;
      case 'unassign':
        this.trips.unassigned = [...this.trips.unassigned, ...$event.rows];
        break;
      case 'assignCourier':
        this.trips.courier = [...this.trips.courier, ...$event.rows];
        break;
    }
    this.updateTripsOnTableAction();
  }

  removeTrips(original: TableRow[], toRemove: TableRow[]): TableRow[] {
    const rowsToRemove: number[] = this.getTripIdsFromRowData(toRemove);
    return original.filter(
      (item) => rowsToRemove.indexOf(item.cells.find((cell: TableCell) => cell.column === 'id').value as number) === -1
    );
  }

  updateTripsOnTableAction(): void {
    this.hasSortedTrips = this.trips.picup.length > 0 || this.trips.contract.length > 0;
    this.saveButtonLabel = 'Save';
    this.hasReset = !(this.trips.picup.length > 0 || this.trips.contract.length > 0);

    this.tripIds.picup = this.trips.picup.length > 0 ? this.getTripIdsFromRowData(this.trips.picup) : [];
    this.tripIds.contract = this.trips.contract.length > 0 ? this.getTripIdsFromRowData(this.trips.contract) : [];
  }

  getTripIdsFromRowData(rowData: TableRow[]): number[] {
    return rowData.map((row: TableRow) => row.cells.find((cell: TableCell) => cell.column === 'id').value as number);
  }

  viewTrip($event: { action: TableAction; rows: TableRow[] }): void {
    this.selectedTripId = $event.rows[0].cells.find((cell: TableCell) => cell.column === 'id').value;
    this.tripViewActive = true;
  }

  toggleMap(): void {
    this.showTripsOnMap = !this.showTripsOnMap;
    if (this.showTripsOnMap) {
      this.tripCostsService.getGeoData();
    }
  }

  resetTrips(): void {
    this.trips.unassigned = [...this.trips.unassigned, ...this.trips.picup, ...this.trips.contract];
    this.tripIds.picup = [];
    this.tripIds.contract = [];
    this.trips.picup = [];
    this.trips.contract = [];
    this.hasSortedTrips = false;
    this.saveButtonLabel = 'Save';
    this.hasReset = true;
  }

  saveTrips(): void {
    if (environment.appVersion !== 2) {
      this.tripCostsService.save(this.tripIds);
    } else {
      this.tripCostsService.saveV2Trips(this.tripIds);
    }
  }

  finaliseTripCosts(): void {
    this.tripCostsService.save(this.tripIds).then(() => {
      if (!this.hasSortedTrips && !this.savingTripsInProcess) {
        this.simpleModalService
          .addModal(ConfirmModalComponent, {
            title: 'No trips selected',
            message: 'Are you sure you want to proceed? All trips will be converted into orders for courier selection.',
          })
          .subscribe((isConfirmed) => {
            if (!isConfirmed) {
              return;
            }
            this.tripCostsService.finalise();
          });
      } else if (!(this.tripsSavedSuccessfully || this.trips.previouslySaved) && !this.savingTripsInProcess) {
        this.simpleModalService
          .addModal(ConfirmModalComponent, {
            title: 'You have unsaved trip selections',
            message:
              'Are you sure you want to proceed? Your current selection has not be saved. All unselected trips will be converted into orders for courier selection.',
          })
          .subscribe((isConfirmed) => {
            if (!isConfirmed) {
              return;
            }
            this.tripCostsService.finalise();
          });
      } else {
        this.tripCostsService.finalise();
      }
    });
  }

  finaliseV2TripCosts(): void {
    this.updateTripsOnTableAction();
    this.tripCostsService.saveV2Trips(this.tripIds).then(() => {
      this.tripCostsService.finalise();
    });
  }
}
