import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { selectorUser } from 'app/auth/auth.reducer';
import { AuthService } from 'app/auth/auth.service';
import { ActingAs, AuthUser } from 'app/interfaces/auth.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { environment } from 'environments/environment';
import { SimpleModalService } from 'ngx-simple-modal';
import { ReportingService } from '../reporting.service';

@Component({
  selector: 'app-csv-reports',
  templateUrl: './csv-reports.component.html',
  styleUrls: ['./csv-reports.component.scss'],
})
export class CsvReportsComponent implements OnInit {
  public Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  public buttonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  dateRange;
  driverReasonReport;
  driverReasonTableMap = [];
  businessDateStatusSummary;
  driverScores;
  suburbHotspots;
  dataSubscription$;
  actingAs: ActingAs;
  customDates = false;
  selectedStartDate: any;
  @Input() customDateObj = {
    title: null,
    start_date: null,
    end_date: null,
  };
  loading = false;
  parcelByDateData;
  environment = environment;
  user: AuthUser;

  constructor(
    public simpleModalService: SimpleModalService,
    private reportingService: ReportingService,
    private store: Store<any>,
    public afAuth: AngularFireAuth,
    public authService: AuthService
  ) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  getReport(type: string): void {
    this.loading = true;
    this.reportingService
      .getReport(type, this.customDateObj)
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  }

  ngOnInit() {}
}
