/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'app/shared/payment/payment.service';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes, BasicButton } from 'app/shared/buttons/basic-button.component';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-buy-credits',
  templateUrl: './buy-credits.component.html',
  styleUrls: ['./buy-credits.component.scss'],
})
export class BuyCreditsComponent implements OnInit {
  wallet;
  ownAmount = false;
  currentTab;
  currency_symbol = environment.currency_symbol;

  price: number;
  public iconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  uiColors = UiColors;

  paymentAmounts: BasicButton[] = [
    { type: ButtonTypes.Primary, title: this.currency_symbol + '100' },
    { type: ButtonTypes.Primary, title: this.currency_symbol + '200' },
    { type: ButtonTypes.Primary, title: this.currency_symbol + '500' },
    { type: ButtonTypes.Primary, title: 'Own Amount', hidden: this.ownAmount },
  ];

  constructor(private paymentService: PaymentService) {
    this.paymentService.wallet.subscribe((wallet) => {
      this.wallet = wallet;
    });
  }

  resetButtons() {
    this.currentTab = null;
    this.price = undefined;
    this.ownAmount = false;
    this.paymentAmounts[3].hidden = false;
    this.paymentAmounts.forEach((button) => {
      button.disabled = false;
    });
  }

  handleButtonClick(amount) {
    this.paymentAmounts.forEach((button) => {
      button.disabled = true;
    });
    this.price = undefined;
    setTimeout(() => {
      switch (amount) {
        case this.currency_symbol + '100': {
          this.price = 100;
          this.currentTab = this.currency_symbol + '100';
          this.ownAmount = false;
          this.paymentAmounts[3].hidden = false;
          break;
        }
        case this.currency_symbol + '200': {
          this.price = 200;
          this.currency_symbol + '200';
          this.ownAmount = false;
          this.paymentAmounts[3].hidden = false;
          break;
        }
        case this.currency_symbol + '500': {
          this.price = 500;
          this.currency_symbol + '500';
          this.ownAmount = false;
          this.paymentAmounts[3].hidden = false;
          break;
        }
        case 'Own Amount': {
          this.ownAmount = true;
          this.price = null;
          this.paymentAmounts[3].hidden = true;
          this.currentTab = null;
          setTimeout(() => {
            document.getElementById('ownAmount').focus();
          }, 1);
          break;
        }
      }
    }, 1);
  }

  ngOnInit() {}
}
