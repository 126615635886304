import { Component, OnInit, ViewEncapsulation, HostBinding, Input } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-full-page-split',
  templateUrl: './full-page-split.component.html',
  styleUrls: ['./full-page-split.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FullPageSplitComponent implements OnInit {
  UiThemes = UiThemes;
  IconTypes = IconTypes;

  @Input() title: string;
  @Input() subtitle: string;
  @Input() fullHeight = true;
  @Input() theme = UiThemes.Dark;
  @Input() showTitleDots = true;
  @Input() odd = false;
  @Input() heroImgUrl: string;

  constructor() { }
  @HostBinding('class.custContainerClass') containerClass: boolean = true;

  ngOnInit() {
  }

}
