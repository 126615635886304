import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import axios from 'app/api/axios';

// Reducers:
import { selectorActiveBucketId } from '../dashboard/buckets/manage-bucket.reducer';

// Services
import { Store } from '@ngrx/store';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { AngularFireDatabase } from '@angular/fire/database';

// Helpers
import { mapDataToTable } from '../shared/table/table.helpers';
import { selectorActingAs, selectorUser } from '../auth/auth.reducer';
import { ActiveTrip$Keys, PicupDetailsKeys, TripTypes } from './trips.enums';
import {
  mfGetLastItemDetails,
  mfGetLastItemTime,
  mfSetHealthHighlightColor,
  mfSetCourierHealthHighlightColor,
} from './post-dispatch/post-dispatch.helpers';
import {
  mfGetNestedObjectValue,
  mfWaypointCompleteStatus,
  mfWaypointTotal,
  mfGetNestedObjectValuesByKeyArray,
  mfCourierStatusStrings,
  mfGetDriverType,
} from 'app/shared/table/map-formatters.table.helpers';

// Interfaces
import { TripsTableData } from './buckets/manage-bucket/trip-costs/trip-costs.interfaces';
import {
  TableColumn,
  TableDataMap,
  TableAction,
  DisplayPipes,
  TableActionTaken,
} from '../shared/table/table.interfaces';
import { PD_Col_Sched, PD_Col_InProg } from './post-dispatch/post-dispatch.interfaces';

import { ActiveTrip, PicupBusinessTripsDataMap, PicupBusinessTripsColumns } from './trips.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { Router } from '@angular/router';
import { ActingAs, AuthUser } from 'app/interfaces/auth.interfaces';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TripsService {
  bucketId: number;
  user: AuthUser;
  auth;
  actingAs: ActingAs;

  stopPolling = false;
  stopPollingBusinessPicups = false;
  tableDataMap: TableDataMap | PicupBusinessTripsDataMap;
  tableColumns: TableColumn[] | PicupBusinessTripsColumns;
  shouldAssignTrips = false;
  adminTableMap;

  // Subscriptions
  currentBusinessPicupsSubscription;
  historicBusinessPicupsSubscription;
  picupNotesSubscription;

  picupNotesIds = [];

  // Observables
  picupsFinancials$ = new BehaviorSubject(undefined);
  picupsScheduled$ = new BehaviorSubject(undefined);
  picupsCurrent$ = new BehaviorSubject(undefined);
  picupsHistoric$ = new BehaviorSubject(undefined);
  picupAlert$ = new BehaviorSubject(undefined);
  picupsPicupArray$ = new BehaviorSubject(undefined);
  courierOrdersCurrent$ = new BehaviorSubject(undefined);
  courierOrdersHistoric$ = new BehaviorSubject(undefined);
  picupsBucket$ = new BehaviorSubject(undefined);
  courierOrdersBucket$ = new BehaviorSubject(undefined);
  picupWaypoints$ = new BehaviorSubject(undefined);
  activeTripWaypoints$ = new BehaviorSubject(undefined);
  creatingPicups$ = new BehaviorSubject(undefined);
  createdSuccessfully$ = new BehaviorSubject(undefined);
  picupIdsSubscriptionDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  picupNotes = new BehaviorSubject(undefined);
  private ENTERPRISE_WAYPOINTS_BASE_URL = '/enterprise-waypoints/';

  constructor(
    private store: Store<any>,
    private notificationsService: NotificationsService,
    private db: AngularFireDatabase,
    private router: Router,
    private firestore: AngularFirestore
  ) {
    this.store.select(selectorActiveBucketId).subscribe((next) => (this.bucketId = next));
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => {
      this.actingAs = next;
    });
  }

  // when_accepted_by_customer

  adminCourierTableColumns: TableColumn[] = [
    { name: 'courier_order_id', displayName: 'Courier Order ID', hidden: true },
    { name: 'customer_reference', displayName: 'Customer Reference' },
    { name: 'owner', displayName: 'Owner' },
    { name: 'courier_reference', displayName: 'Courier Reference' },
    { name: 'collection_date', displayName: 'Collected' },
    { name: 'delivery_date', displayName: 'Delivered' },
    // { name: 'delivery_address', displayName: 'Delivery Address' },
    { name: 'price', displayName: 'Price' },
    { name: 'reconciled_price', displayName: 'Reconciled Price' },
    { name: 'courier_order_state', displayName: 'Status' },
  ];

  adminCourierTableMap: TableDataMap = {
    cells: {
      courier_order_id: { column: 'Courier Order ID', map: 'courier_order_id' },
      customer_reference: {
        column: 'Customer Reference',
        displayPipeArgs: ['none'],
        map: ['collection', 'waybill', 'business_reference'],
        format: mfGetNestedObjectValuesByKeyArray,
      },
      owner: { column: 'Owner', map: 'owner_name' },
      courier_reference: {
        column: 'Courier Reference',
        map: ['courier_response', 'courier_reference'],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['none'],
      },
      collection_date: {
        column: 'Collected',
        map: ['collection', 'collection_date'],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['date', 'dd MMM yy, HH:mm'],
      },
      delivery_date: {
        column: 'Delivered',
        map: ['collection', 'delivery_date'],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['date', 'dd MMM yy, HH:mm'],
      },
      // delivery_address: { column: 'Delivery Address', map: ['collection', 'waybill', 'destination', 'address_line_1'], format: mfGetNestedObjectValuesByKeyArray },
      price: {
        column: 'Price',
        map: ['collection', 'waybill', 'price', 'inc_vat'],
        format: mfGetNestedObjectValuesByKeyArray,
        prefix: environment.currency_symbol + ' ',
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      reconciled_price: {
        column: 'Reconciled Price',
        map: ['collection', 'waybill', 'reconciled_price', 'inc_vat'],
        format: mfGetNestedObjectValuesByKeyArray,
        prefix: environment.currency_symbol + ' ',
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      courier_order_state: {
        column: 'Status',
        map: 'courier_order_state',
        highlighted: true,
        highlightColorClass: mfSetCourierHealthHighlightColor,
        highlightColorMap: ['courier_order_state'],
        format: mfCourierStatusStrings,
      },
    },
  };

  postDispatchInProgressTableColumns: TableColumn[] = [
    { name: PD_Col_InProg.PicupId, displayName: ' ', hidden: true },
    { name: PD_Col_InProg.TripId, displayName: 'Trip Id' },
    { name: PD_Col_InProg.CustomerRefNum, displayName: 'Customer Ref' },
    { name: PD_Col_InProg.LastEvent, displayName: 'Last Event', sortable: true },
    { name: PD_Col_InProg.LastEventDetails, displayName: 'Last Event' },
    { name: PD_Col_InProg.NextEvent, displayName: 'Next Event', sortable: true },
    { name: PD_Col_InProg.OwnerName, displayName: 'User/Business', sortable: true },
    { name: PD_Col_InProg.Consignment, displayName: 'Consignment', sortable: true },
    { name: PD_Col_InProg.WarehouseName, displayName: 'Warehouse', sortable: true },
    { name: PD_Col_InProg.WarehouseId, displayName: '', hidden: true },
    { name: PD_Col_InProg.DriverName, displayName: 'Driver', sortable: true },
    { name: PD_Col_InProg.Waypoints, displayName: 'Waypoints', sortable: true },
    { name: PD_Col_InProg.TripType, displayName: 'Trip Type', sortable: true },
  ];

  public postDispatchScheduledTableColumns: TableColumn[] = [
    { name: PD_Col_Sched.PicupId, displayName: ' ', hidden: true },
    { name: PD_Col_InProg.TripId, displayName: 'Trip Id' },
    { name: PD_Col_Sched.ScheduledTimeStamp, displayName: 'Scheduled', sortable: true },
    { name: PD_Col_Sched.Scheduled, displayName: 'When Released', sortable: true },
    { name: PD_Col_InProg.LastEvent, displayName: 'Last Event', sortable: true },
    { name: PD_Col_InProg.LastEventDetails, displayName: 'Last Event' },
    { name: PD_Col_InProg.OwnerName, displayName: 'Owner', sortable: true },
    { name: PD_Col_InProg.Consignment, displayName: 'Consignment', sortable: true },
    { name: PD_Col_InProg.WarehouseName, displayName: 'Warehouse', sortable: true },
    { name: PD_Col_InProg.WarehouseId, displayName: '', hidden: true },
    { name: PD_Col_Sched.Waypoints, displayName: 'Waypoints', sortable: true },
  ];

  public postDispatchInProgressTableMap: TableDataMap = {
    cells: {
      [PD_Col_InProg.PicupId]: { column: PD_Col_InProg.PicupId, map: ActiveTrip$Keys.PicupId },
      [PD_Col_InProg.CustomerRefNum]: {
        column: PD_Col_InProg.CustomerRefNum,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.CustomerReference],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_InProg.TripId]: {
        column: PD_Col_InProg.TripId,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.TripId],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['none'],
      },
      [PD_Col_InProg.LastEvent]: {
        column: PD_Col_InProg.LastEvent,
        map: [ActiveTrip$Keys.LastEvent],
        format: mfGetLastItemTime,
        displayPipeArgs: ['timeAgo'],
      },
      [PD_Col_InProg.LastEventDetails]: {
        column: PD_Col_InProg.LastEventDetails,
        map: [ActiveTrip$Keys.LastEvent],
        format: mfGetLastItemDetails,
        highlighted: true,
        highlightColorClass: mfSetHealthHighlightColor,
        highlightColorMap: [ActiveTrip$Keys.Health],
      },
      [PD_Col_InProg.NextEvent]: { column: PD_Col_InProg.NextEvent, map: [ActiveTrip$Keys.NextEvent] },
      [PD_Col_InProg.OwnerName]: {
        column: PD_Col_InProg.OwnerName,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.OwnerName],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_InProg.Consignment]: {
        column: PD_Col_InProg.Consignment,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.Consignment],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_InProg.WarehouseName]: {
        column: PD_Col_InProg.WarehouseName,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.WarehouseName],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_InProg.WarehouseId]: {
        column: PD_Col_InProg.WarehouseId,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.WarehouseId],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_InProg.DriverName]: { column: PD_Col_InProg.DriverName, map: [ActiveTrip$Keys.DriverName] },
      [PD_Col_InProg.Waypoints]: {
        column: PD_Col_InProg.Waypoints,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.WaypointStatus],
        format: mfWaypointCompleteStatus,
      },
      [PD_Col_InProg.TripType]: { column: PD_Col_InProg.TripType, map: 'trip_type', format: mfGetDriverType },
    },
  };

  public postDispatchScheduleTableMap: TableDataMap = {
    cells: {
      [PD_Col_Sched.PicupId]: { column: PD_Col_Sched.PicupId, map: ActiveTrip$Keys.PicupId },
      [PD_Col_Sched.TripId]: {
        column: PD_Col_Sched.TripId,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.TripId],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['none'],
      },
      [PD_Col_Sched.Scheduled]: {
        column: PD_Col_Sched.Scheduled,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.ScheduledDate],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['timeAgo'],
      },
      [PD_Col_Sched.ScheduledTimeStamp]: {
        column: PD_Col_Sched.ScheduledTimeStamp,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.ScheduledDate],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['date', 'dd LLL HH:mm'],
      },
      [PD_Col_Sched.LastEvent]: {
        column: PD_Col_Sched.LastEvent,
        map: [ActiveTrip$Keys.LastEvent],
        format: mfGetLastItemTime,
        displayPipeArgs: ['timeAgo'],
      },
      [PD_Col_Sched.LastEventDetails]: {
        column: PD_Col_Sched.LastEventDetails,
        map: [ActiveTrip$Keys.LastEvent],
        format: mfGetLastItemDetails,
      },
      [PD_Col_Sched.OwnerName]: {
        column: PD_Col_Sched.OwnerName,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.OwnerName],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_Sched.Consignment]: {
        column: PD_Col_Sched.Consignment,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.Consignment],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_Sched.WarehouseName]: {
        column: PD_Col_Sched.WarehouseName,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.WarehouseName],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_Sched.WarehouseId]: {
        column: PD_Col_Sched.WarehouseId,
        map: [ActiveTrip$Keys.PicupDetails, PicupDetailsKeys.WarehouseId],
        format: mfGetNestedObjectValue,
      },
      [PD_Col_Sched.Waypoints]: {
        column: PD_Col_Sched.Waypoints,
        map: ActiveTrip$Keys.TotalWaypoints,
        format: mfWaypointTotal,
      },
    },
  };

  public postDispatchColumns: PicupBusinessTripsColumns = {
    inProgress: this.postDispatchInProgressTableColumns,
    scheduled: this.postDispatchScheduledTableColumns,
  };

  public postDispatchTableMap: PicupBusinessTripsDataMap = {
    inProgress: this.postDispatchInProgressTableMap,
    scheduled: this.postDispatchScheduleTableMap,
  };

  public courierTableColumns: TableColumn[] = [
    { name: 'courier_order_id', displayName: 'Courier Order ID', hidden: true },
    { name: 'customer_reference', displayName: 'Customer Reference' },
    { name: 'courier_reference', displayName: 'Courier Reference' },
    { name: 'collection_date', displayName: 'Collected' },
    { name: 'delivery_date', displayName: 'Estimated Delivery' },
    // { name: 'delivery_address', displayName: 'Delivery Address' },
    { name: 'last_event', displayName: 'Last Event' },
    { name: 'price', displayName: 'Price' },
    { name: 'courier_order_state', displayName: 'Status' },
  ];

  public courierTableMap: TableDataMap = {
    cells: {
      courier_order_id: { column: 'Courier Order ID', map: 'courier_order_id' },
      customer_reference: {
        column: 'Customer Reference',
        displayPipeArgs: ['none'],
        map: ['collection', 'waybill', 'business_reference'],
        format: mfGetNestedObjectValuesByKeyArray,
      },
      courier_reference: {
        column: 'Courier Reference',
        map: ['courier_response', 'courier_reference'],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['none'],
      },
      collection_date: {
        column: 'Collected',
        map: ['collection', 'collection_date'],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['date', 'dd MMM yy, HH:mm'],
      },
      delivery_date: {
        column: 'Delivered',
        map: ['collection', 'delivery_date'],
        format: mfGetNestedObjectValue,
        displayPipeArgs: ['date', 'dd MMM yy, HH:mm'],
      },
      // delivery_address: { column: 'Delivery Address', map: ['collection', 'waybill', 'destination', 'address_line_1'], format: mfGetNestedObjectValuesByKeyArray },
      last_event: { column: 'Last Event', map: 'timestamp', displayPipeArgs: ['date', 'dd MMM yy, HH:mm'] },
      price: {
        column: 'Price',
        map: ['collection', 'waybill', 'price', 'inc_vat'],
        format: mfGetNestedObjectValuesByKeyArray,
        prefix: environment.currency_symbol + ' ',
        displayPipeArgs: DisplayPipes.decimalsTwo,
      },
      courier_order_state: {
        column: 'Status',
        map: 'courier_order_state',
        highlighted: true,
        highlightColorClass: mfSetCourierHealthHighlightColor,
        highlightColorMap: ['courier_order_state'],
        format: mfCourierStatusStrings,
      },
    },
  };

  public postDispatchTablePrimaryActions: TableAction[] = [
    { event: 'copyId', title: 'Copy ID', icon: IconTypes.Details, icon_color: UiColors.Grey8 },

    { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
  ];

  public postDispatchTableCourierOrderActions: TableAction[] = [
    { event: 'viewTrip', title: 'View Trip', icon: IconTypes.Eye, icon_color: UiColors.Grey8 },
  ];

  //   {
  //     Unknown = 0,
  //     Historic = 10,
  //     Scheduled = 20,
  //     Current = 30,
  //     Financials = 40,
  //     Alert = 50,
  //     Maintenance = 250,
  //     Exception = 500
  // }

  adjustDriverEarnings(data) {
    return axios({
      method: 'POST',
      url: 'picup/adjust-driver-earnings',
      data: data,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Earnings Adjusted' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }


  createSQLTrips() {
    return axios({
      method: 'POST',
      url: '/order/confirm-bucket',
      data: {
        bucket_id: this.bucketId,
        business_id: this.actingAs.id,
      },
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Trips Dispatched' });
      })
      .catch((error) => {
        this.notificationsService.publish({ type: 'error', message: error.response?.data.message });
      });
  }

  assignBucketPicups(data) {
    const picups: TripsTableData = {
      picup: [],
      contract: [],
      courier: [],
      hasFetched: false,
    };

    data.forEach((picup) => {
      switch (picup.picup_details.trip_type) {
        case TripTypes.Picup:
          picups.picup.push(picup);
          break;
        case TripTypes.Contract:
          picups.contract.push(picup);
          break;
        default:
          picups.picup.push(picup);
      }
    });
    const tableDataMap: TableDataMap = this.tableDataMap as TableDataMap;
    const tableColumns: TableColumn[] = this.tableColumns as TableColumn[];
    picups.picup = mapDataToTable(picups.picup, tableDataMap, tableColumns);
    picups.contract = mapDataToTable(picups.contract, tableDataMap, tableColumns);
    picups.hasFetched = true;
    this.picupsBucket$.next(picups);
  }

  assignBusinessPicups(data: ActiveTrip[]) {
    const picups = {
      scheduled: [],
      cancelled: [],
      complete: [],
      hasFetched: false,
    };

    data.forEach((picup) => {
      if (picup.picup_state === 'Cancelled') {
        picups.cancelled.push(picup);
      } else if (picup.picup_state === 'Scheduled') {
        picups.scheduled.push(picup);
      } else if (picup.picup_state === 'Completed') {
        picups.complete.push(picup);
      }
    });

    const tableDataMap: PicupBusinessTripsDataMap = this.tableDataMap as PicupBusinessTripsDataMap;
    const tableColumns: PicupBusinessTripsColumns = this.tableColumns as PicupBusinessTripsColumns;

    picups.scheduled = mapDataToTable(picups.scheduled, tableDataMap.scheduled, tableColumns.scheduled);
    // picups.financials = mapDataToTable(picups.financials, tableDataMap.inProgress, tableColumns.inProgress);
    picups.cancelled = mapDataToTable(picups.cancelled, tableDataMap.inProgress, tableColumns.inProgress);
    picups.complete = mapDataToTable(picups.complete, tableDataMap.inProgress, tableColumns.inProgress);
    picups.hasFetched = true;

    this.picupsHistoric$.next(picups);
  }

  assignBusinessWaypoints(data: ActiveTrip[]) {
    const picups = {
      scheduled: [],
      // financials: [],
      cancelled: [],
      complete: [],
      hasFetched: false,
    };

    data.forEach((picup) => {
      if (picup.picup_state === 'Cancelled') {
        picups.cancelled.push(picup);
      } else if (picup.picup_state === 'Scheduled') {
        picups.scheduled.push(picup);
        // } else if (picup.picup_state === 'Financials') {
        //   picups.financials.push(picup);
      } else if (picup.picup_state === 'Completed') {
        picups.complete.push(picup);
      }
    });

    this.picupWaypoints$.next(picups);
  }

  handleTablePostDispatchActions($event: TableActionTaken): void {
    const picupId = $event.rows[0].cells.find((cell) => cell.column === PD_Col_InProg.PicupId).value;
    this.router.navigate(['/dashboard/trip-view/', picupId]);
  }

  handleSQLTableActions($event: TableActionTaken): void {
    const tripId = $event.rows[0].cells.find((cell) => cell.column === 'trip_id').value;
    const selBox = document.createElement('textarea');
    switch ($event.action.event) {
      case 'viewTrip':
        this.router.navigate(['dashboard/orders/trip/', tripId]);
        break;
      case 'copyId':
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = tripId as string;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        break;
    }
  }

  handleTableCourierOrderActions($event: TableActionTaken): void {
    const courierOrderId = $event.rows[0].cells.find((cell) => cell.column === 'courier_order_id').value;
    const selBox = document.createElement('textarea');

    switch ($event.action.event) {
      case 'viewTrip':
        this.router.navigate(['/dashboard/courier-order/', courierOrderId]);
        break;
      case 'copyId':
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = courierOrderId as string;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        break;
    }
  }

  destroySubscriptions(): void {
    if (this.currentBusinessPicupsSubscription) {
      this.currentBusinessPicupsSubscription.unsubscribe();
    }
    if (this.historicBusinessPicupsSubscription) {
      this.historicBusinessPicupsSubscription.unsubscribe();
    }
    if (this.picupNotesSubscription) {
      this.picupNotesSubscription.unsubscribe();
    }
  }

  getPicupNotes(picup_id: string): void {
    this.picupNotesIds = [];
    const docRef = this.firestore.collection('notes');
    this.picupNotesSubscription = docRef.ref
      .where('picup_id', '==', picup_id)
      .orderBy('timestamp', 'asc')
      .onSnapshot((querySnapshot) => {
        const picupNotes = [];
        querySnapshot.forEach((note) => {
          picupNotes.push(note.data());
          this.picupNotesIds.push(note.id);
        });
        this.picupNotes.next(picupNotes);
      });
  }

  saveNote(note_data) {
    note_data['timestamp'] = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore.collection('notes').add(note_data);
  }

  markNoteViewed(): void {
    const docRef = this.firestore.collection('notes');
    this.picupNotesIds.forEach((note) => {
      docRef.doc(note).update({
        read_by: firebase.firestore.FieldValue.arrayUnion(this.user.user_id),
      });
    });
  }
}
