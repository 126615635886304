import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import * as fromAuth from 'app/auth/auth.reducer';
import { ActingAs, AuthUser, Warehouse } from 'app/interfaces/auth.interfaces';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FreshworksService implements OnDestroy {
  private readonly _isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoaded$ = this._isLoaded$.asObservable();

  private readonly _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly unsubscribe$ = new Subject<void>();

  private freshWorksInstance;

  constructor(private store: Store<fromAuth.State>) {
    this.store.select(fromAuth.isUserAuthenticated).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(((isAuthenticated) => {
      this._isAuthenticated$.next(isAuthenticated);
      this.clearUserData();
      this.initLoadingAndUserDetailsSubscription();
      this.initFreshWorks(isAuthenticated);
    }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initFreshWorks(isAuthenticated: boolean = false): void {
    const widgetId = isAuthenticated ? environment.freshWorks.clientBot : environment.freshWorks.publicBot;

    const freshworksScript = document.createElement('script');
    freshworksScript.src = environment.freshWorks.url;
    freshworksScript.setAttribute('chat', 'true');
    freshworksScript.setAttribute('widgetId', widgetId);
    freshworksScript.async = true;

    document.head.appendChild(freshworksScript);

    this.checkIfLoaded();
  }

  private checkIfLoaded(): void {
    const checkFreshworksLoad = setInterval(() => {
      if ((<any>window).fcWidget) {
        this.freshWorksInstance = (<any>window).fcWidget;

        if (this.freshWorksInstance.isLoaded()) {
          clearInterval(checkFreshworksLoad);
          this._isLoaded$.next(this.freshWorksInstance.isLoaded());
        }
      }
    }, 1000);
  }

  private initLoadingAndUserDetailsSubscription(): void {
    combineLatest([
      this.isLoaded$,
      this._isAuthenticated$,
      this.store.select(fromAuth.selectorActingAs),
      this.store.select(fromAuth.selectorUser),
      this.store.select(fromAuth.selectorWarehouses)
    ]).pipe(
      filter(([isLoaded, _isAuthenticated, _actingAs, _userDetails, _warehouses]: [boolean, boolean, ActingAs, AuthUser, Warehouse[]]) => isLoaded),
      takeUntil(this.unsubscribe$)
    ).subscribe(([_isLoaded, isAuthenticated, actingAs, userDetails, warehouses]: [boolean, boolean, ActingAs, AuthUser, Warehouse[]]) => {
      let config;

      const { name, email, phone, user_id } = userDetails;

      if (isAuthenticated) {
        config = {
          firstName: name.split(' ')[0] || '',
          lastName: name.split(' ')[1] || '',
          email,
          phone,
          meta: {
            cf_business_name: actingAs.name,
            cf_userid: user_id,
            cf_user_role: actingAs.roles.join(', '),
            ...((warehouses && warehouses.length > 0) ? { cf_warehouse_name: warehouses.map(warehouse => warehouse.name).join(', ') } : { cf_warehouse_name: '' }),
            ...((warehouses && warehouses.length > 0) ? { cf_warehouse_id: warehouses.map(warehouse => warehouse.id).join(', ') } : { cf_warehouse_id: '' })
          }
        };
      } else {
        config = {
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        };
      }

      (<any>window).fcWidget.user.isExists().then((data) => {
        if (data.data === true) {
          (<any>window).fcWidget.user.update(config);
        } else {
          (<any>window).fcWidget.user.create(config).then(() => { }).catch((err) => console.log(err));
        }
      })
    });
  }

  clearUserData(): void {
    if ((<any>window).fcWidget) {
      (<any>window).fcWidget.destroy();
      // Completely remove the widget from the DOM.
      const scriptToRemove = document.querySelector('script[src="//fw-cdn.com/11338766/4025167.js"]');
      if (scriptToRemove) {
        scriptToRemove.parentNode.removeChild(scriptToRemove);
      }
      (<any>window).fcWidget.user.clear();
      if ((<any>window).fcWidget.isOpen()) {
        (<any>window).fcWidget.close();
      }
    }
  }
}
