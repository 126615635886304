import { Component, Input, EventEmitter, Output, OnInit, OnChanges } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, OnChanges {
  @Input() sourceData: any;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() svgIcon: IconTypes;
  @Input() value: string;
  @Input() error: string;
  @Output() valueChanged = new EventEmitter<string>();
  @Output() keywordChanged = new EventEmitter<string>();

  constructor() {}

  onChange(event): void {
    if(!event.description){
      this.keywordChanged.emit(event);
    }
  }

  onSelectionChanged(): void {
    this.valueChanged.emit(this.value);
  }

  ngOnChanges(): void {}

  ngOnInit(): void {}

}
