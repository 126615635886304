import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ManageBusinessService } from '../manage-business.service';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { PhoneValidationPipe } from 'app/shared/pipes/phone-validation.pipe';

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.scss'],
})
export class AddBusinessComponent extends SimpleModalComponent<any, any> {
  public UiThemes = UiThemes;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };
  ModalSizes = ModalSizes;
  phoneTouched: boolean = false;
  business_model = {
    name: null,
    phone: null,
    registration_number: null,
    vat_number: null,
  };
  public actions: ModalAction[] = [
    { name: 'close', title: 'Close' },
    { name: 'confirm', title: 'Confirm', isPrimary: true },
  ];

  constructor(public manageBusinessService: ManageBusinessService, private phoneValidationPipe: PhoneValidationPipe) {
    super();
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'confirm':
        this.phoneTouched = true;
        if (!this.phoneValidationPipe.transform(this.business_model.phone)) {
          return;
        }
        this.result = this.business_model;
        this.close();
        break;
    }
  }
}
