import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { OperationsTrackingService } from 'app/operations-tracking/operations-tracking.service';
import { OrderFrontEndStateEnum } from 'app/dashboard/post-dispatch/post-dispatch.interfaces';
import { OrderStateEnum, OrderSummary } from 'app/operations-tracking/operations-tracking.interface';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { selectUberEnabledWarehousesConfig } from 'app/auth/auth.reducer';
import { BulkSendToCourierService } from 'app/operations-tracking/shared/bulk-send-to-courier.service';
import { ConfirmInputModalComponent } from 'app/shared/modals/confirm-input-modal/confirm-input-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';
import { BulkSendToCourierTableComponent } from 'app/operations-tracking/shared/components/bulk-send-to-courier-table/bulk-send-to-courier-table.component';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class SLAAlertsComponent extends OrderTrackingComponent implements OnInit ,OnDestroy {
  alertSubscription: Subscription;
  dataSource = new MatTableDataSource<OrderSummary>([]);
  OrderStateEnum = OrderStateEnum;
  uberAllowedWarehouses: string[] = [];
  constructor(
    public newTripsService: NewTripsService,
    public notificationsService: NotificationsService,
    public operationsTrackingService: OperationsTrackingService,
    public store: Store,
    public bulkSendToCourierService: BulkSendToCourierService,
    private simpleModalService: SimpleModalService,
    private dialog: MatDialog
  ) {
    super(notificationsService, operationsTrackingService, store, newTripsService);
  }

  ngOnInit(): void {
    this.store.select(selectUberEnabledWarehousesConfig).pipe(take(1)).subscribe(warehouses => {
      this.uberAllowedWarehouses = warehouses?.warehouse_ids.length > 0 ? [...warehouses.warehouse_ids] : []
    } )
    this.operationsTrackingService.usedIds$.pipe(take(1)).subscribe((ids: string[]) => {
      this.selectedIds = ids;
      this.getAlertOrders();
    });
  }

  ngOnDestroy(): void {
    this.alertSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAlertOrders(): void{
    const batches = this.getFirestoreBatchesForOrders(OrderFrontEndStateEnum.Alert, true);
    this.alertSubscription?.unsubscribe();
    this.alertSubscription = combineLatest(batches).subscribe((ordersSummary:  OrderSummary[][]) => {
      let orders: OrderSummary[] = [];
      orders = orders.concat(...ordersSummary);
      let data = this.filterResultsByWarehouse(orders);
      data = this.filterResultsByWaybill(data);
      this.dataSource.data = data;
    });
  }

  private filterResultsByWarehouse(orders: OrderSummary[]): OrderSummary[] {
    const allowedWarehouses = this.uberAllowedWarehouses ?? [];
    if(allowedWarehouses?.length > 0) {
      const data = orders.filter(order => allowedWarehouses.some(warehouseId => warehouseId === order.WarehouseId));
      return data;
    }
    return [];
  }

  private filterResultsByWaybill(orders: OrderSummary[]): OrderSummary[] {
    // remove when pnp fixes shtuff
    const data = orders.filter(order => !order.CustomerReference.toLowerCase().startsWith('one'));
    return data;
  }

  public bulkSendToCourier(): void{
    const orderIds = this.dataSource.data.map(order => order.Id)
    this.simpleModalService
      .addModal(ConfirmInputModalComponent, {
        title: 'Bulk Send to courier?',
        message: `You are sending ${orderIds?.length} orders to the courier ? To confirm, please type "courier" as shown`,
        key: 'courier',
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (!result) {
          return;
        }
        this.bulkSendToCourierService.bulkRecreateCourierOrder(orderIds);
        const matDialog = this.dialog.open(BulkSendToCourierTableComponent, {
          width: '1000px',
          height: '500px',
          disableClose: true
        });

        matDialog.afterClosed().subscribe(() => {
          this.bulkSendToCourierService.clearList();
        })
      })
  }
}
