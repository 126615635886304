<app-section>
  <heading [icon]="IconTypes.Gear" [color]="uiColors.Blue">
    <h4 class="heading-title">Integration</h4>
    <div class="controls"></div>
  </heading>
  <app-grid-container
    [columns]="'repeat(1, 1fr)'"
    [padding]="'5rem'"
    [colGap]="'2rem'"
    [rowGap]="'1rem'"
    [theme]="UiThemes.Light"
  >
    <div class="details">
      <h4>
        Api Key:<strong class="key"> {{ actingAs?.id }}</strong>
      </h4>
      <p>
        Integration documentation can be found at
        <a href="https://integrate.picup.co.za" target="_blank">integrate.picup.co.za</a>
      </p>
    </div>
    <div class="current-webhook" *ngIf="businessDetails?.picup_webhook_url">
      <h4>Current Webhook URL:</h4>
      <p>{{businessDetails?.picup_webhook_url}}</p>
    </div>
    <!-- <div class="current-webhook" *ngIf="businessDetails?.picup_webhook_url_v2">
      <h4>Order Ingestion Cancellation URL:</h4>
      <p>{{businessDetails?.picup_webhook_url_v2}}</p>
    </div> -->
    <div>
      <app-basic-button
        [processing]="processing"
        [active]="true"
        [title]="'Set Webhook URLS'"
        [type]="ButtonTypes.Highlight"
        (click)="setWebhookURL()"
      >
      </app-basic-button>
      <app-basic-button
        *ngIf="environment.appVersion === 2"
        [active]="false"
        [title]="'Send Test Webhooks'"
        [type]="ButtonTypes.Primary"
        (click)="sendTestWebhooks()"
        [processing]="loading"
      >
      </app-basic-button>
    </div>
  </app-grid-container>
</app-section>

<app-section>
  <heading [icon]="IconTypes.Gear" [color]="uiColors.Blue">
    <h4 class="heading-title">Cartrack Integration</h4>
    <div class="controls"></div>
  </heading>
  <app-grid-container
    [columns]="'repeat(1, 1fr)'"
    [padding]="'5rem'"
    [colGap]="'2rem'"
    [rowGap]="'1rem'"
    [theme]="UiThemes.Light"
  >
    <div class="details">
      <h4>
        Username:<strong class="key"> {{ cartrackIntegration?.username ?? '' }}</strong>
      </h4>
    </div>
    <div class="current-webhook">
      <h4>API Password:<strong class="key"> {{ cartrackIntegration?.password ?? '' }}</strong></h4>
    </div>
    <div>
      <app-basic-button
        [processing]="processing"
        [active]="true"
        [title]="'Set Cartrack Api Details'"
        [type]="ButtonTypes.Highlight"
        (click)="setCartrackApiKeys()"
      >
      </app-basic-button>
    </div>
  </app-grid-container>
</app-section>