import { MapMarkerColors } from './../../interfaces/ui.interfaces';
import { MapTypes } from './../../shared/map/map.interfaces';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FBDriverModel } from 'app/admin/admin.interfaces';
import * as fromAuth from 'app/auth/auth.reducer';
import { selectLastMileCollectionName, selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { ActingAs, AuthUser, UserRoles } from 'app/interfaces/auth.interfaces';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { BasicButton, ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { MenuDropDownItems } from 'app/shared/menu-dropdown/menu-drop-down.interfaces';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { LastMileService } from './last-mile.service';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-last-mile',
  templateUrl: './last-mile.component.html',
  styleUrls: ['./last-mile.component.scss'],
})
export class LastMileComponent implements OnInit, OnDestroy {
  public JustifyContent = JustifyContent;
  uiColors = UiColors;
  iconTypes = IconTypes;
  public icons = {
    types: IconTypes,
    colors: UiColors,
  };
  @Input() orderId: string;
  @Input() lastMileId: string;
  @Input() fleetAllocation: string;
  @Output() lastMileEvent: EventEmitter<string> = new EventEmitter();
  fsLastMileMappedData;
  lastMileSubscription: Subscription;
  polylineSubscription: Subscription;
  environment = environment;
  public viewTabs: BasicButton[] = [];
  tabs$;
  driverRole;
  driverTravelData;
  routeSubscription: Subscription;
  user: AuthUser;
  failedParcels = [];
  driver_id: string;
  driverData: FBDriverModel;
  mapTypes = MapTypes;
  markers = [];
  lastMilePolyline;
  actingAs: ActingAs;
  showRoute = false;
  showMap = false;
  buttonTypes = ButtonTypes;
  private lastMileCollectionName = null
  public adminMenuOptions: MenuDropDownItems[] = [];
  public firebaseMenuOptions: MenuDropDownItems[] = [
    { name: 'mobile-last-mile', displayName: 'Mobile Last Mile Node' },
  ];
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private store: Store<fromAuth.State>,
    private lastMileService: LastMileService,
    private firestore: AngularFirestore,
    private authService: AuthService
  ) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.routeSubscription = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.lastMileId = params['id'];
      }
    });
    this.store.select(selectorActingAs).subscribe((next) => {
      if (next) {
        this.actingAs = next;
      }
    });
    this.store.select(selectLastMileCollectionName).subscribe(value => {
      this.lastMileCollectionName = value ?? 'last-mile';
    })
    this.viewTabs = this.setViewTabs();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => (this.tabs$ = this.getActiveTab(this.route.root)));
    this.tabs$ = this.getActiveTab(this.route.root);
    this.adminMenuOptions = this.setMenuItems();
  }

  ngOnInit(): void {
    this.getLastMileObject(this.lastMileId, this.orderId);
    this.setViewTabs();
  }

  handleActingAs(businessId: string): void {
    if (businessId !== this.actingAs.id) {
      this.authService.setActingAs(businessId, false);
    }
  }

  // Do this inside the component to manage the multiple subscriptions
  getLastMileObject(lastMileId: string, orderId: string): void {
    this.lastMileSubscription?.unsubscribe();
    const docRef = this.firestore.collection(this.lastMileCollectionName);
    this.lastMileSubscription = docRef
      .doc(lastMileId)
      .valueChanges()
      .subscribe((res: any) => {
        this.driverRole = res.Role;
        if (res) {
          if (res.BusinessIds?.length === 1) {
            this.handleActingAs(res.BusinessIds[0]);
          }
          if (orderId && !this.user.is_admin) {
            this.filterLastMileParcelsByOrder(res, orderId);
          } else if (this.user.is_admin) {
            this.fsLastMileMappedData = res;
            this.constructMapData();
            this.showRoute = true;
            this.checkForFailedParcels(res.Parcels);
          } else {
            this.filterLastMileParcelsByBusiness(res);
          }
        } else {
          this.lastMileService.rehydrateAggregate(this.lastMileId);
        }
      });
  }

  filterLastMileParcelsByBusiness(fsLastMileMappedData: any): void {
    const valueObject = Object.values(fsLastMileMappedData.Parcels);
    const filteredParcels = [];
    valueObject.forEach((parcel: any) => {
      if (parcel.BusinessId === this.actingAs.id) {
        filteredParcels[parcel.ParcelWaybill] = fsLastMileMappedData.Parcels[parcel.ParcelWaybill];
      }
    });
    this.showRoute = Object.values(filteredParcels).length > 0;
    fsLastMileMappedData.Parcels = filteredParcels;
    this.fsLastMileMappedData = fsLastMileMappedData;
    this.constructMapData();
    this.checkForFailedParcels(filteredParcels);
  }

  filterLastMileParcelsByOrder(fsLastMileMappedData: any, orderId: string): void {
    const valueObject = Object.values(fsLastMileMappedData.Parcels);
    const filteredParcels = {};
    const filteredStagedAssignments = {};
    valueObject.forEach((parcel: any) => {
      if (parcel.OrderId === orderId  || this.actingAs.id === parcel.BusinessId) {
        filteredParcels[parcel.ParcelWaybill] = fsLastMileMappedData.Parcels[parcel.ParcelWaybill];
      }
    });
    this.showRoute = Object.values(filteredParcels).length > 0;
    fsLastMileMappedData.Parcels = filteredParcels;
    fsLastMileMappedData.StagedAssignments = filteredStagedAssignments;
    this.fsLastMileMappedData = fsLastMileMappedData;
    this.constructMapData();
    this.checkForFailedParcels(filteredParcels);
  }

  checkForFailedParcels(parcels) {
    const parcelArray = Object.values(parcels);
    this.failedParcels = parcelArray.filter((parcel: any) => {
      return parcel.LastMileParcelState === 'FailedCollection' || parcel.LastMileParcelState === 'FailedDelivery';
    });
  }

  getMarkerColor(LastMileParcelState) {
    // None = 0,
    // Assigned = 1,
    // Collected = 2,
    // FailedCollection = 3,
    // Delivered = 4,
    // FailedDelivery = 5,
    // Returned = 6,
    // Cancelled = 7,
    // FailedReturn = 8,
    // PendingRecollection = 9,
    // PendingRedelivery = 10,
    // PendingReturn = 11,
    // FinalCollectionAttemptFailed = 12,
    const colours = {
      Collection: null,
      Delivery: null,
    };
    switch (LastMileParcelState) {
      case 'Assigned':
        colours.Collection = MapMarkerColors.Blue;
        colours.Delivery = MapMarkerColors.Blue;
        break;
      case 'Collected':
        colours.Collection = MapMarkerColors.Green;
        colours.Delivery = MapMarkerColors.Blue;
        break;
      case 'FailedCollection':
        colours.Collection = MapMarkerColors.Red;
        colours.Delivery = MapMarkerColors.Red;
        break;
      case 'Delivered':
        colours.Collection = MapMarkerColors.Green;
        colours.Delivery = MapMarkerColors.Green;
        break;
      case 'FailedDelivery':
        colours.Collection = MapMarkerColors.Green;
        colours.Delivery = MapMarkerColors.Red;
        break;
      case 'Returned':
        colours.Collection = MapMarkerColors.Green;
        colours.Delivery = MapMarkerColors.Yellow;
        break;
      case 'Cancelled':
        colours.Collection = MapMarkerColors.Red;
        colours.Delivery = MapMarkerColors.Red;
        break;
      case 'PendingRecollection':
        colours.Collection = MapMarkerColors.Blue;
        colours.Delivery = MapMarkerColors.Blue;
        break;
      case 'PendingRedelivery':
        colours.Collection = MapMarkerColors.Green;
        colours.Delivery = MapMarkerColors.Blue;
        break;
      case 'PendingReturn':
        colours.Collection = MapMarkerColors.Green;
        colours.Delivery = MapMarkerColors.Blue;
        break;
      case 'FinalCollectionAttemptFailed':
        colours.Collection = MapMarkerColors.Red;
        colours.Delivery = MapMarkerColors.Red;
        break;
    }
    return colours;
  }

  constructMapData(): void {
    this.markers = [];
    if (this.fsLastMileMappedData.WaypointVisits?.length) {
      this.fsLastMileMappedData.WaypointVisits.forEach((waypoint: any) => {
        const marker1 = {
          latitude: waypoint.Location.Latitude,
          longitude: waypoint.Location.Longitude,
          status: this.getStatus(waypoint.VisitState),
          label: waypoint.WaypointIndex.toString(),
        };
        this.markers.push(marker1);
      });
    } else {
      const Parcels = Object.values(this.fsLastMileMappedData.Parcels);
      Parcels.forEach((parcel: any) => {
        const origin = parcel.OriginWarehouse ? parcel.OriginWarehouse : parcel.Origin;
        const destination = parcel.DestinationWarehouse ? parcel.DestinationWarehouse : parcel.Destination;
        const markerColours = this.getMarkerColor(parcel.LastMileParcelState);
        const marker1 = {
          latitude: destination.Latitude,
          longitude: destination.Longitude,
          status: markerColours.Delivery,
          label: 'D',
        };
        const marker2 = {
          latitude: origin.Latitude,
          longitude: origin.Longitude,
          status: markerColours.Collection,
          label: 'C',
        };
        this.markers.push(marker1, marker2);
      });
    }
    if (this.fsLastMileMappedData?.ActiveRouteId) {
      this.getDriverTravelData(this.fsLastMileMappedData?.ActiveRouteId);
      this.getPlannedPolyline(this.fsLastMileMappedData?.ActiveRouteId);
    }
  }

  getPlannedPolyline(route_id: string): void {
    const docRef = this.firestore.collection('route-polylines');
    this.polylineSubscription = docRef
      .doc(route_id)
      .valueChanges()
      .subscribe((res) => {
        this.lastMilePolyline = res;
      });
  }

  getDriverTravelData(route_id) {
    this.lastMileService.getTravelData(route_id).then((res) => (this.driverTravelData = res));
  }

  getStatus(visitState: string): MapMarkerColors {
    switch (visitState) {
      case 'None':
        return MapMarkerColors.Blue;
      case 'EnRoute':
        return MapMarkerColors.Yellow;

      case 'Visiting':
        return MapMarkerColors.Orange;

      case 'Finalized':
        return MapMarkerColors.Green;
    }
  }

  setMenuItems(): MenuDropDownItems[] {
    return [
      { name: 'reconcileParcels', displayName: 'Reconcile Parcels' },
      {
        name: 'rehydrate-order',
        displayName: 'Rehydrate Order',
        hidden: !this.actingAs.roles.includes(UserRoles.SuperAdministrator),
      },
    ];
  }

  ngOnDestroy(): void {
    this.lastMileSubscription.unsubscribe();
    if (this.polylineSubscription) {
      this.polylineSubscription.unsubscribe();
    }
    this.lastMileService.destroyLastMileSubscription();
  }

  buttonClicked($event: string): void {
    if ($event === 'Back') {
      this.backButton();
    } else {
      this.handleTabNavigation($event);
    }
  }

  handleTabNavigation($event: string): void {
    this.router.navigate(['dashboard/last-mile/' + this.lastMileId, { outlets: { LastMile: [$event.toLowerCase()] } }]);
  }

  getActiveTab(route: ActivatedRoute, url: string = '', breadcrumbs = []): { label: string; url: string }[] {
    // Initial route goes below if you want to add later
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : '';
    const path = route.routeConfig ? route.routeConfig.path : '';

    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      return this.getActiveTab(route.firstChild, nextUrl, newBreadcrumbs);
    }
    newBreadcrumbs.splice(0, 1);
    // TODO: REMOVE THIS WHEN FULL SITE IS FLESHED OUT
    return newBreadcrumbs;
  }

  backButton(): void {
    this.location.back();
  }

  setViewTabs(): BasicButton[] {
    return [
      {
        type: ButtonTypes.Primary,
        title: 'Back',
        hidden: this.orderId ? true : false,
      },
      { type: ButtonTypes.Primary, title: 'Details' },
      {
        type: ButtonTypes.Primary,
        title: 'Financials',
        hidden: !this.user.is_admin,
      },

      // { type: ButtonTypes.Primary, title: 'Timeline' },
    ];
  }

  handleFirebaseClick(): void {
    window.open(
      'https://console.firebase.google.com/u/0/project/' +
        environment.firebase.projectId +
        '/database/data/mobile-last-mile/' +
        this.lastMileId
    );
  }

  handleEventStoreClick(): void {
    window.open(environment.eventStoreStream + this.lastMileId);
  }

  refreshLastMile(): void {
    this.getLastMileObject(this.lastMileId, this.orderId);
    this.lastMileEvent.emit();
  }
}
