
import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NewTripsService } from 'app/dashboard/new-trips/new-trips.service';
import { Branding } from 'app/interfaces/businesses.interfaces';
import { MapMarkerColors } from 'app/interfaces/ui.interfaces';
import { MapTypes } from 'app/shared/map-view/map-view.interfaces';
import { Order, OrderParcel, OrderParcelStateEnum } from 'app/shared/shared.interfaces';
import { environment } from 'environments/environment';
import { Subject, Subscription } from 'rxjs';
import { DriverState } from 'app/driver/driver.interface';
import { SmsTrackingService, waybillToken } from './sms-tracking.service';
import { take, takeUntil } from 'rxjs/operators';
import { WaybillInfo } from './models/waybill-info.model';
import { FsDriverDetails } from './models/driver-details.model';
import { darkMapStyle, lightMapStyle } from 'app/shared/map/map.constants';
import { AuthService } from 'app/auth/auth.service';
import { AccmanLoginResponse } from 'app/auth/auth.interface';
declare let window: any;

export enum TrackingView {
  'nobanner' = 'nobanner',
  'maponly' = 'maponly',
  'norating' = 'norating',
  'all' = 'all'
}

@Component({
  selector: 'app-sms-tracking',
  templateUrl: './sms-tracking.component.html',
  styleUrls: ['./sms-tracking.component.scss'],
  providers: [
    SmsTrackingService
  ]
})
export class SmsTrackingComponent implements OnInit, AfterViewChecked, OnDestroy {
  constructor(
    public db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private authService: AuthService,
    private tripsService: NewTripsService,
    private router: Router,
    public smsTrackingService: SmsTrackingService
  ) { }

  order_waybill_number: string = '';
  view: TrackingView = TrackingView.all;
  showRating = false;
  showDriverOnMap = false;
  height;
  mapTypes = MapTypes;
  customer_text;
  businessId: string;
  orderId: string;
  driverDetails: FsDriverDetails;
  markers;
  polyLineSubscription;
  polylines = [];
  fsOrderData;
  fsLastMileMappedData;
  businessReferenceBreakdown;
  businessReference: string;
  tokenError = TokenErrorEnum

  errorDefaultMessage = 'We\'re sorry, your parcel waybill was not found.'

  supportEmailAddress = environment.supportEmailAddress;
  supportPhoneNumber = environment.supportPhoneNumber;

  driverSubscription: Subscription;
  driverStateSubscription: Subscription;
  lastMileSubscription: Subscription;
  trackingView = TrackingView

  unsubscribe$ = new Subject<void>();
  timeBeforeInactivity = 240000
  lastDriverResponse: string = ''
  // For uber assignments etc...
  isSpecialCourierOrders: boolean = false;
  showSpecialCourierFinalizedBlock: boolean = false;

  darkMapStyle = darkMapStyle;
  lightMapStyle = lightMapStyle;

  businessBranding: Branding;

  error = false;

  readonly branding$ = this.smsTrackingService.branding$;
  readonly isWaybillLoading$ = this.smsTrackingService.isLoading$;
  readonly courierDriverDetails$ = this.smsTrackingService.courierDriverDetails$;

  ngOnInit(): void {
    if (this.router.url.includes('order-tracking')) {
      this.setOrderTrackingWaybillDetails();
    } else {
      this.getParams();
    }
    this.getFirestoreToken();
    this.setViewHeight();
    this.authService.isFsTokenLoaded$.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      if(value){
        this.getWaybillInformation();
      }
    })
  }

  ngAfterViewChecked(): void {
    if ((<any>window).fcWidget) {
      (<any>window).fcWidget.destroy();
    }
  }

  ngOnDestroy(): void {
    if (this.driverSubscription) {
      this.driverSubscription.unsubscribe();
    }
    if (this.lastMileSubscription) {
      this.lastMileSubscription.unsubscribe();
    }
    this.driverStateSubscription?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setOrderTrackingWaybillDetails(): void {
    const params = this.route.snapshot.queryParams;
    const waybill: string = params['waybill'];
    this.order_waybill_number = waybill.substring(3);
    const view = params.view;
    if (view) {
      this.view = TrackingView[view];
      this.setViewHeight();
    }

  }

  private getParams(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.order_waybill_number = routeParams.get('id');
    const queryParams = this.route.snapshot.queryParams;
    const view = queryParams['view'];
    if (view) {
      this.view = TrackingView[view];
      this.setViewHeight();
    }

  }

  private setViewHeight(): void {
    if (this.view === this.trackingView.all || this.view === this.trackingView.norating) {
      this.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 80;
    } else if (this.view === this.trackingView.nobanner) {
      this.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 30;
    } else {
      this.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
    }
  }

  private getBranding(businessId: string): void {
    this.smsTrackingService.getBranding(businessId).pipe(take(1)).subscribe({
      next: (response) => {
        this.businessBranding = response;
        this.smsTrackingService.isLoading = false;
      },
      error: () => {
        this.error = true;
        this.errorDefaultMessage = 'Something went wrong please refresh and try again'
        this.smsTrackingService.isLoading = false;
      }
    });
  }

  private getWaybillInformation(): void {
    const isWaybillNumberEncrypted = isNaN(Number(this.order_waybill_number));

    this.smsTrackingService.getWaybillInfoByReference(this.order_waybill_number, isWaybillNumberEncrypted).pipe(take(1)).subscribe({
      next: (data: WaybillInfo[]) => {
        if (data.length === 0) {
          this.error = true;
          return;
        }

        this.orderId = data[0].OrderId;
        this.businessId = data[0].BusinessId;
        this.order_waybill_number = data[0].OrderWaybill;
        this.isSpecialCourierOrders = !!data[0].CourierCode;

        this.getOrderDetails();
        this.getBranding(this.businessId);
      },
      error: () => {
        this.error = true;
      }
    });
  }

  private getOrderDetails(): void {
    this.smsTrackingService.getOrderDetails(this.orderId).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe({
        next: (order: Order) => {
          if (order) {
            this.filterOrderParcelsByWaybill(order, this.order_waybill_number);
          } else {
            this.tripsService.rehydrateAggregate(this.orderId);
          }
        },
        error: () => {
          this.error = true;
        }
      });
  }

  private filterOrderParcelsByWaybill(orderData: Order, waybill): void {
    orderData.Parcels = Object.values(orderData.Parcels);
    orderData.Parcels = orderData.Parcels.filter((parcel: OrderParcel) => parcel.ParcelWaybill.slice(0, 12) === waybill);
    this.fsOrderData = orderData;
    this.createSummaryData(orderData.Parcels);
  }

  private getFirestoreToken(): void{
    // get token from BE
    this.smsTrackingService.checkIfLoginIsAvailable().pipe(take(1)).subscribe({
      next: (value: AccmanLoginResponse) => {
        localStorage.setItem('cust_token', value.Value.CustomToken);
        this.smsTrackingService.connectToFireAuthWithToken(value.Value.CustomToken);
      },
      error: () => {
        this.smsTrackingService.getFirestoreToken(this.order_waybill_number)
          .pipe(take(1))
          .subscribe(
            {next: (res: waybillToken) => {
              this.smsTrackingService.connectToFireAuthWithToken(res.result)
            }, error: (error) => {
              if(error?.error?.message === 'expired'){
                this.smsTrackingService.tokenError = TokenErrorEnum.expired
              }
              this.smsTrackingService.isLoading = false;
            }})
      }
    })
    // continue as normal
  }

  getDriver(driver_id) {
    if (driver_id === this.driverDetails?.id || !driver_id) {
      return;
    }
    const docRef = this.firestore.collection('driver-details');
    this.driverSubscription?.unsubscribe();
    this.driverSubscription = docRef
      .doc(driver_id)
      .valueChanges()
      .subscribe((res: any) => {
        this.driverDetails = res;
      });
  }


  private createSummaryData(parcels: OrderParcel[]): void {
    this.businessReferenceBreakdown = {};
    parcels.forEach((parcel: OrderParcel) => {
      const parcelBusinessReference = parcel.BusinessReference;
      const origin = parcel.OriginWarehouse ? parcel.OriginWarehouse : parcel.Origin;
      const destination = parcel.DestinationWarehouse ? parcel.DestinationWarehouse : parcel.Destination;
      if (Object.keys(this.businessReferenceBreakdown).includes(parcelBusinessReference)) {
        this.businessReferenceBreakdown[parcelBusinessReference].Parcels.push(this.reduceParcelDetails(parcel));
      } else {
        this.businessReferenceBreakdown[parcelBusinessReference] = {
          Parcels: [this.reduceParcelDetails(parcel)],
          Origin: origin,
          LastMileId: parcel.LastMileId,
          Destination: destination,
          OrderId: parcel.OrderId,
          BusinessReference: parcel.BusinessReference,
          OrderParcelState: parcel.OrderParcelState,
        };
      }
    });

    this.businessReferenceBreakdown = Object.values(this.businessReferenceBreakdown);
    this.businessReference = this.businessReferenceBreakdown[0].BusinessReference;

    if (this.businessReferenceBreakdown[0].LastMileId) {
      this.getLastMileObject(this.businessReferenceBreakdown[0].LastMileId);
    } else if (this.isSpecialCourierOrders) {
      // Special courier orders like Uber.
      this.smsTrackingService.getCourierDriverDetails(this.order_waybill_number).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (driverDetails: FsDriverDetails) => {
          if (!driverDetails.hasError) {
            this.driverDetails = driverDetails;
            this.lastDriverResponse = driverDetails.last_updated;

            this.showDriverOnMap = parcels.some((parcel) => {
              return !parcel.FinalizedAt;
            });
          }
        },
        error: () => {
          this.error = true;
        }
      });

      this.constructMapData();
    } else {
      // For normal courier orders, RAM etc...
      this.constructMapData(false);
    }
  }

  callDriver(number: string): void {
    window.open('tel:' + number);
  }

  getLastMileObject(lastMileId: string): void {
    if (!lastMileId) {
      this.fsLastMileMappedData = undefined;
      return;
    }
    const docRef = this.firestore.collection('last-mile');
    if (this.lastMileSubscription) {
      return;
    }
    this.lastMileSubscription = docRef
      .doc(lastMileId)
      .valueChanges()
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.filterLastMileParcelsByReference(res, this.businessReference);
          } else {
            this.fsLastMileMappedData = undefined;
          }
        },
        error: () => {
          this.error = true;
        }
      });
  }


  filterLastMileParcelsByReference(fsLastMileMappedData, businessReference): void {
    fsLastMileMappedData.Parcels = Object.values(fsLastMileMappedData.Parcels);
    fsLastMileMappedData.Parcels = fsLastMileMappedData.Parcels.filter(
      (parcel) => parcel.BusinessReference === businessReference
    );
    fsLastMileMappedData.WaypointVisits = fsLastMileMappedData?.WaypointVisits?.filter((visit) =>
      visit.Contacts.some((contact) => contact.BusinessReference === businessReference)
    );
    this.fsLastMileMappedData = fsLastMileMappedData;
    this.getDriverDetails(fsLastMileMappedData.DriverId);
    this.getDriverLastUpdated(fsLastMileMappedData.DriverId);
    this.getPolyLine(this.fsLastMileMappedData.ActiveRouteId);
    this.showDriverOnMap = fsLastMileMappedData?.Parcels.some((parcel) => {
      return !parcel.FinalizedAt;
    });
    this.constructMapData();
  }

  getPolyLine(activeRouteId: string): void {
    if (!activeRouteId) {
      return;
    }

    this.smsTrackingService.getOrderRoutePolyLine(activeRouteId).pipe(take(1))
      .subscribe({
        next: (res: any) => {
          const polylines = [];
          polylines.push(res.WaybillPolylines[this.order_waybill_number]);
          this.polylines = polylines;
        },
        error: () => {
          this.error = true;
        }
      });
  }

  getDriverDetails(driver_id: string): void {
    if (driver_id === this.driverDetails?.id || !driver_id) {
      return;
    }
    this.smsTrackingService.getDriverDetails(driver_id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (driverDetails: FsDriverDetails) => {
        this.driverDetails = driverDetails;
      },
      error: () => {
        this.error = true;
      }
    });
  }

  getDriverLastUpdated(driver_id: string): void {
    if (driver_id === this.driverDetails?.id || this.showRating || !driver_id) {
      if (driver_id == null) {
        this.lastDriverResponse = '';
      }
      return;
    }
    this.driverStateSubscription?.unsubscribe();
    const docRef = this.firestore.collection('driver-states');
    this.driverStateSubscription = docRef
      .doc(driver_id)
      .valueChanges()
      .subscribe({
        next: (res: DriverState) => {
          this.lastDriverResponse = res.last_location_update
        },
        error: () => {
          this.error = true;
        }
      });
  }

  reduceParcelDetails(parcel: OrderParcel) {
    return {
      Dimensions: parcel.Dimensions,
      ParcelReference: parcel.ParcelReference,
      ParcelWaybill: parcel.ParcelWaybill,
      OrderParcelState: parcel.OrderParcelState,
      Barcode: parcel.Barcode,
      CourierParcelState: parcel.CourierParcelState,
    };
  }

  constructMapData(showCollectionPin: boolean = true): void {
    this.markers = [];
    this.businessReferenceBreakdown.forEach((business_ref) => {
      const status = this.businessBranding?.primary_color
        ? { collection: this.businessBranding?.primary_color, delivery: this.businessBranding?.primary_color }
        : { delivery: MapMarkerColors.Blue, collection: MapMarkerColors.Blue };

      const marker1 = {
        latitude: business_ref.Destination.Latitude,
        longitude: business_ref.Destination.Longitude,
        status: status?.delivery,
        label: 'D',
        icon: null
      };
      if(this.businessBranding?.delivery_icon) {
        marker1.icon = this.businessBranding?.delivery_icon
        marker1.label = ''
      }
      this.markers.push(marker1);

      if (showCollectionPin) {
        const marker2 = {
          latitude: business_ref.Origin.Latitude,
          longitude: business_ref.Origin.Longitude,
          status: status?.collection,
          label: 'C',
          icon: null
        };
        if(this.businessBranding?.collection_icon) {
          marker2.icon = this.businessBranding?.collection_icon
          marker2.label = ''
        }

        this.markers.push(marker2);
      }

    });
    this.customer_text = this.renderText();
  }

  private renderLastMileParcelUpdateText(): string {
    const contact_name = this.businessReferenceBreakdown[0]?.Destination.Contact.Name;
    const warehouse_route = this.fsLastMileMappedData?.WarehouseVisit;
    const waypoint = this.fsLastMileMappedData?.WaypointVisits ? this.fsLastMileMappedData?.WaypointVisits[0] : null;
    const parcel = this.fsLastMileMappedData?.Parcels[0];

    switch (true) {
      case !!parcel?.FinalizedAt:
        this.showRating = true;
        return 'Good news, ' + contact_name + '! Your delivery is complete';
      case warehouse_route?.VisitState === 'EnRoute':
        return 'Driver En Route to ' + warehouse_route.WarehouseName;
      case warehouse_route?.VisitState === 'Visiting':
        return 'The driver has arrived at ' + warehouse_route.WarehouseName;
      case waypoint?.VisitState === 'EnRoute':
        return 'Sit tight ' + contact_name + ', your delivery is on the way!';
      case waypoint?.VisitState === 'Visiting':
        return 'Your driver has arrived!';
      case parcel?.LastMileParcelState === 'Collected':
        return 'Your parcel(s) has been collected, the driver will be on their way shortly';
      case !parcel:
        this.showRating = true;
        return 'Good news, ' + contact_name + '! Your delivery is complete';
      default:
        return 'Tracking information will be available once the driver begins';
    }
  }

  private renderCourierParcelUpdateText(): string {
    const parcels = this.businessReferenceBreakdown[0].Parcels;
    switch (true) {
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Delivered): {
        this.showRating = true;
        return 'Good news, ' + this.businessReferenceBreakdown[0]?.Destination.Contact.Name + '! Your delivery is complete';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Collected): {
        return 'Your parcels have been collected';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.PendingAllocation): {
        return 'Tracking information will be available once the driver begins';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedDelivery): {
        return 'Your parcel delivery has been failed';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.OutForDelivery): {
        return 'Your parcel is on its way to you';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedCollection): {
        return 'Your parcel collection has been failed';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FinalCollectionAttemptFailed): {
        return 'Your parcel final collection attempt has failed';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Returned): {
        return 'Your parcel has been returned';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.FailedReturn): {
        return 'Failed to return your parcel';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.Cancelled): {
        return 'Your parcel delivery has been cancelled';
      }
      case parcels.every((parcel) => parcel.CourierParcelState === OrderParcelStateEnum.AtCourierCenter): {
        return 'Your parcel is at the courier center';
      }
      case parcels.every((parcel) => parcel.CourierParcelState === OrderParcelStateEnum.WaybillCreationFailed): {
        return 'Failed to create the waybill for your parcel';
      }
      case parcels.every((parcel) => parcel.CourierParcelState === OrderParcelStateEnum.WaybillCreated): {
        return 'Your order has been created with the courier';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.OutForCollection): {
        return 'Your parcel is being collected';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.AtSortingFacility): {
        return 'Your parcel is at a sorting facility';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.AllocationFailed): {
        return 'Your parcel allocation has failed';
      }
      case parcels.every((parcel) => parcel.OrderParcelState === OrderParcelStateEnum.ExecutionFailed): {
        return 'Your parcel execution failed';
      }
      case parcels.every((parcel) => parcel.CourierParcelState === OrderParcelStateEnum.DriverAssigned): {
        return 'A driver has been assigned to your order';
      }
    }
  }

  renderSpecialCourierOrderParcelUpdateText(): string {
    const parcel = this.businessReferenceBreakdown[0].Parcels[0];
    switch (true) {
      case parcel.OrderParcelState === OrderParcelStateEnum.Delivered: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Good news, ' + this.businessReferenceBreakdown[0]?.Destination.Contact.Name + '! Your delivery is complete';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.Collected: {
        return 'Your parcels have been collected';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.PendingAllocation: {
        return 'Tracking information will be available once the driver begins';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.FailedDelivery: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel delivery has been failed';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.OutForDelivery: {
        return 'Your parcel is on its way to you';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.FailedCollection: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel collection has been failed';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.FinalCollectionAttemptFailed: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel final collection attempt has failed';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.Returned: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel has been returned';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.FailedReturn: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Failed to return your parcel';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.Cancelled: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel delivery has been cancelled';
      }
      case parcel.CourierParcelState === OrderParcelStateEnum.AtCourierCenter: {
        return 'Your parcel is at the courier center';
      }
      case parcel.CourierParcelState === OrderParcelStateEnum.WaybillCreationFailed: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Failed to create the waybill for your parcel';
      }
      case parcel.CourierParcelState === OrderParcelStateEnum.WaybillCreated: {
        return 'Your order has been created with the courier';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.OutForCollection: {
        return 'Your parcel is being collected';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.AtSortingFacility: {
        return 'Your parcel is at a sorting facility';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.AllocationFailed: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel allocation has failed';
      }
      case parcel.OrderParcelState === OrderParcelStateEnum.ExecutionFailed: {
        this.showSpecialCourierFinalizedBlock = true;
        return 'Your parcel execution failed';
      }
      case parcel.CourierParcelState === OrderParcelStateEnum.DriverAssigned: {
        return 'A driver has been assigned to your order';
      } default:
        this.showSpecialCourierFinalizedBlock = false;
        return 'Tracking information will be available once the driver begins';
    }
  }

  renderText(): string {
    if (this.isSpecialCourierOrders) {
      return this.renderSpecialCourierOrderParcelUpdateText();
    }

    if (
      this.fsLastMileMappedData &&
      (this.fsLastMileMappedData?.WaypointVisits?.length || this.fsLastMileMappedData?.WarehouseVisit)
    ) {
      return this.renderLastMileParcelUpdateText();
    } else {
      return this.renderCourierParcelUpdateText();
    }
  }

  showConnectionBanner(): boolean {
    if (this.lastDriverResponse.length > 0) {
      return (new Date().getTime() - new Date(this.lastDriverResponse).getTime()) > this.timeBeforeInactivity;
    }
    return false;
  }

  getDriverIcon(): string {
    if(this.businessBranding && this.driverDetails) {
      if(this.businessBranding?.default_driver_icon){
        return this.businessBranding?.default_driver_icon;
      }

      if(this.businessBranding.vehicle_icon_car
      || this.businessBranding.vehicle_icon_motorbike
      || this.businessBranding.vehicle_icon_small_van
      || this.businessBranding.vehicle_icon_large_van) {
        switch(this.driverDetails.active_vehicle) {
          case 'vehicle-car':
          {
            if(this.businessBranding.vehicle_icon_car) {
              return this.businessBranding.vehicle_icon_car;
            }
            break;
          }
          case 'vehicle-motorcycle':
          {
            if(this.businessBranding.vehicle_icon_motorbike){
              return this.businessBranding.vehicle_icon_motorbike;
            }
            break;
          }
          case 'vehicle-small-van':
          {
            if(this.businessBranding.vehicle_icon_small_van){
              return this.businessBranding.vehicle_icon_small_van;
            }
            break;
          }
          case 'vehicle-large-van':
          {
            if(this.businessBranding.vehicle_icon_large_van){
              return this.businessBranding.vehicle_icon_large_van;
            }
            break;
          }
        }
      }
    }
    return '/assets/img/vehicles/' + (this.driverDetails?.active_vehicle ? this.driverDetails?.active_vehicle : 'vehicle-car') + '-top.svg';
  }

  // private getMarkerColor(mappedData) {
  //   if (mappedData?.WarehouseVisit) {
  //     switch (true) {
  //       case mappedData.WarehouseVisit.VisitState === 'EnRoute':
  //         return {
  //           delivery: MapMarkerColors.Blue,
  //           collection: MapMarkerColors.Yellow,
  //         };
  //       case mappedData.WarehouseVisit.VisitState === 'Visiting':
  //         return {
  //           delivery: MapMarkerColors.Blue,
  //           collection: MapMarkerColors.Orange,
  //         };
  //       default:
  //         return {
  //           delivery: MapMarkerColors.Blue,
  //           collection: MapMarkerColors.Blue,
  //         };
  //     }
  //   } else if (mappedData?.WaypointVisits?.length) {
  //     const waypoint = mappedData.WaypointVisits[0];
  //     switch (true) {
  //       case waypoint.VisitState === 'None':
  //         return {
  //           delivery: MapMarkerColors.Blue,
  //           collection: MapMarkerColors.Green,
  //         };
  //       case waypoint.VisitState === 'EnRoute':
  //         return {
  //           delivery: MapMarkerColors.Yellow,
  //           collection: MapMarkerColors.Green,
  //         };
  //       case waypoint.VisitState === 'Visiting':
  //         return {
  //           delivery: MapMarkerColors.Orange,
  //           collection: MapMarkerColors.Green,
  //         };
  //       case waypoint.VisitState === 'Finalized':
  //         return {
  //           delivery: MapMarkerColors.Green,
  //           collection: MapMarkerColors.Green,
  //         };
  //       default:
  //         return {
  //           delivery: MapMarkerColors.Blue,
  //           collection: MapMarkerColors.Blue,
  //         };
  //     }
  //   } else {
  //     const parcels = mappedData.Parcels;
  //     if (parcels.every((parcel) => parcel.LastMileParcelState === 'Delivered')) {
  //       return {
  //         delivery: MapMarkerColors.Green,
  //         collection: MapMarkerColors.Green,
  //       };
  //     } else if (parcels.every((parcel) => parcel.LastMileParcelState === 'Cancelled')) {
  //       return {
  //         delivery: MapMarkerColors.Red,
  //         collection: MapMarkerColors.Red,
  //       };
  //     } else if (parcels.every((parcel) => parcel.LastMileParcelState === 'Returned')) {
  //       return {
  //         delivery: MapMarkerColors.Red,
  //         collection: MapMarkerColors.Green,
  //       };
  //     } else if (parcels.some((parcel) => parcel.LastMileParcelState === 'Assigned')) {
  //       return {
  //         delivery: MapMarkerColors.Blue,
  //         collection: MapMarkerColors.Blue,
  //       };
  //     } else if (parcels.every((parcel) => parcel.LastMileParcelState === 'Collected')) {
  //       return {
  //         delivery: MapMarkerColors.Blue,
  //         collection: MapMarkerColors.Green,
  //       };
  //     } else if (
  //       parcels.some((parcel) => parcel.LastMileParcelState === 'ExecutionFailed') ||
  //       parcels.some((parcel) => parcel.LastMileParcelState === 'FailedCollection')
  //     ) {
  //       return {
  //         delivery: MapMarkerColors.Blue,
  //         collection: MapMarkerColors.Red,
  //       };
  //     } else if (
  //       parcels.some((parcel) => parcel.LastMileParcelState === 'FailedDelivery') ||
  //       parcels.some((parcel) => parcel.LastMileParcelState === 'FailedReturn')
  //     ) {
  //       return {
  //         delivery: MapMarkerColors.Red,
  //         collection: MapMarkerColors.Green,
  //       };
  //     } else {
  //       return {
  //         delivery: MapMarkerColors.Blue,
  //         collection: MapMarkerColors.Blue,
  //       };
  //     }
  //   }
  // }
}

export enum TokenErrorEnum{
  expired = 'expired'
}
