import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ModalAction, ModalSizes } from 'app/shared/modals/modal.interfaces';
import { ManageBusinessService } from '../manage-business.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { CartrackIntegration } from '../integration-details/integration-details.component';

@Component({
  selector: 'app-set-cartrack',
  templateUrl: './set-cartrack.component.html',
  styleUrls: ['./set-cartrack.component.scss']
})
export class SetCartrackComponent extends SimpleModalComponent<any, any>implements OnInit {

  public actions: ModalAction[] = [
    { name: 'close', title: 'Close' },
    { name: 'apply', title: 'Apply', isPrimary: true },
  ];
  public UiThemes = UiThemes;
  ModalSizes = ModalSizes;
  IconTypes = IconTypes;
  uiColors = UiColors;
  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  cartrackIntegration: CartrackIntegration | undefined;


  constructor(public manageBusinessService: ManageBusinessService, private store: Store<any>) {
    super();
  }

  ngOnInit() {
  }

  handleAction($event: string): void {
    switch ($event) {
      case 'close':
        this.close();
        break;
      case 'apply':
        this.result = this.cartrackIntegration;
        this.close();
        break;
    }
  }
}
