import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SetWebhookComponent } from '../set-webhook/set-webhook.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { MapTypes } from 'app/shared/map/map.interfaces';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { ManageBusinessService } from '../manage-business.service';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SetCartrackComponent } from '../set-cartrack/set-cartrack.component';

@Component({
  selector: 'app-integration-details',
  templateUrl: './integration-details.component.html',
  styleUrls: ['./integration-details.component.scss']
})
export class IntegrationDetailsComponent implements OnInit, OnDestroy {
  public IconTypes = IconTypes;
  public ButtonTypes = ButtonTypes;
  public UiThemes = UiThemes;
  public MapTypes = MapTypes;
  environment = environment;
  uiColors = UiColors;

  @Input() actingAs;
  @Input() businessDetails;

  loading = false;
  processing = false;
  @Output() reload = new EventEmitter<any>();

  cartrackIntegration: CartrackIntegration = { username: '', password: '' };
  private unsubscribe$ = new Subject<void>();

  constructor(private simpleModalService: SimpleModalService, private manageBusinessService: ManageBusinessService) {
  }

  ngOnInit(): void {
    this.getCartrackApikeys();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setWebhookURL(): void {
    this.processing = true;
    this.simpleModalService
      .addModal(SetWebhookComponent, { businessDetails: this.businessDetails })
      .subscribe((result) => {
        if (!result) {
          this.processing = false;
          return;
        }
        this.manageBusinessService
          .setWebhookURL(result)
          .then(() => {
            this.reload.emit(true);
            this.processing = false;
          })
          .catch(() => (this.processing = false));
      });
  }

  sendTestWebhooks(): void {
    this.loading = true;
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Test Webhooks',
        message: 'This will send an example of each webhook to the configured address.'
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          this.loading = false;
          return;
        }
        this.manageBusinessService
          .testWebhooks()
          .then(() => (this.loading = false))
          .catch(() => (this.loading = false));
      });
  }

  getCartrackApikeys(): void {
    this.manageBusinessService.getCartrackAPIKeyData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe( (value: businessFEConfig) => {
        this.cartrackIntegration = value?.cartrackIntegration
      })
  }

  setCartrackApiKeys(): void {
    this.simpleModalService
      .addModal(SetCartrackComponent, { cartrackIntegration: this.cartrackIntegration })
      .subscribe((result) => {
        if (!result) {
          this.processing = false;
          return;
        }
        this.manageBusinessService.setCartrackAPIKeyData(result);
      });
  }

}

export interface CartrackIntegration {
  username: string;
  password: string;
  new?: boolean
}

export interface businessFEConfig {
  cartrackIntegration: CartrackIntegration;
  consignmentValues: string[];
  useCustomConsignments: boolean;
}
