<app-modal
  [open]="true"
  [actions]="actions"
  [size]="ModalSizes.Large"
  [highlightPrimaryAction]="true"
  (handleAction)="handleAction($event)"
>
  <heading [icon]="Icons.Types.Business" [color]="uiColors.Blue">
    <h4 class="heading-title">{{ 'Set Cartrack API Key' }}</h4>
  </heading>
  <div class="modal-body">
    <app-grid-container [columns]="'repeat(1, 1fr)'" [colGap]="'2rem'" [rowGap]="'1rem'" [padding]="'2rem'">
        <app-grid-container [columns]="'repeat(2, 1fr)'" [colGap]="'2rem'" [rowGap]="'1rem'" [padding]="'0'">
          <input-field
            [type]="'text'"
            [labelTheme]="'key'"
            (valueChanged)="cartrackIntegration?.username != null ? cartrackIntegration.username = $event : cartrackIntegration = {username: $event, password : '', new: true }"
            [autoComplete]="'off'"
            [value]="cartrackIntegration?.username"
            [label]="'USERNAME  (key: username)'"
            [required]="true"
          >
          </input-field>
          <input-field
            [type]="'text'"
            [labelTheme]="'key'"
            (valueChanged)="cartrackIntegration?.password != null ? cartrackIntegration.password = $event : cartrackIntegration = {username: '', password : $event, new: true}"
            [autoComplete]="'off'"
            [value]="cartrackIntegration?.password"
            [label]="'PASSWORD (key: password)'"
            [required]="true"
          >
          </input-field>
        </app-grid-container>
    </app-grid-container>
  </div>
</app-modal>
