<div class="background">
    <div class="gradient">
        <div class="gradient-v2">
            <div class="img-flex">
                    <div style="width: 10%;"></div>
                    <div class="img-top">
                        <div class="img-container">
                            <img src="/assets/img/picup-logo.svg" />
                        </div>
                    </div>
            </div>
            <div class="container">
                <div class="row">
                    <div style="width: 20%"></div>
                    <div class="column flex-box-start" *ngIf="validOOB; else invalidOOB">
                        <form [formGroup]="resetPasswordForm" (ngSubmit)="passwordReset()">
                                <div class="password-input">
                                    <h3 class="password-heading">Create a password</h3>
                                    <div>
                                        <input placeholder="Password*" id="password" [type]="showPassword ? 'text' : 'password'" formControlName="newPassword" />
                                        <app-icon (click)="toggleVisibility()" class="visible-icon" [icon]="IconTypes.Eye" [size]="1.5" [color]="UiColors.Grey4"></app-icon>
                                    </div>
                                    <div class="error-text-flex">
                                        <ng-container *ngIf="passwordErrors$ | async as errors">
                                            <ng-container *ngFor="let error of errors">
                                                <div style="padding-bottom: 2px;">
                                                    <label [class]="(password.touched || password.dirty) && password.invalid ? 'password-label' : 'password-label-dirty'">
                                                        {{error}}
                                                    </label>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                                <div>
                                    <button mat-button type="submit" 
                                        [disabled]="!resetPasswordForm.valid || (resetProcessing$ | async)" 
                                        class="reset-button">Reset Password
                                    </button>
                                </div>
                            <!-- <button (click)="generateRandomPassword(8)">Generate</button> -->
                        </form>
                    </div>
                    <ng-template #invalidOOB>
                        <div class="invalid-box">
                            <h3>Invalid Password Reset Link</h3>
                            <span>Your password reset link has been used, or has expired.</span>
                            <span>Please check your email for a newer reset link (which often appears at the bottom of an email thread),
                             or <a (click)="gotoResetPassword()" class="hyperlink-text">click here</a> to return to the password reset page and request another one</span>
                        </div>
                    </ng-template>
                </div>
            <ng-template #newPassword>
                <div class="container">
                    <div class="row">
                        <div style="width: 20%"></div>
                        <div class="column flex-box-start">
                            <form [formGroup]="resetPasswordForm" (ngSubmit)="passwordReset()">
                                <div class="password-input">
                                    <h3 class="password-heading">Invalid Code</h3>
                                    <div class="invalid-message">You reset link has expired, please request a new one</div>
                                </div>
                                    <div class="button-div">
                                        <button mat-button (click)="redirectToReset()" 
                                            class="reset-button">Get new link
                                        </button>
                                    </div>
                            <!-- <button (click)="generateRandomPassword(8)">Generate</button> -->
                        </form>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>  
</div>
