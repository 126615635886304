import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

interface SelectOption {
  name: string,
  value: string
}

@Component({
  selector: 'select-field',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})

export class SelectComponent implements OnInit {
  @Input() label: string;
  @Input() options: SelectOption[];
  @Input() selectedOption: SelectOption;

  @Output() valueChanged =  new EventEmitter<SelectOption>();

  filteredOptions: SelectOption[];

  ngOnInit() {
    this.filterOptions();
  }

  onChange(option: SelectOption) {
    this.selectedOption = option;
    this.filterOptions()
    this.valueChanged.emit(option);
    return false;
  }

  filterOptions() {
    if (this.selectedOption) {
      this.filteredOptions = this.options.filter((option) => option.name !== this.selectedOption.name);
      return;
    }

    if (this.options) {
      this.selectedOption = this.options[0];
    }
  }
}
