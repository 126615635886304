import { Component, OnInit, Input } from '@angular/core';
import { TableDataMap, TableColumn } from 'app/shared/table/table.interfaces';

@Component({
  selector: 'app-driver-report-table',
  templateUrl: './driver-report-table.component.html',
  styleUrls: ['./driver-report-table.component.scss']
})
export class DriverReportTableComponent implements OnInit {

  @Input() driverReasonReport;
  driverFailureData;

  driverFailureColumns: TableColumn[] = [
    { name: 'driver_name', displayName: 'Driver', sortable: true },
    { name: 'business_closed', displayName: 'Business Closed', shouldCalculate: true, sortable: true },
    { name: 'contact_not_available', displayName: 'Contact Not Available', shouldCalculate: true, sortable: true },
    { name: 'incorrect_address', displayName: 'Incorrect Address', shouldCalculate: true, sortable: true },
    { name: 'not_enough_time', displayName: 'Not Enough Time', shouldCalculate: true, sortable: true },
    { name: 'order_cancelled', displayName: 'Order Cancelled', shouldCalculate: true, sortable: true },
    { name: 'parcel_damanged', displayName: 'Parcel Damaged', shouldCalculate: true, sortable: true },
    { name: 'parcel_lost_or_stolen', displayName: 'Parcel Lost or Stolen', shouldCalculate: true, sortable: true },
    { name: 'parcel_transferred', displayName: 'Parcel Transfered', shouldCalculate: true, sortable: true },
    { name: 'qr_code_damaged', displayName: 'QR Code Damaged', shouldCalculate: true, sortable: true },
    { name: 'traffic_problem', displayName: 'Traffic Problem', shouldCalculate: true, sortable: true },
    { name: 'total', displayName: 'Total', shouldCalculate: true, sortable: true }

  ];

  driverFailureDataMap: TableDataMap = {
    cells: {
      driver_name: { column: '', map: 'driver_name' },
      business_closed: { column: '', map: 'business-closed' },
      contact_not_available: { column: '', map: 'contact-not-available' },
      incorrect_address: { column: '', map: 'incorrect-address' },
      not_enough_time: { column: '', map: 'not-enough-time' },
      order_cancelled: { column: '', map: 'order-cancelled' },
      parcel_damanged: { column: '', map: 'parcel-damanged' },
      parcel_lost_or_stolen: { column: '', map: 'parcel-lost-or-stolen' },
      parcel_transferred: { column: '', map: 'parcel-transferred' },
      qr_code_damaged: { column: '', map: 'qr-code-damanged' },
      traffic_problem: { column: '', map: 'traffic-problem' },
      total: { column: '', map: 'total' }
    }
  };

  constructor() { }

  parseData() {
    const final = []
    this.driverReasonReport.map(
      driver => {
        const row = {};
        row['driver_name'] = driver.name;
        row['total'] = 0;
        function totalIncrement(value) {
          return row['total'] + value;
        }
        driver.series.forEach(
          element => {
            row[element.name] = element.value;
            row['total'] = totalIncrement(element.value)
          });
        final.push(row);
      }
    )
    this.driverFailureData = final;
  }

  ngOnInit() {
    this.parseData();
  }

}
