import { Injectable } from '@angular/core';
import { IconTypes } from './shared/icon/icon.interfaces';
import { UiColors, UiThemes } from './interfaces/ui.interfaces';

@Injectable({
  providedIn: 'root'
})
export class StaticService {

  constructor() { }


  public staticData = [
    // ON DEMAND
    {
      packageType: 'on-demand',
      context: 'Delivery',
      theme: UiThemes.Light,
      price: 'Last Mile Delivery',
      title: 'PICUP FOR',
      highlight: 'On Demand',
      subTitle: 'OUR LOCAL ON-DEMAND DELIVERY SOLUTION FOR INDIVIDUALS AND BUSINESSES',
      description: 'Picup\'s packaged solutions for restaurants, meals kit, and pharmaceutical companies to make instant collection or next-day easy. Get in touch today to find out more!',
      overview: 'Ever needed something urgently delivered to a family member or friend in a flash? Or remember the last time in the office when your boss urgently needed that document sent to a client? Whether it’s you in your personal capacity or your business you can sign up instantly and start placing deliveries at the tap of an app or move of a mouse.',
      layout: 'corner-block',
      showTMSBlock: false,
      showInSelector: true,
      showEnterprisePricingTable: false,
      showHowTo: true,
      showTestimonial: true,
      homepageButtons: [
        {
          text: 'Register Now',
          icon: IconTypes.Down,
          link: 'on-demand'
        }
      ],
      CTA: [
        {
          text: 'Register Now',
          icon: IconTypes.Details,
          link: '/dashboard/'
        }
      ],
      features: [
        {
          icon: IconTypes.Trip,
          iconColor: UiColors.Blue,
          text: '90min Delivery',
        },
        {
          icon: IconTypes.Truck,
          iconColor: UiColors.Blue,
          text: 'Verified Drivers',
        },
        {
          icon: IconTypes.Dollar,
          iconColor: UiColors.Blue,
          text: 'Transparent Pricing',
        },
        {
          icon: IconTypes.Gear,
          iconColor: UiColors.Blue,
          text: 'Cloud-based Platform',
        },
        {
          icon: IconTypes.Eye,
          iconColor: UiColors.Blue,
          text: 'Real-time GPS Tracking',
        },
        {
          icon: IconTypes.Warehouse,
          iconColor: UiColors.Blue,
          text: 'Instant Collection for Restaurants',
        },
      ],
      clientLogos: [
        {
          imgUrl: '/assets/img/static/on-demand/client-logos/two-tins.png',
          client: 'Two Tins',
          type: 'Mobile Services'
        },
        {
          imgUrl: '/assets/img/static/on-demand/client-logos/lionheart.png',
          client: 'Lion Heart',
          type: 'Promotions'
        },
        {
          imgUrl: '/assets/img/static/on-demand/client-logos/fit-food.png',
          client: 'Fit Food',
          type: 'Food & Beverage'
        },
        {
          imgUrl: '/assets/img/static/on-demand/client-logos/lebonbon.png',
          client: 'LeBonBon',
          type: 'Food & Beverage'
        },
        {
          imgUrl: '/assets/img/static/on-demand/client-logos/digital-express.png',
          client: 'Digital Express',
          type: 'Printing'
        }

      ],
      testimonial: {
        text: 'The Picup team and their Enterprise platform make planning and implementing our daily office deliveries a smooth process. The benefit we see from being able to monitor routes is a big plus and the assigned drivers are committed and energised to get deliveries done on time. For a customer-focused, professional courier service I would highly recommend Picup.',
        personName: 'Lesley Grandjean',
        personTitle: 'Director - The Fruit Cube',
      },
      packageFeatures: null,
      additionFeatureBlocks: [
        {
          imgUrl: '/assets/img/static/new-hero-home.png',
          centerImg: true,
          title: 'Stay in Control',
          subtitle: 'Financials',
          features: [
            {
              imgUrl: '/assets/img/static/illustrations/business-credit-wallet.svg',
              title: 'Pay via EFT or Credit Card'
            },
            {
              imgUrl: '/assets/img/static/illustrations/laptop-platform.svg',
              title: 'Preload credits'
            },
            {
              imgUrl: '/assets/img/static/illustrations/business-invite-team.svg',
              title: 'Manage users'
            },
            {
              imgUrl: '/assets/img/static/illustrations/dashboard-money.svg',
              title: 'Upfront Billing'
            }
          ]
        },
        {
          imgUrl: '/assets/img/static/on-demand/hero1.png',
          centerImg: true,
          title: 'Reach Your Customers',
          subtitle: 'Mobile App',
          features: [
            {
              imgUrl: '/assets/img/static/illustrations/dashboard-map.svg',
              title: 'Cape Town, JHB & KZN'
            },
            {
              imgUrl: '/assets/img/static/illustrations/platform.svg',
              title: 'Download Android'
            },
            {
              imgUrl: '/assets/img/static/illustrations/status-collection-in-progress.svg',
              title: 'Schedule deliveries'
            },
            {
              imgUrl: '/assets/img/static/illustrations/multiple-drop.svg',
              title: 'Multiple drop feature'
            },
          ]
        }

      ],
    },

    // BASIC
    {
      packageType: 'basic',
      context: 'Aggregation',
      theme: UiThemes.Blue,
      price: 'Integrated Solutions',
      title: 'Picup',
      highlight: 'E-Commerce',
      subTitle: 'Manage Local and National Online Delivery Streams In Single  Platform',
      description: 'Needing goods frequently delivered locally and nationally for your business, manage all your deliveries from one account. Available on mobile or desktop, register to get started!',
      overview: 'The Business Lite package helps get your business off the ground with one easy to manage platform for on-demand, local and national delivery. Takes the hassle out of managing your deliveries today and register for a business basic account.',
      layout: 'corner-block',
      imgUrl: '/assets/img/packages/basic.png',
      showTMSBlock: false,
      showInSelector: true,
      showHowTo: false,
      showEnterprisePricingTable: false,
      showTestimonial: false,
      homepageButtons: [
        {
          text: 'Find Out More',
          icon: IconTypes.Down,
          link: 'basic'
        }
      ],
      CTA: [
        {
          text: 'Sign Up Now',
          icon: IconTypes.Details,
          link: '/register'
        },
      ],
      features: [
        {
          icon: IconTypes.Trip,
          text: 'On Demand',
          iconColor: UiColors.Grey9
        },
        {
          icon: IconTypes.Map,
          text: 'National Deliveries',
          iconColor: UiColors.Grey9
        },
        {
          icon: IconTypes.Gear,
          text: 'API Integration',
          iconColor: UiColors.Grey9
        },
        {
          icon: IconTypes.Bucket,
          text: 'Intelligent Order Grouping',
          iconColor: UiColors.Grey9
        },
        {
          icon: IconTypes.Parcel,
          text: 'Automated Waybills',
          iconColor: UiColors.Grey9
        },
        {
          icon: IconTypes.Dollar,
          text: 'Realtime Billing',
          iconColor: UiColors.Grey9
        }
      ],
      clientLogos: [
        {
          imgUrl: '/assets/img/static/basic/client-logos/the-joinery.png',
          client: 'The Joinery',
          type: 'Bespoke Product Design'
        },
        {
          imgUrl: '/assets/img/static/basic/client-logos/hamper-world.png',
          client: 'Hamper World',
          type: 'Promotions'
        },
        {
          imgUrl: '/assets/img/static/basic/client-logos/kid-zone.png',
          client: 'Kid Zone',
          type: 'Childrens Toys'
        },
        {
          imgUrl: '/assets/img/static/basic/client-logos/stor-age-01.png',
          client: 'Stor-Age',
          type: 'Self Storage'
        }
      ],
      packageFeatures: {
        title: 'Feel The Power At Your Fingertips',
        subtitle: 'FOR BUSINESSES ON THE MOVE',
        heroImgUrl: '/assets/img/packages/phone-laptop-phone.png',
        features: [
          {
            imgUrl: '/assets/img/static/illustrations/laptop-api.svg',
            title: 'Courier Aggregation'
          },
          {
            imgUrl: '/assets/img/static/illustrations/multiple-drop.svg',
            title: 'Picup Multiple Drop'
          },
          {
            imgUrl: '/assets/img/static/illustrations/business-credit-wallet.svg',
            title: 'Preload Credits'
          },
          {
            imgUrl: '/assets/img/static/illustrations/parcels-scan.svg',
            title: 'Pay via EFT or Credit Card'
          },
          {
            imgUrl: '/assets/img/static/illustrations/business-invite-team.svg',
            title: 'Manage Users'
          },
          {
            imgUrl: '/assets/img/static/illustrations/plugin.svg',
            title: 'Wordpress & Shopify Plugin'
          }
        ],
      },
      additionFeatureBlocks: null
    },

    // PRO
    {
      packageType: 'pro',
      context: 'Suppy Chain Software',
      theme: UiThemes.Dark,
      price: 'From R999',
      title: 'Picup ',
      highlight: 'Pro',
      subTitle: 'Comprehensive Supply Chain Management for Medium to Large Enterprises ',
      description: 'Are you a managing supply chain from your home, small or large Warehouse? Picup Pro gives you all the functionality to optimise workflow efficiencies in your warehouse, reduces costs, and improves your overall dispatch delivery process.',
      overview: 'Businesses looking for a single platform to manage all their deliveries, on-demand and national we’ve got you covered. Our monthly account-based option allows for easy reconciliation and invoicing to suit your business needs.',
      layout: 'corner-block',
      imgUrl: '/assets/img/packages/pro.png',
      showTMSBlock: true,
      showHowTo: false,
      showInSelector: true,
      showTestimonial: false,
      showEnterprisePricingTable: true,
      homepageButtons: [
        {
          text: 'Find Out More',
          icon: IconTypes.Down,
          link: 'pro'
        }
      ],
      CTA: [
        {
          text: 'Register Now',
          icon: IconTypes.Down,
          link: 'free'
        }
      ],
      features: [{
        icon: IconTypes.Parcel,
        text: 'Bulk deliveries',
        iconColor: UiColors.Blue,
        textColor: UiColors.White
      },
      {
        icon: IconTypes.Marker,
        text: 'Address Verification',
        iconColor: UiColors.Blue,
        textColor: UiColors.White

      },
      {
        icon: IconTypes.Trip,
        text: 'Route optimization',
        iconColor: UiColors.Blue,
        textColor: UiColors.White
      },
      {
        icon: IconTypes.Gear,
        text: 'API Integration & Support',
        iconColor: UiColors.Blue,
        textColor: UiColors.White
      },
      {
        icon: IconTypes.Details,
        text: 'Waybill Automation',
        iconColor: UiColors.Blue,
        textColor: UiColors.White
      },
      {
        icon: IconTypes.Warehouse,
        text: 'WMS Integration',
        iconColor: UiColors.Blue,
        textColor: UiColors.White
      },
      ],
      clientLogos: [

        {
          imgUrl: '/assets/img/static/pro/client-logos/ucook.png',
          client: 'Ucook',
          type: 'Meal Delivery'
        },
        {
          imgUrl: '/assets/img/static/pro/client-logos/shop-2-shop-01.png',
          client: 'Shop2Shop',
          type: 'Wholesale Distribution'
        },
        {
          imgUrl: '/assets/img/static/pro/client-logos/picknpay.png',
          client: 'Pick \'n Pay',
          type: 'Groceries'
        },
        {
          imgUrl: '/assets/img/static/pro/client-logos/fruit-cube.png',
          client: 'The Fruit Cube',
          type: 'Meal Delivery'
        }
      ],
      packageFeatures: {
        title: 'A tool for every need',
        subtitle: 'Feature Highlights',
        heroImgUrl: '/assets/img/packages/phone-laptop-phone.png',
        features: [
          {
            imgUrl: '/assets/img/static/illustrations/compare-prices.svg',
            title: 'Predictive analytics'
          },
          {
            imgUrl: '/assets/img/static/illustrations/network-chain.svg',
            title: 'Cross Docking'
          },
          {
            imgUrl: '/assets/img/static/illustrations/multiple-drop.svg',
            title: 'Dynamic Routing'
          },
          {
            imgUrl: '/assets/img/static/illustrations/plugin.svg',
            title: 'Verbose API Integration'
          },
          {
            imgUrl: '/assets/img/static/illustrations/customer-support.svg',
            title: 'Custom Email Alerts'
          },
          {
            imgUrl: '/assets/img/static/illustrations/phone-notifications.svg',
            title: 'WhatsApp for Business'
          },
          {
            imgUrl: '/assets/img/static/illustrations/dashboard-map.svg',
            title: 'Last Mile DCS (Dispatch Control System)'
          },
          {
            imgUrl: '/assets/img/static/illustrations/realtime-tracking.svg',
            title: 'Parcel Level Tracking'
          },
          {
            imgUrl: '/assets/img/static/illustrations/globe.svg',
            title: 'Intelligent Geofencing Allocations'
          },
          {
            imgUrl: '/assets/img/static/illustrations/parcels-stack.svg',
            title: 'Warehouse Packing Assistance'
          }
        ],
      },
      additionFeatureBlocks: [
        {
          imgUrl: '/assets/img/static/pro/dcs-feature.png',
          title: 'Preparation is Key',
          subtitle: 'Dispatch Control System',
          features: [],
          description: '<p>Solving the last mile delivery problem is no small task - By leaning on a set of incredibly complex algorithms, the Picup TMS is able to create delivery sequences that are both cost and service centric.</p><p>Taking into account historic delivery data, the system predicts traffic conditions, residential/commercial zoning, vehicle type and more to allocate time windows to delivery locations. As the system is used, the Artificial Intelligence modules are molded to your individual delivery footprint and will increase in accuracy over time.</p><p>Backed by a patented costing algorithm (PARCS), the system calculates route costs well ahead of dispatch, leaving you in control to adjust or edit dispatches.</p><p>Real time visibility on delivery results helps customer service teams engage with clients to ensure that issues are remedied before driver debrief.</p>'
        },
        {
          imgUrl: '/assets/img/static/pro/third-party-feature.png',
          title: 'Connection is Power',
          subtitle: 'Third Party Courier Aggregation',
          features: [],
          description: '<p>Playing to your strengths means supplementing your fulfillment options with</p> <p>third parties where service reach and pricing can be regulated.</p> <p>Picup TMS uses its ICA (Intelligent Courier Aggregation) module to assign certain parcels to a list of pre-approved aggregated third parties when price can be reduced. Overall, means an increased service area with a reduced cost per delivery.</p> <p>Software Integrations and waybill creation is maintained by the Picup development team and ensures that changes are made ahead of release.</p> <p>Because all orders are kept within the system, status updates, feedback and reporting functionality is maintained across all channels, ensuring end to end visibility regardle the fulfillment partner.</p> </div> </div> </div> </div>'
        }
      ],
    },

    // TMS
    {
      packageType: 'tms',
      theme: UiThemes.Dark,
      context: 'Software',
      price: 'From R499',
      title: 'Picup TMS',
      highlight: 'Fleet Management Software',
      description: 'Picup’s TMS platform is a first in the world for business managing their driver fleet. Integrated vehicle telematics, with the picup mobile delivery applciation, gives you full control of your vehicles every minute of the day.',
      overview: 'Businesses looking for a single platform to manage all their deliveries, on-demand and national we’ve got you covered. Our monthly account-based option allows for easy reconciliation and invoicing to suit your business needs.',
      layout: 'split-block',
      imgUrl: '/assets/img/packages/tms.png',
      showTMSBlock: false,
      showHowTo: false,
      showInSelector: false,
      showTestimonial: false,
      showEnterprisePricingTable: false,
      homepageButtons: [
        {
          text: 'Find Out More',
          icon: IconTypes.Down,
          link: 'pro'
        }
      ],
      CTA: [
        {
          text: 'Register Now',
          icon: IconTypes.Down,
          link: 'free'
        }
      ],
      features: [
        {
          icon: IconTypes.History,
          text: 'Driver App',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Packing Algorithm',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Driver Payment Module',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Telematics Support',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Daily Time Controls',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Custom KPI',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        }
      ],
      clientLogos: [

      ],
      testimonial: {
        text: 'Picup really allowed my business take the next step in getting my products to my customers quickly and seamlessly. I LOVE IT.',
        personName: 'RAYMOND FLATLY',
        personTitle: 'CEO - Dear Rae',
        personImgUrl: '/assets/img/packages/client-profile.png',
        companyLogo: '/assets/img/packages/client-logo.png'
      },
      packageFeatures: {
        title: 'Feel The Power At Your Fingertips',
        subtitle: 'FOR BUSINESSES ON THE MOVE',
        heroImgUrl: '/assets/img/packages/phone-laptop-phone.png',
        features: [
          {
            imgUrl: 'assets/img/process/laptop.svg',
            title: 'API Integration'
          },
          {
            imgUrl: 'assets/img/process/laptop.svg',
            title: 'Route Optimisation'
          },
          {
            imgUrl: 'assets/img/process/laptop.svg',
            title: 'Electronic POD'
          }
        ],
      },
      additionFeatureBlocks: [
        {

          imgUrl: '/assets/img/packages/half-screen.png',
          title: 'Preparation',
          subtitle: 'Planning',
          features: [
            {
              imgUrl: '/assets/img/process/laptop.svg',
              title: 'Example Feature'
            },
            {
              imgUrl: '/assets/img/process/business-credit-wallet.svg',
              title: 'Another Feature'
            }
          ]
        },
        {
          imgUrl: '/assets/img/packages/pro.png',
          title: 'Tracking',
          subtitle: 'Visibility',
          features: [
            {
              imgUrl: '/assets/img/process/laptop.svg',
              title: 'Example Feature'
            },
            {
              imgUrl: '/assets/img/process/business-credit-wallet.svg',
              title: 'Another Feature'
            }
          ]
        }
      ]
    },
    // Africa
    {
      packageType: 'africa',
      theme: UiThemes.Dark,
      context: 'Software',
      price: 'From R499',
      title: 'Sokoni',
      highlight: 'A connected Africa',
      description: 'Picup’s TMS platform is a first in the world for business managing their driver fleet. Integrated vehicle telematics, with the picup mobile delivery applciation, gives you full control of your vehicles every minute of the day.',
      overview: 'Businesses looking for a single platform to manage all their deliveries, on-demand and national we’ve got you covered. Our monthly account-based option allows for easy reconciliation and invoicing to suit your business needs.',
      layout: 'split-block',
      imgUrl: '/assets/img/packages/africa.png',
      showTMSBlock: false,
      showHowTo: false,
      showInSelector: false,
      showTestimonial: false,
      showEnterprisePricingTable: false,
      homepageButtons: [
        {
          text: 'Find Out More',
          icon: IconTypes.Down,
          link: 'pro'
        }
      ],
      CTA: [
        {
          text: 'Register Now',
          icon: IconTypes.Down,
          link: 'free'
        }
      ],
      features: [
        {
          icon: IconTypes.History,
          text: 'Driver App',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Packing Algorithm',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Driver Payment Module',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Telematics Support',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Daily Time Controls',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        },
        {
          icon: IconTypes.History,
          text: 'Custom KPI',
          iconColor: UiColors.Blue,
          textColor: UiColors.White
        }
      ],
      clientLogos: [
        {
          imgUrl: '/assets/img/packages/client-logo.png',
          client: 'Bidvest Waltons',
          type: 'Office Supplies'
        },
        {
          imgUrl: '/assets/img/packages/client-logo-2.png',
          client: 'Dear Rae',
          type: 'Jewellery'
        }
      ],
      testimonial: {
        text: 'Picup really allowed my business take the next step in getting my products to my customers quickly and seamlessly. I LOVE IT.',
        personName: 'RAYMOND FLATLY',
        personTitle: 'CEO - Dear Rae',
        personImgUrl: '/assets/img/packages/client-profile.png',
        companyLogo: '/assets/img/packages/client-logo.png'
      },
      packageFeatures: {
        title: 'Feel The Power At Your Fingertips',
        subtitle: 'FOR BUSINESSES ON THE MOVE',
        heroImgUrl: '/assets/img/packages/phone-laptop-phone.png',
        features: [
          {
            imgUrl: 'assets/img/process/laptop.svg',
            title: 'API Integration'
          },
          {
            imgUrl: 'assets/img/process/laptop.svg',
            title: 'Route Optimisation'
          },
          {
            imgUrl: 'assets/img/process/laptop.svg',
            title: 'Electronic POD'
          }
        ],
      },
      additionFeatureBlocks: [
        {

          imgUrl: '/assets/img/packages/half-screen.png',
          title: 'Preparation',
          subtitle: 'Planning',
          features: [
            {
              imgUrl: '/assets/img/process/laptop.svg',
              title: 'Example Feature'
            },
            {
              imgUrl: '/assets/img/process/business-credit-wallet.svg',
              title: 'Another Feature'
            }
          ]
        },
        {
          imgUrl: '/assets/img/packages/pro.png',
          title: 'Tracking',
          subtitle: 'Visibility',
          features: [
            {
              imgUrl: '/assets/img/process/laptop.svg',
              title: 'Example Feature'
            },
            {
              imgUrl: '/assets/img/process/business-credit-wallet.svg',
              title: 'Another Feature'
            }
          ]
        }
      ]
    }
  ]

  public tmsBlock = {
    title: 'Driver Management Suite',
    subtitle: 'Your Drivers, our Software',
    heroImgUrl: '/assets/img/packages/tms.png',
    features: [
      {
        imgUrl: '/assets/img/static/illustrations/parcels-stack.svg',
        title: 'Mobile Driver App'
      },
      {
        imgUrl: '/assets/img/static/illustrations/parcels-stack.svg',
        title: 'Smart Vehicle packing Algorithm'
      },
      {
        imgUrl: '/assets/img/static/illustrations/business-credit-wallet.svg',
        title: 'PARCS Driver Payment Module'
      },
      {
        imgUrl: '/assets/img/static/illustrations/laptop-api.svg',
        title: 'Telematics Support'
      },
      {
        imgUrl: '/assets/img/static/illustrations/delivery-fast.svg',
        title: 'Daily Time Controls '
      },
      {
        imgUrl: '/assets/img/static/illustrations/business-sign-up.svg',
        title: 'Driver Behavior and custom KPI'
      }
    ],
  }




}
