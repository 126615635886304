import { Component, Input, OnChanges } from '@angular/core';
import { UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { TableActionTaken, TableCell, TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';
import { LastMileService } from '../last-mile.service';

@Component({
  selector: 'app-last-mile-failed-parcels',
  templateUrl: './last-mile-failed-parcels.component.html',
  styleUrls: ['./last-mile-failed-parcels.component.scss'],
})
export class LastMileFailedParcelsComponent implements OnChanges {
  @Input() failedParcels;
  @Input() lastMileId;
  mappedTableData;
  iconTypes = IconTypes;
  uiColours = UiColors

  constructor(private lastMileService: LastMileService) {}

  parcelTableColumns: TableColumn[] = [
    { name: 'ParcelWaybill', displayName: 'Waybill', hidden: true },
    { name: 'ParcelReference', displayName: 'Parcel Reference' },
    { name: 'FailedReason', displayName: 'Failure Reason' },
    { name: 'LastMileParcelState', displayName: 'Parcel State' },
    { name: 'CollectionContactName', displayName: 'CollectionContactName' },
    { name: 'CollectionAddress', displayName: 'CollectionAddress' },
    { name: 'CollectionAttempts', displayName: 'CollectionAttempts' },
    { name: 'DeliveryAddress', displayName: 'Delivery Address' },
    { name: 'DeliveryContactName', displayName: 'Delivery Name' },
    { name: 'DeliveryAttempts', displayName: 'DeliveryAttempts' },
  ];

  parcelTableDataMap: TableDataMap = {
    cells: {
      ParcelWaybill: { column: 'ParcelWaybill', map: 'ParcelWaybill' },
      LastMileParcelState: { column: 'LastMileParcelState', map: 'LastMileParcelState' },
      BusinessReference: { column: 'BusinessReference', map: 'BusinessReference' },
      ParcelReference: { column: 'ParcelReference', map: 'ParcelReference' },
      FailedReason: { column: 'FailedReason', map: 'FailedReason' },
      CollectionAttempts: { column: 'CollectionAttempts', map: 'CollectionAttempts' },
      CollectionContactName: { column: 'CollectionContactName', map: 'CollectionContactName' },
      CollectionAddress: { column: 'CollectionAddress', map: 'CollectionAddress' },
      DeliveryAttempts: { column: 'DeliveryAttempts', map: 'DeliveryAttempts' },
      DeliveryContactName: { column: 'DeliveryContactName', map: 'DeliveryContactName' },
      DeliveryAddress: { column: 'DeliveryAddress', map: 'DeliveryAddress' },
    },
  };

  parcelBulkActions = [
    { event: 'reattempt', title: 'Re-Attempt', icon: IconTypes.Trip, icon_color: UiColors.Orange },
    { event: 'returnOrFail', title: 'Return/FailCollection', icon: IconTypes.History, icon_color: UiColors.Red },
  ];

  handleTableActions($event: TableActionTaken): void {
    const parcel_array = [];
    const actionData = {
      Redeliver: [],
      Return: [],
      Recollect: [],
      Fail: [],
    };
    $event.rows.forEach((element) => {
      parcel_array.push({
        ParcelWaybill: element.cells.find((cell: TableCell) => cell.column === 'ParcelWaybill').value,
        CurrentState: element.cells.find((cell: TableCell) => cell.column === 'LastMileParcelState').value,
      });
    });
    if ($event.action.event === 'reattempt') {
      parcel_array.forEach((parcel) => {
        switch (parcel.CurrentState) {
          case 'FailedCollection':
            actionData.Recollect.push(parcel.ParcelWaybill);
            break;
          case 'FailedDelivery':
            actionData.Redeliver.push(parcel.ParcelWaybill);
            break;
        }
      });
    }
    if ($event.action.event === 'returnOrFail') {
      parcel_array.forEach((parcel) => {
        switch (parcel.CurrentState) {
          case 'FailedCollection':
            actionData.Fail.push(parcel.ParcelWaybill);
            break;
          case 'FailedDelivery':
            actionData.Return.push(parcel.ParcelWaybill);
            break;
        }
      });
    }
    this.lastMileService.actionFailedParcels(this.lastMileId, actionData);
  }

  ngOnChanges() {
    if (this.failedParcels) {
      const mappedTableData = [];
      this.failedParcels.forEach((parcel) => {
        mappedTableData.push({
          FailedReason: parcel.FailedReason,
          ParcelWaybill: parcel.ParcelWaybill,
          BusinessReference: parcel.BusinessReference,
          ParcelReference: parcel.ParcelReference,
          LastMileParcelState: parcel.LastMileParcelState,
          CollectionContactName: parcel.OriginWarehouse
            ? parcel.OriginWarehouse.Contact?.Name
              ? parcel.OriginWarehouse.Contact.Name
              : parcel.OriginWarehouse.WarehouseName
            : parcel.Origin.Contact.Name,
          CollectionAddress: parcel.OriginWarehouse
            ? parcel.OriginWarehouse.FormattedAddress
            : parcel.Origin.FormattedAddress,
          CollectionAttempts: parcel.CollectionAttempts,
          DeliveryContactName: parcel.DestinationWarehouse
            ? parcel.DestinationWarehouse.WarehouseName
            : parcel.Destination.Contact.Name,
          DeliveryAddress: parcel.DestinationWarehouse
            ? parcel.DestinationWarehouse.FormattedAddress
            : parcel.Destination.FormattedAddress,
          DeliveryAttempts: parcel.DeliveryAttempts,
        });
      });
      this.mappedTableData = mappedTableData;
    }
  }
  ngOnInit(): void {}
}
