import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectorUser } from 'app/auth/auth.reducer';
import { MapMarkerColors, UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { MapTypes } from 'app/shared/map-view/map-view.interfaces';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { SimpleModalService } from 'ngx-simple-modal';
import { LastMileService } from '../last-mile.service';
import { ReconcileWarehouseModalComponent } from '../reconcile-route/reconcile-warehouse-modal.component';

@Component({
  selector: 'app-last-mile-fs-waypoint-route',
  templateUrl: './last-mile-fs-waypoint-route.component.html',
  styleUrls: ['./last-mile-fs-waypoint-route.component.scss'],
})
export class LastMileFsWaypointRouteComponent implements OnChanges {
  @Input() Parcels;
  @Input() WaypointVisits;
  @Input() lastMileId;
  @Input() RouteStartedAt;
  @Input() RouteEndedAt;
  @Input() RouteId;
  @Input() fleetAllocation;
  @Input() isHistoricRoute = false;
  @Input() lastMilePolyline;
  @Input() orderId: string;
  @Output() refreshDataEvent = new EventEmitter();
  reconciling;
  endingRoute;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public UiThemes = UiThemes;
  public JustifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  public mapTypes = MapTypes;
  parcelStateBreakdown;
  showMap;
  user;
  markers = [];
  driverTravelData;

  constructor(
    private simpleModalService: SimpleModalService,
    private lastMileService: LastMileService,
    private store: Store
  ) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
  }

  toggleMap(): void {
    this.showMap = !this.showMap;
    if (this.showMap) {
      this.constructMapData();
    }
  }

  constructMapData(): void {
    this.getDriverTravelData();

    this.markers = [];

    this.WaypointVisits.forEach((waypoint) => {
      const marker1 = {
        latitude: waypoint.Location.Latitude,
        longitude: waypoint.Location.Longitude,
        status: this.getStatus(waypoint.VisitState),
        label: waypoint.WaypointIndex.toString(),
      };
      this.markers.push(marker1);
    });
  }

  getStatus(visitState: string): MapMarkerColors {
    switch (visitState) {
      case 'None':
        return MapMarkerColors.Blue;
      case 'EnRoute':
        return MapMarkerColors.Yellow;

      case 'Visiting':
        return MapMarkerColors.Orange;

      case 'Finalized':
        return MapMarkerColors.Green;
    }
  }

  getDriverTravelData(): void {
    this.lastMileService.getTravelData(this.RouteId).then((res) => (this.driverTravelData = res));
  }

  endRoute(): void {
    this.endingRoute = true;
    this.lastMileService
      .endWaypointRoute(this.lastMileId)
      .then(() => {
        this.endingRoute = false;
        this.refreshDataEvent.emit();
      })
      .catch(() => (this.endingRoute = false));
  }

  reconcileRoute(): void {
    const modal_data = {
      Parcels: Object.values(this.Parcels),
      route_type: 'waypoint',
    };
    this.simpleModalService
      .addModal(ReconcileWarehouseModalComponent, modal_data)
      .subscribe((result: { parcel_waybill: string; reason: string; result: string }[]) => {
        if (!result) {
          this.reconciling = false;
          return;
        }
        this.lastMileService
          .reconcileParcels(result, this.lastMileId, this.orderId)
          .then(() => {
            this.reconciling = false;
            this.refreshDataEvent.emit();
          })
          .catch(() => {
            this.reconciling = false;
            this.refreshDataEvent.emit();
          });
      });
  }

  ngOnChanges(): void {
    this.parcelStateBreakdown = {
      collected: 0,
      delivered: 0,
      failed: 0,
      total: 0,
    };
    const Parcels = Object.values(this.Parcels);
    Parcels.forEach((parcel: any) => {
      this.parcelStateBreakdown.total++;
      if (parcel.LastMileParcelState === 'Collected') {
        this.parcelStateBreakdown.collected++;
      }
      if (parcel.LastMileParcelState === 'Delivered') {
        this.parcelStateBreakdown.delivered++;
      }
      if (parcel.LastMileParcelState === 'FailedDelivery') {
        this.parcelStateBreakdown.failed++;
      }
    });
  }
}
