export const environment = {
  merchantId: 'merchant-d827f668-d434-4ce5-b853-878f874ae746',
  appName: 'Picup integrator - AzureDev',
  envName: 'Azure-Dev',
  currency_symbol: 'R',
  appVersion: 2,
  googleApiKey: 'AIzaSyAZSiEx7YaRz_94wUEW9fHemnzwrXmiHH8',
  eventStoreStream: 'http://kl-finaldevtest-eventstore-vm-dvsuhgfeua8m5-0.eastus2.cloudapp.azure.com:2114/web/index.html#/streams/',
  peachUrl: 'https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=',
  peachRedirectUrl: 'http://localhost:4200/assets/pages/peach.html',
  changePackagePassword: 'thepassword',
  supportEmailAddress: 'customer.service@picup.co.za',
  supportPhoneNumber: '086 999 0393',
  autocompletePref: {
    types: ['establishment', 'geocode'],
    fields: ['address_components', 'types', 'name', 'geometry.location', 'place_id', 'formatted_address'],
  },
  billingModule: {
    baseUrl: 'https://picup-staging-billing.azurewebsites.net/v1',
    paystackPublicKey: 'pk_test_dda21e80b48328b3fa74a22616b0477f58f6d2e1',
    apiKey: '5E9151D0-58FB-4AC5-A5A6-4A2D2BA5A7BC',
  },
  courierIntegrationModule: {
    baseUrl: 'https://picupdev-courierintegrationmodule.azurewebsites.net',
    apiKey: '12345',
  },
  countryCodes: ['ZA', 'GH', 'EG', 'NA', 'MZ', 'DR'],
  // Azure storage account + the "Access Keys" located in the storage account
  azure: {
    storageAccount: 'picupdev',
    storageKey: 'x8ntji4+41YJR6RLhSC/XHqqcCYV16cEqrExaC+QWVnCYHEVxjzEYs5k64B1J0KMr7I2K4rPTcEgXQdh5iTmCw==',
  },
  production: false,
  firebase: {
    apiKey: 'AIzaSyCFu531OAie0edjcT7U9A0HeegvsLVYL4I',
    authDomain: 'picup-azure-dev.firebaseapp.com',
    databaseURL: 'https://picup-azure-dev.firebaseio.com',
    projectId: 'picup-azure-dev',
    storageBucket: 'picup-azure-dev.appspot.com',
    messagingSenderId: '476145183433',
  },
  defaultPagination: {
    PageSize: 25,
    PageNumber: 1,
  },
  planningModule: {
    baseUrl: 'https://picupplanningdevelop.azurewebsites.net',
  },
  integration: {
    rootUrl: 'https://picupdev-webapi.azurewebsites.net/v1/',
    firebaseFunction: 'https://us-central1-picup-azure-dev.cloudfunctions.net',
  },
  accessManagement: {
    baseUrl: 'https://karooooodev-accessmanagement.azurewebsites.net',
    resetPasswordKey: '01C66C6D-DB97-495E-87E8-765825045BE5',
  },
  reporting: {
    baseUrl: 'https://picupdevelopment-reporting-webapi.azurewebsites.net',
  },
  paymentDetails: {
    bankName: 'FNB',
    branchCode: '250655',
    accountName: 'Picup Technologies Pty (Ltd)',
    accountType: 'Business Cheque',
    accountNumber: '62695635072',
    popEmail: 'info@picup.co.za',
  },
  defaultPaginationSizes: [5, 10, 25, 50, 100, 9999],
  graphQL: {
    costing: {
      clientName: 'costing',
      url: 'https://karooooodev-costing-graphql.azurewebsites.net/graphql/'
    },
    financials: {
      clientName: 'financials',
      url: 'https://karooooodev-financials-graphql.azurewebsites.net/graphql/'
    },
    accessManagement: {
      clientName: 'accessManagement',
      url: 'https://karooooodev-accessmanagement-graphql.azurewebsites.net/graphql/'
    }
  },
  financials: {
    url: 'https://karooooodev-financials.azurewebsites.net'
  },
  freshWorks: {
    publicBot: '6e480e74-c0e5-4455-874a-53a13735dfae',
    clientBot: 'c7a209d6-464a-488a-9d0d-9444bb320f33',
    url: '//fw-cdn.com/11338766/4025167.js'
  },
  overrideLastMileForDevReasons: false,
};
