import { ServiceType } from 'app/dashboard/settings/warehouses/manage-couriers/manage-couriers.interfaces';

export interface AddressBookEntry {
  business_id?: string;
  warehouse_id?: string;
  user_id?: string;
  address_book_id?: number;
  description: string;
  name: string;
  phone: string;
  email: string;
  formatted_address: string;
  latitude: number;
  longitude: number;
  complex: string;
  country: string;
  unit_no: string;
  country_short_code: string;
  postal_code: string;
  suburb: string;
}

export enum PD_Courier_Order_Table_Actions {
  ViewTrip = 'viewTrip',
  CopyId = 'copyId',
  Reconcile = 'reconcile',
}

export interface Address {
  suburb: string;
  building_or_complex_name: string;
  unit_or_floor: string;
  postal_code: string;
  formatted_address: string;
  geocoded_address: string;
  latitude: number;
  longitude: number;
  is_modified: boolean;
  country: string;
  country_short_code: string;
  address_line_1: string;
  //  TODO: added for types
  client_address: string;
}

export interface Contact {
  customer_name?: string;
  customer_phone?: string;
  email_address?: string;
  special_instructions?: string;
  delivery_window_start?: string;
  delivery_window_end?: string;
  duration_at_stop_minutes?: number;
  includeParcelAmounts: boolean;
  paymentsTotal?: string;
}

export interface Shipment {
  save: boolean;
  consignment?: string;
  business_reference?: string;
  visit_type: string;
  contact_description?: string;
  address: Address;
  contact: Contact;
  parcels: Parcel[];
}

export interface Parcel {
  parcel_value?: number;
  size: string;
  tracking_number: string;
  parcel_reference: string;
}

export enum AvailableBucketsResultEnum {
  None = 'none',
  Create = 'create',
}

export enum VisitTypeEnum {
  Delivery = 'Delivery',
  Collection = 'Collection',
}

export enum NotificationTypeEnum {
  Error = 'error',
  Success = 'success',
}

export enum PackageTypeEnum {
  OnDemand = 'on-demand',
  Basic = 'basic',
  Pro = 'pro',
  TMS = 'tms',
  Africa = 'africa',
  Freight = 'freight',
}

export interface WarehouseCourierConfiguration {
  couriers: CourierConfiguration[];
}

export interface CourierConfiguration {
  courier_id: string;
  service_type_uids: string[];
}

export enum AcceptedDriverDocumentFileTypesEnum {
  JPG = 'jpg',
  PDF = 'pdf',
}

export enum DriverImagesEnum {
  ProfilePicture = 'profile_pic',
  VehicleImage = 'vehicle_image',
}

export interface DriverImagesPresent {
  id_document: boolean;
  vehicle_insurance: boolean;
  proof_of_address: boolean;
  vehicle_assessment: boolean;
  drivers_license: boolean;
  carrier_agreement: boolean;
  proof_of_banking: boolean;
}

export interface Order {
  ActivationDate?: firebase.default.firestore.Timestamp;
  BucketId: number;
  BusinessId: string;
  BusinessName: string;
  CourierName: string;
  CustomerReference: string;
  DriverNames: string[];
  FleetAllocation: string;
  FrontEndState: string;
  HangfireJobId: string;
  Id: string;
  IsManagedOrder: boolean;
  IsRoundTrip: boolean;
  IsScheduleLotteryJob: boolean;
  LastEventName: string;
  LastEventTitle: string;
  OrderState: string;
  OwnerLedgerId: string;
  Parcels: OrderParcel[];
  ReadModelError: any;
  RehydratedAt: any;
  RehydratedVersion: number;
  Routes: OrderRoutes;
  ScheduledDate: firebase.default.firestore.Timestamp;
  Timestamp: firebase.default.firestore.Timestamp;
  UseFixedSequencing: boolean;
  Version: number;
  WarehouseId: string;
  WarehouseName: string;
  Quote?: any;
  Vehicle: string;
  LotteryRestartCount: number;
  Reservation?: Reservation;
}

export interface Reservation{
  Username: string;
  Timestamp: firebase.default.firestore.Timestamp;
  UserId: string;
}

export interface OrderParcel {
  ActiveRouteId: string;
  AmountPaid: string;
  Barcode: string;
  BusinessId: string;
  BusinessName: string;
  BusinessReference: string;
  CancelledBy: string;
  CollectionAttempts: number;
  CollectionBarcodeOptions: string;
  Consignment: string;
  CourierOrderId: string;
  CourierParcelState: string;
  CourierReference: string;
  CustomerReference: string;
  DeliveryAttempts: number;
  DeliveryBarcodeOptions: string;
  Description: string;
  Destination: ParcelDestination;
  DestinationWarehouse: string;
  Dimensions: Dimensions;
  DriverName: string;
  DriverId: string;
  FailedReason: any;
  FinalizedAt: any;
  IsIgnored: boolean;
  IsRetry: boolean;
  LastMileId: string;
  LastMileParcelState: string;
  LatestDeliveryRouteId: string;
  LatestReturnedRouteId: string;
  OneTimePin: string;
  OrderId: string;
  OrderParcelState: string;
  OrderSource: string;
  Origin: any;
  OriginWarehouse: ParcelWarehouse;
  OriginalBarcode: string;
  OwnerLedgerId: string;
  OwnerName: string;
  ParcelReference: string;
  ParcelValue: number;
  ParcelWaybill: string;
  PaymentState: any;
  PaymentType: any;
  ReconciledBy: string;
  ReconciledReason: any;
  Size: any;
  SourceBucketId: number;
  SourceTripId: number;
}

export interface OrderRoutes {
  Waypoints: any[];
}

export interface ParcelDestination {
  AddressId: string;
  City: string;
  Complex: string;
  Contact: ParcelContact;
  Country: string;
  CountryCode: string;
  DeliveryDate: any;
  DurationAtStop: string;
  FormattedAddress: string;
  Latitude: number;
  Longitude: number;
  PostalCode: string;
  Priority: any;
  StreetOrFarm: any;
  StreetorFarmNo: any;
  Suburb: string;
  TimeWindowEnd: string;
  TimeWindowStart: string;
  UnitNo: number;
}

export interface ParcelContact {
  ClientAddress: string;
  Email: string;
  Instructions: string;
  Name: string;
  PhoneNumber: string;
}

export interface Dimensions {
  Height: number;
  Length: number;
  Size: string;
  Weight: number;
  Width: number;
}

export interface ParcelWarehouse {
  AddressId: string;
  City: string;
  CollectionDate: Date;
  Complex: string;
  Country: string;
  CountryCode: string;
  FormattedAddress: string;
  Latitude: number;
  Longitude: number;
  PostalCode: string;
  StreetOrFarm: any;
  StreetOrFarmNo: any;
  Suburb: string;
  UnitNo: string;
  WarehouseEmail: string;
  WarehouseId: string;
  WarehouseName: string;
  WarehousePhone: string;
}

export interface CourierOrder {
  BucketId: number;
  BusinessId: string;
  BusinessName: string;
  BusinessReference: string;
  CourierCode: string;
  CourierId: string;
  CourierName: string;
  CourierReference: string;
  CreateCourierOrderResponse: CreateCourierOrderResponse;
  CustomerReference: string;
  Id: string;
  IsCancelled: boolean;
  IsComplete: boolean;
  IsManagedCourierOrder: boolean;
  IsWaybillCreated: boolean;
  LastEventName: string;
  OrderId: string;
  OrderWaybillNumber: string;
  ParcelTracking: ParcelTracking[];
  Parcels: OrderParcel[];
  ReconcileReason: any;
  RehydratedAt: any;
  RehydratedVersion: number;
  ServiceTypeDescription: string;
  ServiceTypeUid: string;
  Timestamp: any;
  TrackingEventType: string;
  Version: number;
  WarehouseId: string;
  WarehouseName: string;
}

export interface ParcelTracking {
  CourierParcelTrackingNumber: string;
  CourierWaybillNumber: string;
  Error: any;
  ParcelState: number;
  ParcelWaybill: string;
}

export interface CreateCourierOrderResponse {
  Collection: CreateCourierOrderResponseCollection;
  CollectionResponse: CreateCourierOrderResponseCollectionResponse;
  Configuration: CreateCourierOrderResponseConfiguration;
  Error: any;
  Exception: any;
  ParcelTracking: ParcelTracking[];
}

export interface CreateCourierOrderResponseConfiguration {
  CourierCode: string;
  CourierId: string;
  CourierType: number;
  CourierCredentials: any;
  ServiceType: ServiceType;
}

export interface CreateCourierOrderResponseCollectionResponse {
  CollectionTimeUnixEpoch: any;
  CourierCode: string;
  CourierReference: string;
  CourierRequestJson: string;
  CourierResponseJson: string;
  ErrorCode: any;
  ErrorMessage: any;
  InternalError: boolean;
  MessageId: any;
  MilliSecondsToProcess: number;
  Success: boolean;
  Url: string;
  WaybillNumber: string;
}

export interface CreateCourierOrderResponseCollection {
  CourierCode: string;
  CourierId: string;
  CourierType: number;
  CourierCredentials: any;
  ServiceType: ServiceType;
}

export interface LastMile {
  ActiveRouteId: string;
  BusinessIds: string[];
  BusinessNames: string[];
  DriverId: string;
  DriverName: string;
  HasFailedCollection: boolean;
  HasFailedDelivery: boolean;
  HasPendingRecollection: boolean;
  HasPendingRedelivery: boolean;
  Id: string;
  LastEventName: string;
  Parcels: ParcelDictionary;
  RehydratedAt: firebase.default.firestore.Timestamp;
  RehydratedVersion: number;
  Role: string;
  RouteEndedAt: firebase.default.firestore.Timestamp;
  RouteStartedAt: firebase.default.firestore.Timestamp;
  Timestamp: firebase.default.firestore.Timestamp;
  UnservedVisits: any;
  VehicleId: string;
  Version: number;
  WarehouseIds: string[];
  WaypointVisits: WaypointVist[];
  RoundTripWarehouseName: string;
  RoundTripWarehouseId: string;
}

export enum LastMileParcelStateEnum {
  Delivered = 'Delivered',
  Collected = 'Collected',
  FailedDelivery = 'FailedDelivery',
  ExecutionFailed = 'ExecutionFailed',
  FailedCollection = 'FailedCollection',
  FailedReturn = 'FailedReturn',
  Cancelled = 'Cancelled',
  Returned = 'Returned',
  Assigned = 'Assigned',
  PendingRecollection = 'PendingRecollection',
  PendingRedelivery = 'PendingRedelivery',
  PendingAllocation = 'PendingAllocation',
  FinalCollectionAttemptFailed = 'FinalCollectionAttemptFailed',
  PendingReturn = 'PendingReturn',
  None = 'None',
}

export enum OrderParcelStateEnum {
  Collected = 'Collected',
  AtSortingFacility = 'AtSortingFacility',
  OutForDelivery = 'OutForDelivery',
  Delivered = 'Delivered',
  WaybillCreationFailed = 'WaybillCreationFailed',
  Cancelled = 'Cancelled',
  AllocationFailed = 'AllocationFailed',
  ExecutionFailed = 'ExecutionFailed',
  FailedCollection = 'FailedCollection',
  FinalCollectionAttemptFailed = 'FinalCollectionAttemptFailed',
  FailedReturn = 'FailedReturn',
  Returned = 'Returned',
  AllocatedToPicup = 'AllocatedToPicup',
  PendingAllocation = 'PendingAllocation',
  FailedDelivery = 'FailedDelivery',
  AllocatedToCourier = 'AllocatedToCourier',
  OutForCollection = 'OutForCollection',
  AtCourierCenter = 'AtCourierCenter',
  WaybillCreated = 'WaybillCreated',
  DriverAssigned = 'DriverAssigned',
  StagedAssignment = 'StagedAssignment',
  None = 'None',
}

export enum CourierOrderParcelStateEnum {
  Delivered = 'Delivered',
  CourierAccepted = 'CourierAccepted',
  WaybillCreationFailed = 'WaybillCreationFailed',
}

export interface ParcelDictionary {
  [key: string]: OrderParcel;
}

export interface WaypointVist {
  Address: string;
  Contacts: WaypointContact;
  Distance: number;
  EnRouteAt: firebase.default.firestore.Timestamp;
  FinalizedAt: firebase.default.firestore.Timestamp;
  Location: WaypointVisitLocation;
  PlannedArrival: firebase.default.firestore.Timestamp;
  PlannedFinish: firebase.default.firestore.Timestamp;
  VisitState: string;
  VisitType: string;
  VisitingAt: firebase.default.firestore.Timestamp;
  WaypointIndex: number;
}

export interface WaypointVisitLocation {
  Longitude: number;
  Latitude: number;
  ActingAs: string;
}

export interface WaypointContact {
  BusinessReference: string;
  ClientAddress: string;
  Collections: string[];
  ContactUid: string;
  Deliveries: string[];
  Email: string;
  Instructions: string;
  Name: string;
  OrderId: string;
  OrderPaymentId: string;
  OrderWaybill: string;
  Payment: any;
  Phone: string;
  RequiresConfirmPhoto: boolean;
  RequiresScan: boolean;
  Returns: string[];
}

export enum PaginateEnum {
  Forward = 'Forward',
  Backward = 'Backward',
  None = 'None',
}

export enum PostDispatchTableEnum {
  InProgress = 'InProgress',
  Alert = 'Alert',
  Pending = 'Pending',
  Lottery = 'Lottery',
  Scheduled = 'Scheduled',
}

export interface Paginate {
  Amount: number;
  Direction: PaginateEnum;
}

export interface PaginateEvent {
  event: Paginate;
  table: PostDispatchTableEnum;
}

export interface PaginationConfiguration {
  paginationDisplayAmounts: number[];
  defaultDisplayAmount: number;
  currentPageIndex: number;
  totalPages: number;
}

export enum CollapsibleTableTypeEnum {
  Order = 'Order',
  CourierOrder = 'CourierOrder',
  LastMile = 'LastMile',
}

export enum PostDispatchViewEnum {
  Active = 'active',
  Pending = 'pending',
  Order = 'order',
  Courier = 'courier',
  Tracking = 'tracking',
  Dispatch = 'dispatch'
}

export enum ModalActionsEnum {
  Confirm = 'Confirm',
  Cancel = 'Cancel',
  Clear = 'Clear',
  Close = 'Close',
  Schedule = 'Schedule',
  ActivateNow = 'Activate Now',
}

export interface CourierCode {
  courier_code: string;
  courier_type: string;
  courier_name: string;
}

export interface WebhookResponse<T> {
  value: T;
}
