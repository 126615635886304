import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { USE_AUTH_INTERCEPTOR, USE_OLD_ID_TOKEN_INTERCEPTOR } from './auth-token-context';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccessManagementService } from 'app/auth/access-management.service';
import { AuthService } from 'app/auth/auth.service';
import { JwtHelperService } from '../jwt-helper/jwt-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private accessManagementService: AccessManagementService, private jwtHelper: JwtHelperService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(USE_AUTH_INTERCEPTOR)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('acmToken')}`
        }
      });
    } else if (req.context.get(USE_OLD_ID_TOKEN_INTERCEPTOR)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`
        }
      });
    }

    return next.handle(req).pipe(catchError((error) => {
      if (error.status === 401) {
        const acmToken = localStorage.getItem('acmToken');
        return this.handle401Error(req, next, acmToken)
      }
      return throwError(error);
    }))
  }

  private hasTokenExpired(authToken): boolean {
    const decoded = this.jwtHelper.parseJwt(authToken);
    if(decoded){
      const date = decoded.exp;
      const currentDay = Math.floor(new Date().getTime()/1000.0)
      return (date - currentDay) <= 0;
    }
    return true;
  }

  private refresh(refreshToken: string, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshToken(refreshToken).pipe(
      take(1),
      switchMap((value) => {
        this.authService.userDetails();
        return next.handle(this.retryLastRequest(request, value.AccessToken));
      }),
      catchError((error) => {
        this.router.navigate(['/login']);
        return throwError(() => error);
      })
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, authToken: string) {
    if (authToken) {
      const refreshToken = localStorage.getItem('refreshToken');
      if(refreshToken){
        return this.refresh(refreshToken, req, next);
      }
    }

    return throwError(() => new Error('handle401Error - Unexpected state'));
  }

  private retryLastRequest(request: HttpRequest<any>, newToken: string): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${newToken}` },
    });
  }
}
