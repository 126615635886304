import { Component, OnInit, Input } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss'],
})
export class SolutionsComponent implements OnInit {
  UiThemes = UiThemes;
  IconTypes = IconTypes;

  @Input() imgSrc: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() linkText: string;
  @Input() alignment: string = 'left';

  constructor() {}

  ngOnInit(): void {}
}
