import { Component, OnInit, Input } from '@angular/core';
import { TableColumn, TableDataMap } from 'app/shared/table/table.interfaces';

@Component({
  selector: 'app-driver-score-table',
  templateUrl: './driver-score-table.component.html',
  styleUrls: ['./driver-score-table.component.scss']
})


export class DriverScoreTableComponent implements OnInit {

  @Input() driverScores;

  driverScoreColumns: TableColumn[] = [
    { name: 'driver_name', displayName: 'Driver', sortable: true },
    // { name: 'avg_actual_trip_time', displayName: 'Trip Time Actual', shouldCalculate: true, sortable: true },
    // { name: 'avg_planned_trip_time', displayName: 'Trip Time Planned', shouldCalculate: true, sortable: true },
    // { name: 'avgTrip_time_difference', displayName: 'Trip Time Difference', shouldCalculate: true, sortable: true },
    // { name: 'avg_on_time_collection', displayName: 'On Time Collection', shouldCalculate: true, sortable: true },
    { name: 'total_collections', displayName: 'Total Collections', shouldCalculate: true, sortable: true },
    { name: 'avg_collection_time_per_parcel', displayName: 'Collection Time Per Parcel', shouldCalculate: true, sortable: true },
    { name: 'avg_on_time_delivery', displayName: 'On Time Delivery', shouldCalculate: true, sortable: true },
    { name: 'total_deliveries', displayName: 'Total Deliveries', shouldCalculate: true, sortable: true },
    { name: 'avg_delivery_time_per_parcel', displayName: 'Delivery Time Per Parcel', shouldCalculate: true, sortable: true },
    { name: 'avg_actual_driving_time', displayName: 'Drive Time Actual', shouldCalculate: true, sortable: true },
    { name: 'avg_planned_driving_time', displayName: 'Drive Time Planned', shouldCalculate: true, sortable: true },
    { name: 'avg_driving_time_difference', displayName: 'Driving Time Difference', shouldCalculate: true, sortable: true },
    { name: 'total_actual_distance_kilometers', displayName: 'Distance Actual', shouldCalculate: true, sortable: true },
    { name: 'total_planned_distance_kilometers', displayName: 'Distance Planned', shouldCalculate: true, sortable: true },

  ];

  driverScoreDataMap: TableDataMap = {
    cells: {
      driver_name: { column: 'Driver', map: 'driver_name' },
      avg_actual_trip_time: { column: 'Trup', map: 'avg_actual_trip_time' },
      avg_planned_trip_time: { column: '', map: 'avg_planned_trip_time' },
      avgTrip_time_difference: { column: '', map: 'avgTrip_time_difference' },
      avg_on_time_collection: { column: '', map: 'avg_on_time_collection' },
      total_collections: { column: '', map: 'total_collections' },
      avg_collection_time_per_parcel: { column: '', map: 'avg_collection_time_per_parcel' },
      avg_on_time_delivery: { column: '', map: 'avg_on_time_delivery' },
      total_deliveries: { column: '', map: 'total_deliveries' },
      avg_delivery_time_per_parcel: { column: '', map: 'avg_delivery_time_per_parcel' },
      avg_actual_driving_time: { column: '', map: 'avg_actual_driving_time' },
      avg_planned_driving_time: { column: '', map: 'avg_planned_driving_time' },
      avg_driving_time_difference: { column: '', map: 'avg_driving_time_difference' },
      total_actual_distance_kilometers: { column: '', map: 'total_actual_distance_kilometers' },
      total_planned_distance_kilometers: { column: '', map: 'total_planned_distance_kilometers' },
    }
  };

  driverScoreData = [{
    driver_id: 'driver-08b3ef1d-e05d-42e2-8753-e872691cfe95',
    driver_name: 'Driver 2',
    avg_actual_trip_time: 52,
    avg_planned_trip_time: 45,
    avgTrip_time_difference: 7,
    avg_on_time_collection: 0,
    total_collections: 29,
    avg_collection_time_per_parcel: 1,  // at a waypoint
    avg_on_time_delivery: -16,
    total_deliveries: 3,
    avg_delivery_time_per_parcel: 2, // at a waypoint
    avg_actual_driving_time: 15,
    avg_planned_driving_time: 31,
    avg_driving_time_difference: -16
  }]

  constructor() { }



  ngOnInit() {
  }

}
