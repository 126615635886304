import { Component, OnDestroy, OnInit } from '@angular/core';
import { UiThemes, UiColors } from 'app/interfaces/ui.interfaces';
import { IconTypes } from 'app/shared/icon/icon.interfaces';
import { TableColumn, TableDataMap, TableAction, TableActionTaken } from 'app/shared/table/table.interfaces';
import { Store } from '@ngrx/store';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';
import { ModalSizes } from 'app/shared/modals/modal.interfaces';
import { GridJustifyItems } from 'app/shared/grid-container/grid-container.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import { BucketService } from '../bucket.service';
import { ActivatedRoute } from '@angular/router';
import { ReceiveStagedOrdersComponent } from '../receive-staged-orders/receive-staged-orders.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmModalComponent } from 'app/shared/modals/confirm-modal.component';
import { environment } from 'environments/environment';
import { ModifyBucketOrderComponent } from '../modify-bucket-order/modify-bucket-order.component';
import { ManageBucketService } from '../manage-bucket.service';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { selectorActingAs, selectorWarehouses } from 'app/auth/auth.reducer';
import { CreateBucketModalComponent } from 'app/shared/buckets/create-bucket-modal/create-bucket-modal.component';
import { Warehouse } from 'app/interfaces/auth.interfaces';
import { StagedOrder, StagedOrders, StagedOrdersActionEnum, StagedOrderSourceEnum } from './staged-orders.interface';
import { NotificationTypeEnum } from 'app/shared/shared.interfaces';
import { ReceivedStagedOrdersResultEnum } from '../receive-staged-orders/receive-staged-orders.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-staged-orders',
  templateUrl: './staged-orders.component.html',
  styleUrls: ['./staged-orders.component.scss'],
})
export class StagedOrdersComponent implements OnInit, OnDestroy {
  public UiThemes = UiThemes;
  public ButtonTypes = ButtonTypes;
  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public ModalSizes = ModalSizes;
  public GridJustifyItems = GridJustifyItems;
  public justifyContent = JustifyContent;

  business_id: string;
  stagedOrders: StagedOrders;
  returnedOrders: StagedOrder[] = [];
  failedCollectionOrders: StagedOrder[] = [];
  noSourceOrders: StagedOrder[] = [];
  warehouseId: string;
  warehouses: Warehouse[];
  environment = environment;
  loadingOrders: boolean;
  displaySections: string[] = [
    StagedOrderSourceEnum.FinalCollectionAttemptFailed,
    StagedOrderSourceEnum.Returned,
    StagedOrderSourceEnum.New,
  ];
  StagedOrderSourceEnum = StagedOrderSourceEnum;
  viewTabs: string[] = [];

  routeSubscription: Subscription;
  stagedOrdersForWarehouseSubscription: Subscription;

  public stagedOrdersTableColumns: TableColumn[] = [
    { name: 'date', displayName: 'Date Created', sortable: true },
    { name: 'business_reference', displayName: 'Reference', sortable: true },
    { name: 'waybill_number', displayName: 'Waybill Number', sortable: true },
    { name: 'customer_name', displayName: 'Customer Name', sortable: true },
    { name: 'customer_phone', displayName: 'Customer Phone', sortable: true },
    { name: 'address', displayName: 'Address', sortable: true },
    { name: 'leg_index', displayName: 'Attempts', sortable: true },
    { name: 'at_warehouse', displayName: 'At Warehouse', sortable: true },
    { name: 'parcel_count', displayName: 'Parcels', sortable: true },
    { name: 'failure_reason', displayName: 'Failure Reason', sortable: true },
    { name: 'bucket_order_id', displayName: 'Bucket Order Id', hidden: true },
  ];

  public stagedOrdersTableDataMap: TableDataMap = {
    cells: {
      date: { column: 'Date Created', map: 'order_date_created', displayPipeArgs: ['date'] },
      business_reference: { column: 'Reference', map: 'business_reference' },
      waybill_number: { column: 'Waybill Number', map: 'waybill_number' },
      customer_name: { column: 'Customer Name', map: ['customer_name'] },
      customer_phone: { column: 'Customer Phone', map: ['customer_phone'], displayPipeArgs: ['none'] },
      address: { column: 'Address', map: ['customer_address'] },
      at_warehouse: { column: 'At Warehouse', map: 'at_warehouse', displayPipeArgs: ['none'] },
      leg_index: { column: 'Attempts', map: 'leg_index', displayPipeArgs: ['none'] },
      parcel_count: { column: 'Parcels', map: 'parcel_count', displayPipeArgs: ['none'] },
      bucket_order_id: { column: 'Bucket Order Id', map: 'bucket_order_id', displayPipeArgs: ['none'] },
      failure_reason: { column: 'failure_reason', map: 'failure_reason' },
    },
  };

  public tablePrimaryActions: TableAction[] = [
    {
      event: StagedOrdersActionEnum.ReceiveOrders,
      title: 'Process Order',
      icon: IconTypes.Bucket,
      icon_color: UiColors.Grey8,
    },
    {
      event: StagedOrdersActionEnum.EditOrder,
      title: 'Edit Order',
      icon: IconTypes.Edit,
      icon_color: UiColors.Grey8,
      hidden: environment.appVersion === 1,
    },
    {
      event: StagedOrdersActionEnum.DeleteOrders,
      title: 'Remove Order',
      icon: IconTypes.Cross,
      icon_color: UiColors.Red,
    },
  ];

  public tableBulkActions = [
    {
      event: StagedOrdersActionEnum.ReceiveOrders,
      title: 'Process Order',
      icon: IconTypes.Bucket,
      icon_color: UiColors.Green,
    },
    {
      event: StagedOrdersActionEnum.DeleteOrders,
      title: 'Remove Order',
      icon: IconTypes.Cross,
      icon_color: UiColors.Red,
    },
  ];

  constructor(
    public store: Store,
    private bucketService: BucketService,
    private route: ActivatedRoute,
    public simpleModalService: SimpleModalService,
    private manageBucketService: ManageBucketService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.viewTabs = this.setViewTabs();
    this.routeSubscription = this.route.params.subscribe((params) => (this.warehouseId = params['id']));
    this.store.select(selectorActingAs).subscribe((next) => (this.business_id = next.id));
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.getStagedOrdersForWarehouse().then(() => {
      this.setStagedOrdersForWarehouse();
    });
  }

  handleTabNavigation($event: string): void {
    const index = this.displaySections.indexOf($event.replace(/ /g, ''));
    if (index < 0) {
      this.displaySections.push($event.replace(/ /g, ''));
    } else {
      this.displaySections.splice(index, 1);
    }
  }

  isActive(tab: string): boolean {
    return this.displaySections.includes(tab.replace(/ /g, ''));
  }

  setViewTabs(): string[] {
    return Object.values(StagedOrderSourceEnum).map((data: string) => {
      return data.replace(/([A-Z])/g, ' $1').trim();
    });
  }

  setStagedOrdersForWarehouse(): void {
    if (this.warehouseId === 'null') {
      this.loadingOrders = false;
      return;
    }
    this.stagedOrdersForWarehouseSubscription = this.bucketService.stagedOrdersForWarehouse.subscribe((orders) => {
      if (orders) {
        this.stagedOrders = orders[0];
        this.failedCollectionOrders = [];
        this.returnedOrders = [];
        this.noSourceOrders = [];

        this.stagedOrders?.orders?.forEach((order) => {
          switch (order.staged_order_source) {
            case StagedOrderSourceEnum.FinalCollectionAttemptFailed: {
              this.failedCollectionOrders.push(order);
              break;
            }
            case StagedOrderSourceEnum.Returned: {
              this.returnedOrders.push(order);
              break;
            }
            default: {
              this.noSourceOrders.push(order);
              break;
            }
          }
        });
        this.loadingOrders = false;
      }
    });
  }

  getStagedOrdersForWarehouse(): Promise<void> {
    this.loadingOrders = true;
    return this.bucketService.getStagedOrdersForWarehouse(this.warehouseId);
  }

  ngOnDestroy(): void {
    this.stagedOrdersForWarehouseSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  respondToTableActions($event: TableActionTaken): void {
    const action: string = $event.action.event;
    const bucket_order_ids = [];
    $event.rows.forEach((element) => {
      bucket_order_ids.push(element.cells.find((cell) => cell.column === 'bucket_order_id').value);
    });
    switch (action) {
      case StagedOrdersActionEnum.ReceiveOrders:
        this.receiveOrders(bucket_order_ids);
        break;
      case StagedOrdersActionEnum.DeleteOrders:
        this.deleteOrders(bucket_order_ids);
        break;
      case StagedOrdersActionEnum.EditOrder:
        this.editOrder(bucket_order_ids[0]);
    }
  }

  receiveOrders(bucket_order_ids: number[]): void {
    const modalData = {
      bucketOrderIds: bucket_order_ids,
      warehouseId: this.warehouseId,
    };
    this.simpleModalService.addModal(ReceiveStagedOrdersComponent, modalData).subscribe((result) => {
      if (!result) {
        return;
      }
      if (result === ReceivedStagedOrdersResultEnum.NewBucket) {
        const modal_data = {
          warehouses: this.warehouses,
          warehouse_choice: false,
          warehouseId: this.warehouseId,
        };
        this.simpleModalService.addModal(CreateBucketModalComponent, modal_data).subscribe((create_result) => {
          if (!create_result) {
            return;
          }
          this.bucketService
            .receiveStagedOrders(create_result, bucket_order_ids)
            .then(() => this.getStagedOrdersForWarehouse());
        });
      } else {
        this.bucketService
          .receiveStagedOrders(result.bucket_details, result.order_ids)
          .then(() => this.getStagedOrdersForWarehouse());
      }
    });
  }

  editOrder(bucket_order_id: number): void {
    const order = this.stagedOrders.orders.find((order_row) => order_row.bucket_order_id === bucket_order_id);
    this.respondToEditOrder(order.order_id);
  }

  deleteOrders(bucket_order_ids: number[]): void {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Remove Staged Orders',
        message: 'Are you sure you want to proceed? There is no way to undo this action.',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        this.bucketService.removeStagedOrders(bucket_order_ids).then(() => {
          this.getStagedOrdersForWarehouse();
        });
      });
  }

  respondToEditOrder(order_id: number): void {
    const data = {
      order_id: order_id,
      warehouse_id: this.warehouseId,
      business_id: this.business_id,
    };
    this.simpleModalService.addModal(ModifyBucketOrderComponent, data).subscribe((result) => {
      if (result) {
        this.manageBucketService
          .updateOrder(result)
          .then((response) => {
            this.getStagedOrdersForWarehouse();
            if (result.print) {
              this.manageBucketService.printWaybill([response.validations[0].waybill_number]);
            }
          })
          .catch((error) => {
            this.notificationsService.publish({
              type: NotificationTypeEnum.Error,
              message: error.response?.data.message,
            });
            this.getStagedOrdersForWarehouse();
          });
      }
    });
  }
}
