import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LegendPosition } from '@swimlane/ngx-charts';
import { selectorActingAs, selectorWarehouses } from 'app/auth/auth.reducer';
import { ActingAs, Warehouse } from 'app/interfaces/auth.interfaces';
import { Subscription } from 'rxjs';
import {
  DashboardBucketTable,
  DashboardDeliveryStats,
  FleetEnum,
  DashboardOrderStats,
  DispatchedTripsCountByFleetByDay,
} from '../../home.interface';
import { HomeService } from '../../services/home.service';
import { ProgressBarGraphData } from '../progress-bar-graph/progress-bar-graph.component';

interface LineChartData {
  name: string;
  series: SeriesData[];
}

interface SeriesData {
  name: string;
  value: number;
}

enum ChartTimeEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

@Component({
  selector: 'app-home-overview',
  templateUrl: './home-overview.component.html',
  styleUrls: ['./home-overview.component.scss'],
})
export class HomeOverviewComponent implements OnInit, OnDestroy {
  warehouses: Warehouse[] = [];
  selectedFleet: string = '';
  fleets: string[] = Object.values(FleetEnum);
  showDeliveryStats: boolean = true;
  legendPosition: LegendPosition = LegendPosition.Below;
  lineChartData: LineChartData[] = [];
  selectedChartTime: string = 'Daily';
  ChartTimeEnum = ChartTimeEnum;
  showFleetStats: boolean = false;
  lineGraphResults: ProgressBarGraphData[] = null;
  bucketsData: DashboardDeliveryStats;
  warehouseIds: string[] = [];
  totalDispatched: number = 0;
  parcelOrderStats: DashboardOrderStats;
  displayedBuckets: DashboardBucketTable[];
  buckets: DashboardBucketTable[];

  actingAs: ActingAs;
  bucketArrayStart: number = 0;

  showBucketsStats: boolean = false;

  bucketSubscription: Subscription;
  graphLoading: boolean = false;
  orderStatisticsLoading: boolean = false;
  fleetStatsLoading: boolean = false;
  filterSubscription: Subscription;

  constructor(private store: Store, public dialog: MatDialog, public homeService: HomeService, public router: Router) {
    this.store.select(selectorActingAs).subscribe((next: ActingAs) => {
      if (next) {
        this.actingAs = next;
      }
    });
  }
  ngOnInit(): void {
    this.store.select(selectorWarehouses).subscribe((next) => (this.warehouses = next));
    this.filterSubscription = this.homeService.dashboardFilters$.subscribe((f) => {
      this.getData(f.warehouseId, f.dateFrom, f.dateTo);
    });
  }

  ngOnDestroy(): void {
    this.bucketSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
  }

  getData(warehouseId: string | null, dateFrom: Date, dateTo: Date): void {
    // to use all warehouses, pass in an empty string
    const warehouses = warehouseId ? [warehouseId] : '';
    this.graphLoading = true;
    this.orderStatisticsLoading = true;
    this.fleetStatsLoading = true;
    this.homeService
      .getDeliveryStats(this.actingAs.id, warehouses, dateFrom, dateTo, [this.selectedFleet])
      .then((x) => {
        this.bucketsData = {
          openCount: x.Buckets.OpenCount,
          pendingCount: x.Buckets.PendingCount,
          stagedOrderCount: x.Buckets.StagedOrderCount,
          activeCount: x.Trips.ActiveCount,
          completedCount: x.Trips.CompletedCount,
        };
        this.totalDispatched = x.DispatchedTripsCountByFleetByDay?.TotalDispatchedBucketCount ?? 0;
        this.setUpGraphData(x.DispatchedTripsCountByFleetByDay);
      });

    this.homeService.getFleetStats(this.actingAs.id, warehouses, dateFrom, dateTo).then((x) => {
      this.lineGraphResults = [
        {
          name: 'Contractor',
          value: x.UniqueDriversByTrips.contract,
        },
        {
          name: 'Picup',
          value: x.UniqueDriversByTrips.picup,
        },
      ];
      this.fleetStatsLoading = false;
    });

    this.homeService
      .getOrderParcelStats(this.actingAs.id, warehouses, dateFrom, dateTo, [this.selectedFleet])
      .then((x) => {
        this.parcelOrderStats = {
          totalOrders: x.TotalOrderCount,
          totalParcels: x.TotalParcelCount,
          percentageDeliveredParcels: x['% Delivered'],
          percentageCompleteOrders: x['% Delivered'],
        };
        this.orderStatisticsLoading = false;
      });
    this.getBuckets(this.actingAs.id);
  }

  setUpGraphData(data: DispatchedTripsCountByFleetByDay): void {
    const dates = Object.keys(data)
      .filter((k) => this.containsNumbers(k))
      .reverse();
    const keys = Object.keys(data[dates[0]]);
    this.lineChartData = keys.map((k) => {
      return {
        name: k,
        series: dates.map((d) => {
          return { name: d, value: data[d][k] };
        }),
      };
    });
    this.graphLoading = false;
  }

  displayBuckets(amount: number): void {
    this.displayedBuckets = this.buckets.slice(this.bucketArrayStart, this.bucketArrayStart + amount);
  }

  changeArray(mode: boolean): void {
    if (mode) {
      if (this.bucketArrayStart < this.buckets?.length - 6) {
        this.bucketArrayStart = this.bucketArrayStart + 1;
      }
    } else {
      if (this.bucketArrayStart > 0) {
        this.bucketArrayStart = this.bucketArrayStart - 1;
      }
    }
    this.displayBuckets(6);
  }

  goToPlacePicup(): void {
    this.router.navigate(['/dashboard/place-order']);
  }

  getBuckets(businessId: string): void {
    this.bucketSubscription = this.homeService.getBuckets(businessId).subscribe((x) => {
      this.buckets = x.docs.map((b) => {
        const bucket = b.data();
        const ret: DashboardBucketTable = {
          warehouseName: bucket.warehouse_name,
          bucketNo: bucket.bucket_id.toString(),
          bucketDate: bucket.delivery_date,
          numberOfOrders: bucket.total_orders,
          numberOfParcels: 0,
          numberFlagged: bucket.flagged_orders,
          numberOfRoutes: bucket.routes,
          orderState: bucket.current_status,
        };
        return ret;
      });
      this.displayBuckets(6);
    });
  }

  containsNumbers(str: string): boolean {
    return /\d/.test(str);
  }
}
