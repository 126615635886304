import { Component, OnInit } from '@angular/core';
import { IconTypes } from '../icon/icon.interfaces';
import { UiColors, UiThemes } from 'app/interfaces/ui.interfaces';
import { JustifyContent } from '../flex-container/flex-container.interfaces';
import { Router } from '@angular/router';
import { StaticService } from 'app/static.service';

@Component({
  selector: 'app-package-selector',
  templateUrl: './package-selector.component.html',
  styleUrls: ['./package-selector.component.scss']
})
export class PackageSelectorComponent implements OnInit {

  public IconTypes = IconTypes;
  public UiColors = UiColors;
  public UiThemes = UiThemes;
  public IcontTypes = IconTypes;
  public justifyContent = JustifyContent;
  packages;

  constructor(
    private router: Router,
    private staticService: StaticService
  ) {
    this.packages = this.staticService.staticData;
  }

  navigateToPackage(packageType) {
    this.router.navigate(['packages', { outlets: { Packages: [packageType] } }])
  }

  ngOnInit() {
  }

}
