import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TableAction } from 'app/shared/table/table.interfaces';
import { TrackingActiveRoutesComponent } from '../tables/tracking-active-routes/tracking-active-routes.component';
import { TrackingCompletedComponent } from '../tables/tracking-completed/tracking-completed.component';
import { TrackingInprogressTripsComponent } from '../tables/tracking-inprogress-trips/tracking-inprogress-trips.component';
import { TrackingPendingComponent } from '../tables/tracking-pending/tracking-pending.component';

@Component({
  selector: 'app-post-dispatch-tracking',
  templateUrl: './post-dispatch-tracking.component.html',
  styleUrls: ['./post-dispatch-tracking.component.scss']
})
export class PostDispatchTrackingComponent implements OnInit {
  @Input() postDispatchTablePrimaryActions: TableAction[] = [];
  @Input() activeDriverPrimaryActions: TableAction[] = [];
  @Input() warehouseFilter: any;

  @ViewChild('active') active: TrackingActiveRoutesComponent
  @ViewChild('completed') completed: TrackingCompletedComponent
  @ViewChild('inprogress') inprogress: TrackingInprogressTripsComponent
  @ViewChild('pending') pending: TrackingPendingComponent

  constructor() {}

  ngOnInit(): void {}

  refreshData(): void {
    this.active.getData();
    this.completed.getData();
    this.inprogress.getData();
    this.pending.getData();
  }
}
