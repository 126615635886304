import { Injectable } from '@angular/core';
import axios from 'app/api/axios';
import { Store } from '@ngrx/store';
import { AuthService } from '../../auth/auth.service';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActingAs } from 'app/interfaces/auth.interfaces';
import { selectorActingAs } from 'app/auth/auth.reducer';
import { AngularFirestore } from '@angular/fire/firestore';
import { businessFEConfig, CartrackIntegration } from './integration-details/integration-details.component';

@Injectable({
  providedIn: 'root'
})
export class ManageBusinessService {
  business_model;
  actingAs: ActingAs;

  businessDetails$ = new BehaviorSubject(undefined);
  couriers$ = new BehaviorSubject(undefined);

  constructor(
    public store: Store<any>,
    public authService: AuthService,
    private simpleModalService: SimpleModalService,
    private notificationsService: NotificationsService,
    private firestore: AngularFirestore
  ) {
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
  }

  addCreditsToBusiness(amount) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/give-credits',
      data: {
        amount: amount
      }
    })
      .then(() => {
        this.notifyAndUpdate(true, amount + ' Credits Given');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  getBusinessDetails(businessId: string) {
    return axios({
      method: 'POST',
      url: '/generic/cqrs/get-business-details',
      data: { business_id: businessId }
    }).then((response) => {
      return response?.data;
    });
  }

  getPeerWarehouses(warehouse_id) {
    return axios({
      method: 'GET',
      url: '/warehouse/' + warehouse_id + '/peer-warehouses'
    }).then((response) => {
      return response?.data;
    });
  }

  testWebhooks(): Promise<void> {
    return axios
      .get('/business/' + this.actingAs.id + '/test-webhooks')
      .then(() => {
        this.notify(true, 'Test Data Sent');
      })
      .catch((error) => this.notify(false, error.response?.data.message));
  }

  updateBusiness(business) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/update',
      data: {
        Details: business
      }
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Details Successfully Updated');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  inviteUser(emailAddresses) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/invite-persons',
      data: { emails: emailAddresses }
    })
      .then(() => {
        this.notifyAndUpdate(true, 'User Invited');
      })
      .catch(() => {
        this.notifyAndUpdate(false, 'No Email Address Provided');
      });
  }

  toggleAdminPermission(user_ids, action) {
    let action_url: string;
    switch (action) {
      case 'grant':
        action_url = '/grant-administrator-permission';
        break;
      case 'revoke':
        action_url = '/revoke-administrator-permission';
        break;
    }
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + action_url,
      data: user_ids
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Account priviledges changed');
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  toggleAccountantPermission(user_ids, action) {
    let action_url: string;
    switch (action) {
      case 'grant':
        action_url = '/grant-accountant-permission';
        break;
      case 'revoke':
        action_url = '/revoke-accountant-permission';
        break;
    }
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + action_url,
      data: user_ids
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Account priviledges changed');
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  removeUser(user_ids) {
    return axios({
      method: 'POST',
      url: 'business/' + this.actingAs.id + '/remove-users',
      data: user_ids
    })
      .then(() => {
        this.notifyAndUpdate(true, 'User Successfully deleted');
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  addGiftCode(code, size) {
    return axios({
      method: 'POST',
      url: 'import/' + this.actingAs.id + '/add-gift-code',
      data: {
        name: code,
        parcel_size: size
      }
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Gift Code Successfully Added');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  setWebhookURL(webhook_model) {
    return axios({
      method: 'POST',
      url: '/enterprise/' + this.actingAs.id + '/set-webhook-url',
      data: webhook_model
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Webhook Set');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  changePackage(billing_package, payment_terms) {
    return axios({
      method: 'POST',
      url: '/business/' + this.actingAs.id + '/set-billing-package/',
      data: {
        billing_package: billing_package,
        payment_terms: payment_terms
      }
    })
      .then(() => {
        this.notify(true, 'Status Changed');
        this.authService.updateAccount(this.actingAs.id);
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  createBusiness(business_model) {
    return axios({
      method: 'POST',
      url: '/business/create',
      data: { details: business_model }
    })
      .then((response) => {
        this.authService.updateAccount(response?.data);
        this.authService.updateBusinesses();
        this.authService.setActingAs(response?.data, true);
        this.notify(true, 'Business Successfully Created');
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
      });
  }

  public deleteBusiness(business_id) {
    return axios.delete('/business/' + business_id).then((result) => {
      return result;
    });
  }

  getCouriers() {
    axios({
      method: 'GET',
      url: '/basic/couriers/'
    }).then((response) => {
      this.couriers$.next(response?.data); // type: Courier
    });
  }

  onSetCourierIntegration(courier) {
    return axios({
      method: 'POST',
      url: '/enterprise/' + this.actingAs.id + '/set-courier-for-business',
      data: { courier_details: courier }
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Courier Integration Set Successfully.');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response?.data.message);
        return;
      });
  }

  removeCourierIntegration(account_number, courier_code, service_type) {
    this.simpleModalService
      .addModal(ConfirmModalComponent, {
        title: 'Are you sure?',
        message:
          'You are about to remove the courier integration ' +
          courier_code +
          '|' +
          account_number +
          ' from ' +
          this.actingAs.name +
          '?',
        description: null
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        axios({
          method: 'POST',
          url: '/enterprise/' + this.actingAs.id + '/remove-courier-from-business',
          data: {
            account_number: account_number,
            courier_code: courier_code,
            service_type: service_type
          }
        })
          .then(() => {
            this.notifyAndUpdate(true, 'Courier Integration Successfully Removed');
            return;
          })
          .catch((error) => {
            this.notifyAndUpdate(false, error.response.message);
            return;
          });
      });
  }

  setBranding(branding) {
    axios({
      method: 'POST',
      url: '/enterprise/' + this.actingAs.id + '/set-branding',
      data: branding
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Branding Successfully Updated');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response.message);
        return;
      });
  }

  updateNotificationSettings(notification_settings) {
    axios({
      method: 'POST',
      url: '/business/' + this.actingAs.id + '/update-notification-settings',
      data: { notification_details: notification_settings }
    })
      .then(() => {
        this.notifyAndUpdate(true, 'Notifications Successfully Updated');
        return;
      })
      .catch((error) => {
        this.notifyAndUpdate(false, error.response.message);
        return;
      });
  }

  notify(successful, message): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message
    });
  }

  notifyAndUpdate(successful, message): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message
    });
    this.authService.updateBusinesses();
  }

  getCartrackAPIKeyData(): Observable<businessFEConfig> {
    const collection = this.firestore.collection('frontend-config');
    return collection
      .doc<businessFEConfig>(this.actingAs.id)
      .valueChanges();
  }

  setCartrackAPIKeyData(cartrackIntegration: CartrackIntegration): void {
    const collection = this.firestore.collection('frontend-config');
    if(cartrackIntegration.new) {
      collection.doc(this.actingAs.id).set({
        cartrackIntegration: {
          username: cartrackIntegration.username,
          password: cartrackIntegration.password
        }
      })
    } else {
      collection.doc(this.actingAs.id).update({
        cartrackIntegration: cartrackIntegration
      })
    }

  }
}
