import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ManageBusinessService } from './manage-business.service';
import { GiftCodesEditComponent } from './gift-codes-edit/gift-codes-edit.component';
import { BuyCreditsComponent } from './buy-credits/buy-credits.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { SetWebhookComponent } from './set-webhook/set-webhook.component';
import { SetEnterpriseComponent } from './set-enterprise/set-enterprise.component';
import { IntegrationDetailsComponent } from './integration-details/integration-details.component';
import { AddCreditsComponent } from './add-credits/add-credits.component';
import { SetCartrackComponent } from './set-cartrack/set-cartrack.component';

@NgModule({
  imports: [SharedModule, CommonModule],
  declarations: [
    GiftCodesEditComponent,

    BuyCreditsComponent,
    AddBusinessComponent,
    SetWebhookComponent,
    SetEnterpriseComponent,
    IntegrationDetailsComponent,

    AddCreditsComponent,
      SetCartrackComponent,
  ],
  exports: [BuyCreditsComponent, SetEnterpriseComponent, IntegrationDetailsComponent, AddCreditsComponent],
  providers: [ManageBusinessService],
  entryComponents: [
    SetEnterpriseComponent,
    SetWebhookComponent,
    AddBusinessComponent,
    GiftCodesEditComponent,
    AddCreditsComponent,
  ],
})
export class ManageBusinessModule {}
