import { Component, OnInit, Input } from '@angular/core';
import { UiThemes } from 'app/interfaces/ui.interfaces';
import { IconTypes } from '../icon/icon.interfaces';
@Component({
  selector: 'app-secondsolutions',
  templateUrl: './secondsolutions.component.html',
  styleUrls: ['./secondsolutions.component.scss']
})
export class SecondsolutionsComponent implements OnInit {
  UiThemes = UiThemes;
  IconTypes = IconTypes;

  @Input() Imgsrc: string;
  @Input() Title: string;
  @Input() subtitle: string;
  constructor() { }

  ngOnInit(): void {
  }

}
