import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { authState } from 'app/auth/auth.reducer';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import axios, { AxiosResponse } from 'axios';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AllocationCode,
  BaseResponse,
  GetPagedResponse,
  PlanningOrder,
  RequestDetails,
  Shipment,
  PlanningTrip,
  ModifyPreAllocationsByTripsRequest,
  ModifyPreAllocationsByShipmentsRequest,
  DispatchTripsResponse,
  PlanningActionEnum,
  AcceptShipmentPreAllocationResponse,
  ShipmentHistory,
  OrderHistory,
  SetBatchToReadyRequest,
  SetPhaseByBatchRequest,
  BaseError,
  FilterItem,
  PlanningBusinessMapping,
  ShipmentPhaseCount,
  RequestFilters,
} from './planning.interfaces';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class PlanningService {
  hubIdFilter$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  businessIdFilter$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  shipmentId$: BehaviorSubject<string> = new BehaviorSubject('');
  orderWaybill$: BehaviorSubject<string> = new BehaviorSubject('');
  tripId$: BehaviorSubject<string> = new BehaviorSubject('');
  isAdminView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  planningBusinesses$: BehaviorSubject<PlanningBusinessMapping[] | null> = new BehaviorSubject(null);

  constructor(
    public notificationsService: NotificationsService,
    private store: Store<authState>,
    private firestore: AngularFirestore
  ) {
    axios.defaults.baseURL = environment.planningModule.baseUrl;
    // this.store.select(selectorActingAs).subscribe((data) => {
    //   this.getBusinessByV2Id([data.id]).then((response) => {
    //     this.planningBusinesses$.next(response.Response);
    //   });
    // });
  }

  get isAdminView$(): Observable<boolean> {
    return new Observable((f) => {
      this.isAdminView.subscribe(f);
    });
  }

  setAdminView(adminView: boolean): void {
    this.isAdminView.next(adminView);
  }

  notify(successful: boolean, message: string): void {
    const notificationType = successful ? 'success' : 'error';
    this.notificationsService.publish({
      type: notificationType,
      message: message,
    });
  }

  getAllocationShipments(
    amount: number,
    pageNumber: number,
    shipmentPhases: string[],
    allocationCodes?: string[]
  ): Promise<GetPagedResponse<Shipment[]> | void> {
    if (!this.isAdminView.value && this.businessIdFilter$.value.length === 0) {
      return new Promise<void>((resolve) => resolve());
    }
    const data: RequestDetails = {
      Pagination: { PageSize: amount, PageNumber: pageNumber },
      Filters: {
        BusinessIds: this.businessIdFilter$?.value,
        HubIds: this.hubIdFilter$?.value,
        ShipmentPhases: shipmentPhases,
        PreAllocationCodes: allocationCodes,
      },
    };
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/shipment/get-allocation-shipments',
      data: data,
    })
      .then((response: AxiosResponse<BaseResponse<GetPagedResponse<Shipment[]>>>) => {
        if (response.data.IsSuccess) {
          return response.data.Response;
        } else {
          this.handleError(PlanningActionEnum.GetAllocationShipments, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  acceptShipmentPreAllocations(shipments: Array<string>): Promise<BaseResponse<AcceptShipmentPreAllocationResponse>> {
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/shipment/accept-shipment-pre-allocations',
      data: shipments,
    })
      .then((response: AxiosResponse<BaseResponse<AcceptShipmentPreAllocationResponse>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, 'Successfully accepted allocations');
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.AcceptShipmentPreAllocations, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  dispatchTrips(tripIds: number[]): Promise<BaseResponse<DispatchTripsResponse[]>> {
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/dispatch/dispatch-trips',
      data: tripIds,
    })
      .then((response: AxiosResponse<BaseResponse<DispatchTripsResponse[]>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, 'Successfully dispatched trips');
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.Dispatch, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  dispatchThirdParty(shipmentIds: string[]): Promise<void> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/dispatch/dispatch-third-party`,
      data: shipmentIds,
    })
      .then((response: AxiosResponse<BaseResponse<void>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, 'Successfully dispatched third party orders');
        } else {
          this.handleError(PlanningActionEnum.DispatchThirdParty, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  handleError(action: PlanningActionEnum, errors?: BaseError[]): void {
    this.notify(false, `${action}: something went wrong!`);
    if (errors) {
      // eslint-disable-next-line no-console
      console.error(errors);
    }
  }

  getOrders(amount: number, pageNumber: number, orderStates: string[]): Promise<GetPagedResponse<PlanningOrder[]>> {
    const data: RequestDetails = {
      Pagination: { PageSize: amount, PageNumber: pageNumber },
      Filters: {
        BusinessIds: this.businessIdFilter$?.value,
        HubIds: this.hubIdFilter$?.value,
        OrderStates: orderStates,
      },
    };
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/order/get-orders',
      data: data,
    })
      .then((response: AxiosResponse<BaseResponse<GetPagedResponse<PlanningOrder[]>>>) => {
        if (response.data.IsSuccess) {
          return response.data.Response;
        } else {
          this.handleError(PlanningActionEnum.GetOrders, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  updateOrder(order: PlanningOrder): Promise<PlanningOrder> {
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/order/update-order',
      data: order,
    })
      .then((response) => {
        this.notify(true, 'Successfully updated order');
        return response.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getPreAllocationRules(): Promise<AllocationCode[]> {
    return axios({
      method: 'GET',
      baseURL: environment.planningModule.baseUrl + '/courier/pre-allocation-rules',
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getOrder(orderWaybill: string): Promise<BaseResponse<PlanningOrder>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/order/get-order-by-order-waybill/${orderWaybill}`,
    })
      .then((response: AxiosResponse<BaseResponse<PlanningOrder>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetOrder, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getBusinesses(): Promise<BaseResponse<FilterItem[]>> {
    return axios({
      method: 'GET',
      baseURL: environment.planningModule.baseUrl + '/business/get-businesses',
    })
      .then((response: AxiosResponse<BaseResponse<FilterItem[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetBusinesses, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getHubs(): Promise<BaseResponse<FilterItem[]>> {
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/hub/get-hubs',
      data: { BusinessIds: this.businessIdFilter$.value },
    })
      .then((response: AxiosResponse<BaseResponse<FilterItem[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetHubs, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentsByOrderWaybill(orderWaybill: string): Promise<BaseResponse<Shipment[]>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/order/get-shipments-by-order-waybill/${orderWaybill}`,
    })
      .then((response: AxiosResponse<BaseResponse<Shipment[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.ShipmentsByOrderWaybill, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentsDetails(shipmentId: string): Promise<BaseResponse<Shipment>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-shipments-details/${shipmentId}`,
    })
      .then((response: AxiosResponse<BaseResponse<Shipment>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentDetails, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getTrips(amount: number, pageNumber: number): Promise<GetPagedResponse<PlanningTrip[]>> {
    const data: RequestDetails = {
      Pagination: { PageSize: amount, PageNumber: pageNumber },
      Filters: {
        BusinessIds: this.businessIdFilter$?.value,
        HubIds: this.hubIdFilter$?.value,
      },
    };
    return axios({
      method: 'POST',
      baseURL: environment.planningModule.baseUrl + '/dispatch/get-trips',
      data: data,
    })
      .then((response: AxiosResponse<BaseResponse<GetPagedResponse<PlanningTrip[]>>>) => {
        if (response.data.IsSuccess) {
          return response.data.Response;
        } else {
          this.handleError(PlanningActionEnum.GetTrips, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  modifyPreAllocationsbyTrips(data: ModifyPreAllocationsByTripsRequest): Promise<void> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/shipment/modify-pre-allocations-by-trips`,
      data: data,
    })
      .then((response: AxiosResponse<BaseResponse<void>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, 'Successfully modified trips');
        } else {
          this.handleError(PlanningActionEnum.ModifyTripPreAllocation, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  modifyPreAllocationsbyShipments(data: ModifyPreAllocationsByShipmentsRequest): Promise<void> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/shipment/modify-pre-allocations-by-shipments`,
      data: data,
    })
      .then((response: AxiosResponse<BaseResponse<null>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, 'Successfully modified shipments');
        } else {
          this.handleError(PlanningActionEnum.ModifyShipmentPreAllocation, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentHistory(shipmentId: string): Promise<BaseResponse<ShipmentHistory[]>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-history/${shipmentId}`,
    })
      .then((response: AxiosResponse<BaseResponse<ShipmentHistory[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentHistory, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getOrderHistory(orderWaybill: string): Promise<BaseResponse<OrderHistory[]>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-history/${orderWaybill}`,
    })
      .then((response: AxiosResponse<BaseResponse<OrderHistory[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetOrderHistory, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentDetails(shipmentId: string): Promise<BaseResponse<Shipment>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-shipment-details/${shipmentId}`,
    })
      .then((response: AxiosResponse<BaseResponse<Shipment>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentDetails, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  setBatchToReady(request: SetBatchToReadyRequest): Promise<void> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/shipment/reset-batch-to-ready`,
      data: request,
    })
      .then((response: AxiosResponse<BaseResponse<null>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, 'Successfully reset shipments to ready');
        } else {
          this.handleError(PlanningActionEnum.SetBatchToReady, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  setPhaseByBatch(request: SetPhaseByBatchRequest): Promise<void> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/shipment/set-phase-by-batch`,
      data: request,
    })
      .then((response: AxiosResponse<BaseResponse<null>>) => {
        if (response.data.IsSuccess) {
          this.notify(true, `Successfully set batch to: ${request.Phase}`);
        } else {
          this.handleError(PlanningActionEnum.SetBatchToReady, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentsByBatchUid(batchUid: string): Promise<BaseResponse<Shipment[]>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-shipments-by-batch/${batchUid}`,
    })
      .then((response: AxiosResponse<BaseResponse<Shipment[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentDetails, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getBusinessByV2Id(businessIds: string[]): Promise<BaseResponse<PlanningBusinessMapping[]>> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/business/get-businesses-by-v2-business-ids`,
      data: businessIds,
    })
      .then((response: AxiosResponse<BaseResponse<PlanningBusinessMapping[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetBusinessesByV2Id, response.data.Errors);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentPhaseCount(): Promise<BaseResponse<ShipmentPhaseCount[]>> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-phase-count`,
      data: { BusinessId: this.businessIdFilter$?.value.length > 0 ? this.businessIdFilter$.value[0] : null },
    })
      .then((response: AxiosResponse<BaseResponse<ShipmentPhaseCount[]>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentPhaseCount);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipmentGeoJSON(shipmentPhases: string[], allocationCodes: string[]): Promise<BaseResponse<any>> {
    const requestFilters: RequestFilters = {
      ShipmentPhases: shipmentPhases,
      PreAllocationCodes: allocationCodes,
    };
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/shipment/get-geo-json`,
      data: requestFilters,
    })
      .then((response: AxiosResponse<BaseResponse<any>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentGeoJSON);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getTripsGeoJSON(tripIds: number[]): Promise<BaseResponse<any>> {
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/trip/get-geo-json-trips`,
      data: tripIds,
    })
      .then((response: AxiosResponse<BaseResponse<any>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentGeoJSON);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getThirdPartyGeoJSON(shipmentPhases: string[]): Promise<BaseResponse<any>> {
    const data: RequestFilters = {
      ShipmentPhases: shipmentPhases,
    };
    return axios({
      method: 'POST',
      baseURL: `${environment.planningModule.baseUrl}/thirdparty/get-active-geo-json`,
      data: data,
    })
      .then((response: AxiosResponse<BaseResponse<any>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentGeoJSON);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getTripGeoJSON(tripId: string): Promise<BaseResponse<any>> {
    return axios({
      method: 'GET',
      baseURL: `${environment.planningModule.baseUrl}/trip/get-geo-json/${tripId}`,
    })
      .then((response: AxiosResponse<BaseResponse<any>>) => {
        if (response.data.IsSuccess) {
          return response.data;
        } else {
          this.handleError(PlanningActionEnum.GetShipmentGeoJSON);
        }
      })
      .catch((error) => {
        this.notify(false, error.response?.data.message);
        throw error;
      });
  }

  getShipments(preAllocation: string): Observable<Shipment[]> {
    return this.firestore
      .collection<Shipment>('shipments', (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('PreAllocation', '==', preAllocation);
      return query;
    })
      .valueChanges();
  }

  updateShipmentAllocation(id: string, preAllocation: string): Promise<void> {
    return this.firestore.collection('shipments').doc(id).update({ PreAllocation: preAllocation });
  }
}
