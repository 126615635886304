import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { IconTypes } from '../../../shared/icon/icon.interfaces';
import { UiColors } from '../../../interfaces/ui.interfaces';
import { JustifyContent } from 'app/shared/flex-container/flex-container.interfaces';
import * as moment from 'moment';
import { ButtonTypes } from 'app/shared/buttons/basic-button.component';

@Component({
  selector: 'app-time-date-picker.modal',
  templateUrl: './time-date-picker.modal.component.html',
  styleUrls: ['./time-date-picker.modal.component.scss'],
})
export class TimeDatePickerModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  public date: Date;
  private time: string;
  public justifyContent = JustifyContent;
  buttonTypes = ButtonTypes;
  today = new Date();

  Icons = {
    Colors: UiColors,
    Types: IconTypes,
  };

  constructor() {
    super();
  }

  ngOnInit() {
    this.date = this.today;
  }

  dateChanged(date) {
    this.date = date;
  }

  timeChanged(time) {
    this.time = moment.utc(time * 3600000).format('HH:mm:ss');
  }

  cancel() {
    this.result = null;
    this.close();
  }

  returnDateTime() {
    const stringDate = this.formatDate(this.date);
    const jsDateTime = moment(`${stringDate} ${this.time}`, 'YYYY-MM-DD HH:mm:ss').format();
    const prettyDateTime = moment(`${stringDate} ${this.time}`, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do YYYY, h:mm:ss');
    this.result = {
      jsDateTime: jsDateTime,
      prettyDateTime: prettyDateTime,
    };
    this.close();
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
}
