import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import axios from 'app/api/axios';
import { NotificationsService } from 'app/shared/notifications/notifications.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { selectLastMileCollectionName, selectorActingAs, selectorUser } from 'app/auth/auth.reducer';
import { ActingAs, AuthUser } from 'app/interfaces/auth.interfaces';
import { AutoNotesService } from '../notes/auto-notes.service';
import { AutoNotesTypes } from '../notes/notes.constants';

@Injectable({
  providedIn: 'root',
})
export class LastMileService {
  private LAST_MILE_BASE_URL = '/mobile-last-mile/';
  user: AuthUser;
  actingAs: ActingAs;
  public fsLastMileMappedData = new BehaviorSubject(undefined);

  lasMileSubscription: Subscription;
  lastMileCollectionName = 'last-mile';

  constructor(
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private notificationsService: NotificationsService,
    private store: Store<any>,
    private autoNotesService: AutoNotesService
  ) {
    this.store.select(selectorUser).subscribe((next) => (this.user = next));
    this.store.select(selectorActingAs).subscribe((next) => (this.actingAs = next));
    this.store.select(selectLastMileCollectionName).subscribe(value => {
      this.lastMileCollectionName = value ?? 'last-mile'
    })
  }

  destroyLastMileSubscription(): void {
    if (this.lasMileSubscription) {
      this.lasMileSubscription.unsubscribe();
    }
    this.fsLastMileMappedData.next(undefined);
  }

  rehydrateAggregate(lastMileId: string) {
    axios({
      method: 'POST',
      url: '/firestore/rehydrate-firestore-readmodel/' + lastMileId,
    })
      .then((res) => {
        return res.data;
      })
      .catch(() =>
        this.notificationsService.publish({
          type: 'error',
          message: 'Error building driver load - Please contact support. (Error Code:RH-' + lastMileId + ')',
        })
      );
  }

  getTravelData(route_id: string) {
    return axios.get('route/' + route_id + '/travel-data').then(({ data }) => data);
  }

  endWarehouseRoute(lastMileId: string) {
    return axios({
      method: 'POST',
      url: '/last-mile/' + lastMileId + '/end-warehouse-route',
      data: [],
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  endWaypointRoute(lastMileId) {
    return axios({
      method: 'POST',
      url: '/last-mile/' + lastMileId + '/end-waypoint-route',
      data: [],
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  actionFailedParcels(lastMileId, actionData) {
    return axios({
      method: 'POST',
      url: '/last-mile/' + lastMileId + '/action-failed-parcels',
      data: actionData,
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getLastMileObject(lastMileId: string, orderId) {
    const docRef = this.firestore.collection(this.lastMileCollectionName);
    this.lasMileSubscription = docRef
      .doc(lastMileId)
      .valueChanges()
      .subscribe((res) => {
        // && !this.user.is_admin - Include if admins want to see all parcels on a driver
        if (orderId) {
          this.filterLastMileParcelsByOrder(res, orderId);
        } else {
          this.fsLastMileMappedData.next(res);
        }
      });
  }

  filterLastMileParcelsByOrder(fsLastMileMappedData, orderId) {
    fsLastMileMappedData.Parcels = Object.values(fsLastMileMappedData.Parcels);
    fsLastMileMappedData.Parcels = fsLastMileMappedData.Parcels.filter((parcel) => parcel.OrderId === orderId);
    this.fsLastMileMappedData.next(fsLastMileMappedData);
  }

  getDriverByLastmileId(lastMileId) {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-driver-by-id',
      data: { last_mile_id: lastMileId },
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  reconcileWarehouseRoute(
    reconcile_body: { parcel_waybill: string; reason: string; result: string }[],
    last_mile_id: string
  ): Promise<void> {
    return axios({
      method: 'POST',
      url: 'last-mile/' + last_mile_id + '/reconcile-warehouse-parcels',
      data: reconcile_body,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Parcels Reconciled' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  reconcileParcels(
    reconcile_body: { parcel_waybill: string; reason: string; result: string }[],
    last_mile_id: string,
    orderId: string
  ): Promise<void> {
    return axios({
      method: 'POST',
      url: 'last-mile/' + last_mile_id + '/reconcile-parcels',
      data: reconcile_body,
    })
      .then(() => {
        this.autoNotesService.generateNote({
          autoNotesType: AutoNotesTypes.reconciledOrder,
          orderId: orderId
        })
        this.notificationsService.publish({ type: 'success', message: 'Parcels Reconciled' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  reconcileAvailableRoute(
    reconcile_body: { parcel_waybill: string; reason: string; result: string }[],
    last_mile_id: string
  ): Promise<void> {
    return axios({
      method: 'POST',
      url: 'last-mile/' + last_mile_id + '/reconcile-parcels',
      data: reconcile_body,
    })
      .then(() => {
        this.notificationsService.publish({ type: 'success', message: 'Parcels Reconciled' });
        return;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getDriverEarningsForRoutes(driverId, returnOnly): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-driver-earnings-for-routes',
      data: {
        driver_id: driverId,
        show_return_only: returnOnly,
      },
    })
      .then((response) => response?.data)
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  unassignStagedParcels(parcels, lastmileId): Promise<any>{
    return axios({
      method: 'POST',
      url: 'last-mile/' + lastmileId + '/unassign-staged-orders',
      data: {
        parcel_waybills: parcels
      },
    })
      .then(() => this.notificationsService.publish({ type: 'success', message: 'Parcels unassigned from last mile.' }))
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  forceAssignStagedParcels(parcels, lastmileId): Promise<any> {
    return axios({
      method: 'POST',
      url: 'last-mile/' + lastmileId + '/force-staged-orders',
      data: {
        parcel_waybills: parcels
      },
    })
      .then(() => this.notificationsService.publish({ type: 'success', message: 'Parcels forced onto active last mile.' }))
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getEarningsForRoutesWithSummary(driver_id: string, all_orders_completed: boolean, business_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-driver-earnings-for-routes-v2',
      data: {
        driver_id: driver_id,
        page: 1,
        page_size: 20,
        all_orders_completed: all_orders_completed,
        owner_id: business_id,
      },
    })
      .then((response) => response?.data[0])
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }

  getFailedParcels(last_mile_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-failed-parcels',
      data: { last_mile_id: last_mile_id },
    }).then((response) => {
      return response?.data;
    });
  }

  getAvailableParcelsForLastMile(last_mile_id: string): Promise<any> {
    return axios({
      method: 'POST',
      url: 'generic/cqrs/get-available-parcels-for-last-mile',
      data: { last_mile_id: last_mile_id },
    })
      .then((response) => {
        return response?.data.output;
      })
      .catch((error) => this.notificationsService.publish({ type: 'error', message: error.response?.data.message }));
  }
}


