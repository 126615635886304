import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TableColumn, TableTypeEnum } from './generic-modular-table.interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-generic-modular-table',
  templateUrl: './generic-modular-table.component.html',
  styleUrls: ['./generic-modular-table.component.scss']
})
export class GenericModularTableComponent implements OnInit, OnDestroy {

  @Input() dataSource: MatTableDataSource<unknown>;
  @Input() tableColumns: TableColumn[];
  @Input() enablePaging: boolean = false;
  selection = new SelectionModel<unknown>(true, []);
  @Output() selectionChanges: EventEmitter<unknown[]> = new EventEmitter<unknown[]>();
  tableType = TableTypeEnum;

  $unsubscribe = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    this.selection.changed.pipe(takeUntil(this.$unsubscribe)).subscribe(value => {
      this.selectionChanges.emit(value.source.selected);
    })
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  displayedColumns(): string[] {
    return this.tableColumns.map((column: TableColumn) => {
      if(!column.hidden){
        return column.id
      }
    }).filter((item: string )=> item);
  }
}
