import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ButtonTypes } from '../buttons/basic-button.component';
import { ModalAction, ModalSizes } from '../modals/modal.interfaces'

@Component({
  selector: 'app-color-picker-modal',
  templateUrl: './color-picker-modal.component.html',
  styleUrls: ['./color-picker-modal.component.scss']
})
export class ColorPickerModalComponent extends SimpleModalComponent<any, any> implements OnInit {
  color = '#fff'
  public ModalSizes = ModalSizes;
  buttonTypes = ButtonTypes;

  public actions: ModalAction[] = [
    { name: 'cancel', title: 'Cancel' },
    { name: this.buttonTypes.Primary, title: 'Apply', disabled: false, isPrimary: true },
  ];

  handleAction($event) {
    if ($event === 'cancel') {
      this.close();
    }
    if ($event === this.buttonTypes.Primary) {
      this.result = this.color;
      this.close();
    }
  }

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
